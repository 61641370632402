import {
    GET_MEDIA_SLIDER,
    GET_SLIDER
} from '../../types/frontend/types';

export default function sliderReducer(state = {}, action) {
    switch (action.type) {
        case GET_MEDIA_SLIDER:
            return {
                ...state,
                getMediaSlider: action.payload
            }
        case GET_SLIDER:
            return {
                ...state,
                getSlider: action.payload
            }
        default:
            return state;
    }
}