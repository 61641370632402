// import { createAsyncThunk } from '@reduxjs/toolkit';
import * as data from './data.auth';
import * as global from '../global/data.global';
import axios from 'axios';

import {
    getTokenCookie,
    removeClientToken,
    getAuthHeader,
    tokenName
} from '../../../views/utils/tools';

axios.defaults.headers.post['Content-Type'] = 'application/json';

// export const clientIsAuth = async (source) => {
//     const getuser = await axios.get(`http://192.168.1.2:3018/api/auth/isauth`, {
//         headers: { 'Authorization': `Bearer ${getTokenCookie(tokenName)}` },
//         cancelToken: source.token
//     })

//     return getuser.data;
// }

export const clientIsAuth = () => {
    return async (dispatch) => {
        try {
            const token = await getTokenCookie(tokenName);
            if (!token) {
                throw new Error();
            }

            const client = await axios.get(`/api/auth/isauth`, getAuthHeader(tokenName));
            dispatch(data.clientSignIn({ data: client.data.data, auth: true }));
            return client;
        } catch (error) {
            // error.response.data = to read json error from server
            dispatch(data.clientIsAuth({ data: {}, auth: false }))
        }
    }
}

export const clientSignIn = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/auth/signin`, dataToSubmit, {
                withCredentials: true,
                headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
            });

            if (request.data.success) {
                setTimeout(() => {
                    dispatch(data.clientSignIn({ data: request.data.data, auth: true }));
                }, 5000)
            } else {
                setTimeout(() => {
                    dispatch(data.clientSignOut());
                }, 5000)
            }

            return request;
        } catch (error) {
            setTimeout(() => {
                dispatch(global.globalError(error.response.data.message))
            }, 5000)
        }
    }

}

export const clientSignOut = () => {
    return async (dispatch) => {
        try {
            removeClientToken();
            dispatch(data.clientSignOut());
            dispatch(global.globalSuccess('Sign out'));
        } catch (error) {
            dispatch(global.globalError(error.response.data.message))
        }
    }
}

export const updatePin = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.put(`/api/auth/updatepin`, dataToSubmit, {
                withCredentials: true,
                headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
            });
            if (request.data.success) {
                setTimeout(() => {
                    dispatch(data.clientUpdatePin({ data: request.data.data, auth: true }));
                }, 5000)
            }
            return request;
        } catch (error) {
            setTimeout(() => {
                dispatch(global.globalError(error.response.data.message))
            }, 5000)
        }
    }
}

export const updateFirstTime = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.put(`/api/auth/updatefirsttime`, dataToSubmit, {
                withCredentials: true,
                headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
            });
            if (request.data.success) {
                setTimeout(() => {
                    dispatch(data.clientUpdateFirstTime({ data: request.data.data, auth: true }));
                }, 5000)
            }
            return request;
        } catch (error) {
            setTimeout(() => {
                dispatch(global.globalError(error.response.data.message))
            }, 5000)
        }
    }
}