import {
    GET_GALLERY,
    GET_GALLERY_CATEGORY,
    GET_GALLERY_CATEGORY_DISTINCT,
    GET_GALLERY_TAGS,
    GET_MEDIA_GALLERY
} from '../../../types/frontend/types';

export const getMediaGallery = (request) => ({
    type: GET_MEDIA_GALLERY,
    payload: request
})

export const getGallery = (request) => ({
    type: GET_GALLERY,
    payload: request
})

// CATEGORY
export const getGalleryCategory = (request) => ({
    type: GET_GALLERY_CATEGORY,
    payload: request
})

export const getGalleryCategoryDistinct = (request) => ({
    type: GET_GALLERY_CATEGORY_DISTINCT,
    payload: request
})

// TAGS
export const getGalleryTags = (request) => ({
    type: GET_GALLERY_TAGS,
    payload: request
})
