import {
    GET_TESTIMONI,
    GET_TESTIMONI_CATEGORY,
    GET_TESTIMONI_CATEGORY_DISTINCT,
    GET_TESTIMONI_TAGS,
    GET_MEDIA_TESTIMONI
} from '../../../types/frontend/types';

export const getMediaTestimoni = (request) => ({
    type: GET_MEDIA_TESTIMONI,
    payload: request
})

export const getTestimoni = (request) => ({
    type: GET_TESTIMONI,
    payload: request
})

// CATEGORY
export const getTestimoniCategory = (request) => ({
    type: GET_TESTIMONI_CATEGORY,
    payload: request
})

export const getTestimoniCategoryDistinct = (request) => ({
    type: GET_TESTIMONI_CATEGORY_DISTINCT,
    payload: request
})

// TAGS
export const getTestimoniTags = (request) => ({
    type: GET_TESTIMONI_TAGS,
    payload: request
})
