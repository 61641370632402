import {
    GET_SLIDER,
    ADD_SLIDER,
    UPDATE_SLIDER,
    GET_MEDIA_SLIDER,
    ADD_MEDIA_SLIDER,
    DELETE_MEDIA_SLIDER
} from '../../types/slider';

export const getMediaSlider = (request) => ({
    type: GET_MEDIA_SLIDER,
    payload: request
})

export const addMediaSlider = (request) => ({
    type: ADD_MEDIA_SLIDER,
    payload: request
})

export const deleteMediaSlider = (request) => ({
    type: DELETE_MEDIA_SLIDER,
    payload: request
})

export const getSlider = (request) => ({
    type: GET_SLIDER,
    payload: request
})

export const addSlider = (request) => ({
    type: ADD_SLIDER,
    payload: request
})

export const updateSlider = (request) => ({
    type: UPDATE_SLIDER,
    payload: request
})
