import React from 'react';

import moment from 'moment';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import { useDetectOutsideClick } from "./useDetectOutsideClick";

import { FaCloudUploadAlt, FaEye, FaEyeSlash } from 'react-icons/fa'
import { isObjectEmpty } from '../tools';

const FormField = React.forwardRef(({
    id,
    icons,
    formdata,
    change,
    searchChange,
    selectForm,
    addForm,
    removeForm,
    maxLength = 10000,
    disabled = false,
    formdataHandler,
    showName = true,
    theFunction,
    theClass = "",
    thekey
}, ref) => {

    React.useImperativeHandle(ref, () => ({

        closeFromOutside() {
            closeFunction()
        },
        phoneCloseFromOurside() {
            searchCloseFunction()
        },
        closeSelectFromOutside() {
            searchWFCloseFunction()
        },
        closeSelectArrayFromOutside() {
            searchWFArrayCloseFunction()
        }
    }));

    const wrapperRef = React.useRef(null);
    const selectWSearchObjectRef = React.useRef(null);
    const selectWSearchArrayRef = React.useRef(null);
    const selectWFSearchRef = React.useRef(null);

    const [openMode, openModeHandler] = useDetectOutsideClick(wrapperRef, false);
    const [searchMode, searchModeHandler] = useDetectOutsideClick(selectWSearchObjectRef, false);
    const [searchArrayMode, searchArrayModeModeHandler] = useDetectOutsideClick(selectWSearchArrayRef, false);
    const [searchWFMode, searchWFModeHandler] = useDetectOutsideClick(selectWFSearchRef, false);

    const [isRevealPassword, isRevealPasswordHandler] = React.useState(false);
    const [isAdding, isAddingHandler] = React.useState(false);

    React.useEffect(() => {
        if (id === 'password') {
            formdataHandler((prevFormData) => {
                const newFormData = { ...prevFormData };

                const newElement = { ...newFormData[id] };

                newElement.config.type = isRevealPassword ? "text" : id;
                newFormData[id] = newElement;

                return newFormData;
            });
        }
    }, [isRevealPassword, formdataHandler, id])

    const showError = () => {
        let errorMessage = null;

        if (formdata.validation && !formdata.valid) {
            errorMessage = (
                <div className="errorLabel">
                    {formdata.validationMessage}
                </div>
            )
        }

        return errorMessage;
    }

    ///////////////// OPEN MODE 
    const openFunction = () => {
        openModeHandler(true);
    }

    const closeFunction = () => {
        openModeHandler(false);
    }

    ///////////////// SEARCH MODE 
    const searchModeFunction = () => {
        searchModeHandler(true);
    }

    const searchCloseFunction = () => {
        searchModeHandler(false);
    }

    ///////////////// SEARCH MODE OBJECT
    const searchWFModeFunction = () => {
        searchWFModeHandler(true);
    }

    const searchWFCloseFunction = () => {
        searchWFModeHandler(false);
    }

    ///////////////// SEARCH MODE ARRAY
    const searchWFArrayModeFunction = () => {
        searchArrayModeModeHandler(true);
    }

    const searchWFArrayCloseFunction = () => {
        searchArrayModeModeHandler(false);
    }

    const showLinks = (type, whichdata) => {
        let list = [];

        if (type && type.length > 0) {
            type.forEach((item) => {
                list.push(item)
            });
            return list.map((item, i) => (
                <li
                    key={i}
                    onClick={(event) => selectForm({ event, item, whichdata })}
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        {item[thekey].replace(/^\w/, c => c.toUpperCase())}
                    </span>

                </li>
            ))
        } else {
            return (
                <li
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        No data
                    </span>
                </li>
            )
        }
    }

    const showLinksWithMoreInfo = (type, whichdata) => {
        let list = [];

        if (type && type.length > 0) {
            type.forEach((item) => {
                list.push(item)
            });
            return list.map((item, i) => (
                <li
                    key={i}
                    onClick={(event) => selectForm({ event, item, whichdata })}
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        {item.saveas}: <span style={{ fontSize: '11px' }}>{item[thekey].replace(/^\w/, c => c.toUpperCase())}</span>
                    </span>

                </li>
            ))
        } else {
            return (
                <li
                    className="listgroupitem"
                >
                    <span className="memberHeaderLink">
                        No data
                    </span>
                </li>
            )
        }
    }

    var today = moment();
    var valid = function (current) {
        return current.isAfter(today);
    };

    const renderTemplate = ({
        id,
        formdata,
        change,
        theClass,
        theFunction,
        maxLength,
        disabled
    }) => {
        let formTemplate = null;

        const UseFocus = () => {
            const htmlElRef = React.useRef(null)
            const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

            return [htmlElRef, setFocus]
        }

        const [inputRef, setInputFocus] = UseFocus()

        switch (formdata.element) {
            case ('input'):
                formTemplate = (
                    <div>
                        <div className="iconPosition">
                            {icons}
                        </div>
                        <div className="formBlock">
                            <input
                                {...formdata.config}
                                value={formdata.value || ''}
                                onChange={(event) => change({ event, id })}
                                className={disabled ? `${theClass} disabled` : theClass}
                                maxLength={maxLength ? maxLength : 10000}
                                disabled={disabled}
                            />
                            {showError()}
                        </div>
                    </div>
                )
                break;
            case ('inputWF'):
                formTemplate = (
                    <div>
                        <div className="iconPosition">
                            {icons}
                        </div>
                        <div className="formBlock">
                            <input
                                {...formdata.config}
                                value={formdata.value || ''}
                                onChange={(event) => change({ event, id })}
                                className={disabled ? `${theClass} disabled` : theClass}
                                maxLength={maxLength ? maxLength : 10000}
                                disabled={disabled}
                                onKeyPress={event =>
                                    event.key === 'Enter' ?
                                        theFunction(event)
                                        : null
                                }
                            />
                            {showError()}
                        </div>
                    </div>
                )
                break;
            case ('selectobject'):
                formTemplate = (
                    <div>
                        <div className="iconPosition">
                            {icons}
                        </div>
                        <div className="formBlock selectobject">
                            {
                                searchWFMode ?
                                    <div ref={selectWFSearchRef}>
                                        <input
                                            {...formdata.config}
                                            value={formdata.search || ''}
                                            onChange={(event) => searchChange({ event, id })}
                                            className={disabled ? `${theClass} disabled` : theClass}
                                            // maxLength={maxLength ? maxLength : 10000}
                                            disabled={false}
                                            autoFocus={true}
                                            autoCapitalize="none"
                                            autoComplete="off"
                                            autoCorrect="off"
                                        />
                                        <ul
                                            className="dropdownmenu listgroup profilemenu notboolean"
                                        >
                                            {showLinks(formdata.options, id)}
                                        </ul>
                                    </div>
                                    :
                                    <div
                                        id={id}
                                        onClick={disabled ? null : () => searchWFModeFunction()}
                                        className={disabled ? `${theClass} disabled` : theClass}
                                    >
                                        {
                                            isObjectEmpty(formdata.value) ?
                                                `${formdata.config.placeholder}` :
                                                formdata.value[thekey]
                                        }
                                    </div>
                            }
                            {showError()}
                        </div>
                    </div>
                )
                break;
            case ('selectarray'):
                formTemplate = (
                    <div>
                        <div className="iconPosition">
                            {icons}
                        </div>
                        <div className="formBlock">
                            {
                                searchArrayMode ?
                                    <div ref={selectWSearchArrayRef}>
                                        <input
                                            {...formdata.config}
                                            value={formdata.search || ''}
                                            onChange={(event) => searchChange({ event, id })}
                                            className={disabled ? `${theClass} disabled` : theClass}
                                            // maxLength={maxLength ? maxLength : 10000}
                                            disabled={false}
                                            autoFocus={true}
                                            autoCapitalize="none"
                                            autoComplete="off"
                                            autoCorrect="off"
                                        />
                                        <ul
                                            className="dropdownmenu listgroup profilemenu notboolean"
                                        >
                                            {showLinks(formdata.options, id)}
                                        </ul>
                                    </div>
                                    :
                                    <div
                                        id={id}
                                        onClick={disabled ? null : () => searchWFArrayModeFunction()}
                                        className={disabled ? `${theClass} disabled` : theClass}
                                    >
                                        {
                                            isObjectEmpty(formdata.value) ?
                                                <div style={{ fontWeight: 300, color: '#A0A0A0' }}>{formdata.config.placeholder}</div> :
                                                formdata.value[thekey]
                                        }
                                    </div>
                            }
                            {showError()}
                        </div>
                    </div>
                )
                break;
            case ('selectboolean'):
                formTemplate = (
                    <div>
                        <div className="iconPosition">
                            {icons}
                        </div>
                        <div className="formBlock selectboolean">
                            {
                                openMode ?
                                    <div ref={wrapperRef}>
                                        <input
                                            {...formdata.config}
                                            value={formdata.value.name}
                                            className="formSearch"
                                            disabled
                                            autoCapitalize="none"
                                            autoComplete="off"
                                            autoCorrect="off"
                                        />
                                        <ul
                                            className="dropdownmenu listgroup profilemenu"
                                        >
                                            {showLinks(formdata.options, id)}
                                        </ul>
                                    </div>
                                    :
                                    <div
                                        id={id}
                                        onClick={disabled ? null : () => openFunction()}
                                        className={disabled ? `${theClass} disabled` : theClass}
                                    >
                                        {
                                            formdata.value.name.length > 50 ? `${formdata.value.name.substring(0, 55)}...` : formdata.value.name
                                        }
                                    </div>
                            }
                            {showError()}
                        </div>
                    </div>
                )
                break;
            case ('selectbooleanwithbutton'):
                formTemplate = (
                    <div>
                        <div className='buttonWrapper'>
                            <div className="formBlock selectboolean">
                                {
                                    openMode ?
                                        <div ref={wrapperRef}>
                                            <div
                                                className='inputarrayWrapper'
                                            >
                                                <input
                                                    {...formdata.config}
                                                    value={formdata.search || ''}
                                                    onChange={(event) => searchChange({ event, id })}
                                                    className={disabled ? `inputarray disabled` : 'inputarray'}
                                                    // maxLength={maxLength ? maxLength : 10000}
                                                    disabled={false}
                                                    autoFocus={true}
                                                    autoCapitalize="none"
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                />
                                                <ul
                                                    className="dropdownmenu listgroup profilemenu"
                                                >
                                                    {showLinksWithMoreInfo(formdata.options, id)}
                                                </ul>
                                            </div>

                                        </div>
                                        :
                                        <div
                                            id={id}
                                            onClick={disabled ? null : () => openFunction()}
                                            className={disabled ? `${theClass} disabled mr20` : `${theClass} mr20`}
                                        >
                                            {
                                                formdata.selected !== "" ? formdata.selected && formdata.selected.name : 'Please click here to choose sosmed'
                                            }
                                        </div>
                                }
                                {showError()}
                            </div>
                            <div
                                className="iconButton"
                                onClick={(event) => addForm({ event, id })}
                            >
                                {icons}
                            </div>
                        </div>
                        {
                            formdata.value && formdata.value.length > 0 ?
                                <div
                                    style={{
                                        width: '100%',
                                        marginTop: '30px'
                                    }}
                                >
                                    {
                                        formdata.value.map((data, index) => {
                                            let dataid = data.value;
                                            return (
                                                <div
                                                    key={index}
                                                    className={`tablearray`}
                                                    onClick={(event) => removeForm({ event, id, dataid })}
                                                >
                                                    {index + 1}. <span>{data.saveas} ({data.provider})</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                <div
                                    className={`tablearray`}
                                >
                                    no data
                                </div>
                        }
                    </div>
                )
                break;
            case ('selectphone'):
                formTemplate = (
                    <div>
                        <div className="iconPosition">
                            {icons}
                        </div>
                        <div className="formBlock">
                            {
                                searchMode ?
                                    <div ref={selectWSearchObjectRef}>
                                        <span
                                            className='selectIcon'
                                            onClick={disabled ? null : () => searchModeFunction()}
                                        >
                                            {
                                                formdata.searchValue.dialCode
                                            }
                                        </span>
                                        <input
                                            name={'phoneInput'}
                                            type='text'
                                            placeholder={'Type here'}
                                            value={formdata.search || ''}
                                            onChange={(event) => searchChange({ event, id })}
                                            className={disabled ? `${theClass} disabled` : theClass}
                                            maxLength={maxLength ? maxLength : 10000}
                                            disabled={false}
                                            autoFocus={true}
                                        />
                                        <ul
                                            className="dropdownmenu listgroup profilemenu notboolean"
                                        >
                                            {showLinks(formdata.options, id)}
                                        </ul>
                                    </div>
                                    :
                                    <div
                                        id={id}
                                    >
                                        <span
                                            className='selectIcon'
                                            onClick={disabled ? null : () => searchModeFunction()}
                                        >
                                            {
                                                formdata.searchValue.dialCode
                                            }
                                        </span>
                                        <input
                                            {...formdata.config}
                                            value={formdata.value || ''}
                                            onChange={(event) => change({ event, id })}
                                            className={disabled ? `${theClass} disabled` : theClass}
                                            maxLength={maxLength ? maxLength : 10000}
                                            disabled={disabled}
                                        />
                                    </div>
                            }
                            {showError()}
                        </div>
                    </div>
                )
                break;
            case ('date'):
                formTemplate = (
                    <div>
                        <div className="iconPosition">
                            {icons}
                        </div>
                        <div className="formBlock">
                            <Datetime
                                initialValue={formdata.value !== "" ? moment(formdata.value, 'x').format('DD-MM-YYYY') : new Date(formdata.value)}
                                initialViewDate={new Date(formdata.value)}
                                value={new Date(formdata.value)}
                                onChange={(event) => change({ event, id })}
                                closeOnSelect={true}
                                dateFormat="DD-MM-YYYY"
                                timeFormat={false}
                                inputProps={{ placeholder: `${formdata.config.placeholder}` }}
                                isValidDate={valid}
                                className={disabled ? 'form-control disabled' : formdata.validation && !formdata.valid ? 'form-control errordate' : 'form-control'}
                            />
                        </div>
                        {showError()}
                    </div>
                )
                break;
            case ('radio'):
                break;
            case ('textarea'):
                formTemplate = (
                    <div className="formBlock">
                        <textarea
                            {...formdata.config}
                            value={formdata.value}
                            onChange={(event) => change({ event, id })}
                            className={disabled ? `${theClass} disabled` : theClass}
                            disabled={disabled}
                        />
                        {showError()}
                    </div>
                )
                break;
            case ('logo'):
                formTemplate = (
                    <div className='innerWrapper'>
                        <div
                            className={
                                formdata.config.type === 'mobile' ?
                                    `mobilelogoinnerwrapper ${disabled ? 'disabled' : null}`
                                    : `logoinnerwrapper ${disabled ? 'disabled' : null}`
                            }
                            onClick={disabled ? null : (event) => change(event)}
                        >
                            {
                                formdata.value.url && formdata.value.alt ?
                                    <img src={formdata.value.url} alt={formdata.value.alt} />
                                    :
                                    <span><FaCloudUploadAlt /> {formdata.config.type === 'mobile' ? null : 'Upload logo'}</span>
                            }
                        </div>
                    </div>
                )
                break;
            case ('tags'):
                formTemplate = (
                    <div className='tagsOuter'>
                        <div className='tagsContainer'>
                            <div
                                className='tagsWrapper'
                                onClick={() => setInputFocus()}
                            >
                                {
                                    formdata.value && formdata.value.map((tag, index) => (
                                        <div className='tagItem' key={index}>
                                            <span className='text'>{tag.name}</span>
                                            <span
                                                className='close'
                                                onClick={(event) => theFunction({ event, id, index })}
                                            >&times;</span>
                                        </div>
                                    ))
                                }
                                <input
                                    ref={inputRef}
                                    {...formdata.config}
                                    onKeyDown={(event) => change({ event, id })}
                                    className={disabled ? `${theClass} disabled` : theClass}
                                />
                            </div>
                        </div>
                        {showError()}
                    </div>
                )
                break;
            default:
                return formTemplate = null;
        }

        return formTemplate;
    }

    const togglePassword = () => {
        isRevealPasswordHandler(!isRevealPassword);
    }
    // console.log(formdata.config.type, '<<<asdfjsafdasf')
    return (
        <div className="formGroup">
            {
                showName ?
                    <label className="controlLabel">{formdata.title}</label>
                    : null
            }
            <div className={id === 'tags' ? 'formWrapper tagsonly' : 'formWrapper'}>
                {
                    renderTemplate({
                        id,
                        formdata,
                        change,
                        theClass,
                        theFunction,
                        maxLength,
                        disabled
                    })
                }
                {
                    id === 'password' ?
                        <span
                            className="customicon"
                            onClick={() => togglePassword()}
                            style={showName ? { right: 33 } : { right: 12 }}
                        >
                            {
                                isRevealPassword ? <FaEye /> : <FaEyeSlash />
                            }
                        </span>
                        : null
                }
            </div>
        </div>
    )
})

export default FormField;