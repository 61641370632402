export const GET_WEBSITE_SETTING = 'get_website_setting';
export const SUBMIT_WEBSITE_SETTING = 'submit_website_setting';

export const GET_WEBSITE_ADDRESS = 'get_website_address';
export const ADD_WEBSITE_ADDRESS = 'add_website_address';
export const UPDATE_WEBSITE_ADDRESS = 'update_website_address';

export const GET_WEBSITE_EMAIL = 'get_website_email';
export const ADD_WEBSITE_EMAIL = 'add_website_email';
export const UPDATE_WEBSITE_EMAIL = 'update_website_email';

export const GET_WEBSITE_NUMBER = 'get_website_number';
export const ADD_WEBSITE_NUMBER = 'add_website_number';
export const UPDATE_WEBSITE_NUMBER = 'update_website_number';

export const GET_WEBSITE_SOSMED = 'get_website_sosmed';
export const ADD_WEBSITE_SOSMED = 'add_website_sosmed';
export const UPDATE_WEBSITE_SOSMED = 'update_website_sosmed';