import {
    GET_GALLERY,
    GET_GALLERY_CATEGORY,
    GET_GALLERY_CATEGORY_DISTINCT,
    GET_GALLERY_TAGS,
    GET_MEDIA_GALLERY,
    GET_FILTERED_GALLERY
} from '../../types/frontend/types';

export default function galleryReducer(state = {}, action) {
    switch (action.type) {
        case GET_FILTERED_GALLERY:
            return {
                ...state,
                filteredGallery: action.payload.newgallery,
                galleryLength: action.payload.gallerylength,
            };
        case GET_MEDIA_GALLERY:
            return {
                ...state,
                getMediaGallery: action.payload
            }
        case GET_GALLERY:
            return {
                ...state,
                getGallery: action.payload
            }
        case GET_GALLERY_CATEGORY:
            return {
                ...state,
                getGalleryCategory: action.payload
            }
        case GET_GALLERY_CATEGORY_DISTINCT:
            return {
                ...state,
                getGalleryCategoryDistinct: action.payload
            }
        case GET_GALLERY_TAGS:
            return {
                ...state,
                getGalleryTags: action.payload
            }
        default:
            return state;
    }
}