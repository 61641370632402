import React from 'react';

import {
    useDispatch
} from 'react-redux';

import {
    useNavigate,
    useParams
} from 'react-router-dom';

import { useWindowSize } from '../../../utils/windowsize';
import { mobileSize, tabletSize, isBiggerTablet } from '../data/settings';

import {
    getGalleryFunction
} from '../../../store/actions/frontend/gallery';

import CantDecideSection from '../cantdecide';
import GalleryMain from '../widget/gallery';

const SingleGallery = (props) => {
    const { theme } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let searchParams = useParams();
    let id = searchParams.id;

    const size = useWindowSize();
    const isMobile = size.width <= mobileSize.width;

    const [loading, loadingHandler] = React.useState(false);
    const [dataselected, dataselectedHandler] = React.useState([]);

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(getGalleryFunction())
            }
        }
        fetchData().then(async (response) => {
            if (response && response.data.success) {
                let theData = response.data.data;
                let selected = theData && theData.length > 0 && theData.filter(data => data._id === id)[0];
                if (selected === undefined) navigate('/gallery')
                dataselectedHandler(selected);
            }
        })
    }, [dispatch, navigate, id])

    return (
        <section id="gallerypage" className={`homepage ${theme}`}>
            <div className="contentwrap" style={{ transform: "none" }}>
                <div className="section dividersection" style={{ marginTop: isMobile ? '0px' : '70px' }}>
                    <div className="container">
                        <div
                            className="headingblock center mb30"
                        >
                            <h2 className='aboutTitle'>
                                {dataselected && dataselected.name}
                            </h2>
                        </div>
                        <div className="row">
                            <div className='lumi-md-12 lumi-xs-12'>
                                <div className='cardWrapper'>
                                    <div className='lumi-md-12 lumi-xs-12 pb20 pl0 pr0'>
                                        <div className='cardMedia'>
                                            <div className='mediaList'>
                                                {
                                                    dataselected.images && dataselected.images.length > 0 ?
                                                        <GalleryMain
                                                            images={dataselected && dataselected.images}
                                                            theme={theme}
                                                        />
                                                        :
                                                        <div className={`lumiGalleryWrapper ${theme}`}>
                                                            No images
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {
                 !loading && openmedia &&
                 <ClientBackdrop click={mediaClickHandler} />
             } */}
                        </div >
                    </div>
                </div>
                <CantDecideSection
                    isMobile={isMobile}
                />
            </div>
        </section>
    )
}

export default SingleGallery;