import {
    GET_WEBSITE_SETTINGS,
    GET_WEBSITE_ADDRESS
} from '../../../types/frontend/types';

export const getWebsiteSettings = (request) => ({
    type: GET_WEBSITE_SETTINGS,
    payload: request
})

export const getWebsiteAddress = (request) => ({
    type: GET_WEBSITE_ADDRESS,
    payload: request
})