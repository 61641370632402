import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';

// must comment before push to production
import loggerMiddleware from './logger';

import rootReducer from './store';

import promiseMiddleware from 'redux-promise';

const monitorReducerEnhancer = createStore => (reducer, initialState, enhancer) => {
    const monitoredReducer = (state, action) => {
        const newState = reducer(state, action);
        return newState;
    }
    return createStore(monitoredReducer, initialState, enhancer);
}

export default function store() {
    let middleware = [];
    let enhancers = [];

    if (process.env.NODE_ENV === 'development') {
        middleware = [promiseMiddleware, loggerMiddleware, thunkMiddleware];
        enhancers.push(monitorReducerEnhancer);
    } else {
        middleware = [promiseMiddleware, thunkMiddleware];
    }

    return configureStore({
        reducer: rootReducer,
        middleware: middleware,
        enhancers: enhancers
    })
}