import React from 'react';

const PinItem = React.forwardRef((props, ref) => {
    const {
        value,
        required,
        onChange,
        type,
        disabled,
        secret,
        onBackspace,
        theClass,
        ...other
    } = props;

    const [state, setState] = React.useState({ value, focus: false });
    const inputRef = React.useRef(null);

    React.useImperativeHandle(ref, () => ({
        focus: () => inputRef.current.focus(),
    }));

    const onKeyDown = (e) => {
        if (e.keyCode === 8 && (!state.value || !state.value.length)) {
            onBackspace();
        }
    };

    const handleOnChange = (e) => {
        const value = validate(e.target.value);
        if (state.value === value) return;
        if (value.length < 2) {
            setState((prevState) => ({
                ...prevState,
                value,
            }));

            setTimeout(() => onChange(value), 0);
        }
    };

    const onFocus = (e) => {
        e.target.select();
        setState((prevState) => ({
            ...prevState,
            focus: true,
        }));
    };

    const onBlur = () => {
        setState((prevState) => ({
            ...prevState,
            focus: false,
        }));
    };

    const validate = (value) => {
        if (type === 'number') {
            const numCode = value.charCodeAt(0);
            const isInteger =
                numCode >= '0'.charCodeAt(0) && numCode <= '9'.charCodeAt(0);
            return isInteger ? value : '';
        }
        return value.toUpperCase();
    };

    const getPattern = () => {
        if (type === 'number') {
            return '[0-9]*';
        }
        if (type === 'text') {
            return '[A-Z0-9]*';
        }
    };

    return (
        <input
            {...other}
            value={state.value || ''}
            disabled={disabled}
            ref={inputRef}
            required={required}
            onChange={handleOnChange}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            type={secret ? 'password' : 'text'}
            pattern={getPattern()}
            aria-label={state.value}
            placeholder={state.value}
            className={theClass}
            maxLength={1}
            autoComplete='off'
            inputMode='text'
        />
    )
})

export default PinItem;