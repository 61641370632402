import React from 'react';

import {
    Outlet,
    useLocation,
    useNavigate,
    useOutletContext
} from 'react-router-dom';

import { HeaderPage } from '../globalfunction';
import { mobile } from '../globalsize';

const TemplateDashboard = ({
    props,
    pageName,
    pageNav,
    showHeader = true
}) => {

    const { theme } = props;

    const location = useLocation();
    const navigate = useNavigate();

    const globaldata = useOutletContext();
    const {
        size
    } = globaldata;

    const isMobile = mobile(size);

    const goToLocation = (nextlocation) => {
        navigate(nextlocation);
    }

    const printNavigation = (theNav, location) => {
        return theNav.map((nav, index) => {
            const sameLocation = nav.pathname === location.pathname ? 'active' : '';
            return (
                <li
                    className={sameLocation} key={index}
                    onClick={() => goToLocation(nav.pathname)}
                >
                    <span>{nav.icon}</span>
                    {
                        isMobile ? null :
                            nav.showName ?
                                <span className='navTitle'>{nav.name}</span>
                                : null
                    }
                </li>
            )
        })
    }

    return (
        <div className='mainBodyWrapper'>
            {
                showHeader ?
                    HeaderPage(pageName)
                    : null
            }
            {
                <div className='dashboardNavigation'>
                    <ul>
                        {
                            printNavigation(pageNav, location)
                        }
                    </ul>
                </div>
            }
            <Outlet
                context={{
                    size,
                    theme
                }}
            />
        </div>
    )
}

export default TemplateDashboard;