import React from 'react';

const FormButton = ({
    theName,
    theClass,
    theType,
    theTitle,
    theFunction
}) => {

    const renderTemplate = ({
        theName,
        theClass,
        theType,
        theTitle,
        theFunction
    }) => {
        let buttonTemplate = null;

        switch (theName) {
            case 'submitNF':
                buttonTemplate =
                    <button
                        className={theClass}
                        type={theType}
                    >
                        {theTitle}
                    </button>
                break;
            case 'submitWF':
                buttonTemplate =
                    <button
                        className={theClass}
                        type={theType}
                        onClick={theFunction()}
                    >
                        {theTitle}
                    </button>
                break;
            default:
                return buttonTemplate = null
        }
        return buttonTemplate

    }

    return (
        <div className="buttonWrapper">
            {
                renderTemplate({
                    theName,
                    theClass,
                    theType,
                    theTitle,
                    theFunction
                })
            }
        </div>
    )
}

export default FormButton