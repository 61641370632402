import React from 'react';

import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { usePagination } from './usePagination';

const Pagination = ({
    totalCount,
    pageSize,
    onPageChanged,
    currentPage,
    width,
    lastPage
}) => {
    
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        pageSize
    })
    
    const onBeginning = () => {
        onPageChanged(1);
    }

    const onNext = () => {
        onPageChanged(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChanged(currentPage - 1);
    };

    const onLast = () => {
        onPageChanged(lastPage);
    }

    return (
        <ul className={`${width ? "pagination justifyContentCenter posInherit" : "pagination justifyContentCenter"}`}>
            <li
                className={`pageItem ${currentPage === 1 ? 'disabled' : null}`}
                onClick={currentPage === 1 ? null : () => onBeginning()}
            >
                <span
                    className="pageLink"
                >
                    <FaAngleDoubleLeft
                        className="icon agraicon w18px"
                    />
                </span>
            </li>
            <li
                className={`pageItem ${currentPage === 1 ? 'disabled' : null}`}
                onClick={currentPage === 1 ? null : () => onPrevious()}
            >
                <span
                    className="pageLink"
                >
                    <FaAngleLeft
                        className="icon agraicon w18px"
                    />
                </span>
            </li>
            {
                paginationRange.map(i => {
                     
                    return (
                        <li
                            key={i}
                            className={`pageItem ${currentPage === i ? 'active' : null}`}
                            onClick={i === currentPage ? null : () => onPageChanged(i)}
                        >
                            <span className="pageLink">{i}</span>
                        </li>
                    )
                })
            }
            <li
                className={`pageItem ${currentPage === lastPage ? 'disabled' : null}`}
                onClick={currentPage === lastPage ? null : () => onNext()}
            >
                <span
                    className="pageLink"
                >
                    <FaAngleRight
                        className="icon agraicon w18px"
                    />
                </span>
            </li>
            <li
                className={`pageItem ${currentPage === lastPage ? 'disabled' : null}`}
                onClick={currentPage === lastPage ? null : () => onLast()}
            >
                <span
                    className="pageLink"
                >
                    <FaAngleDoubleRight
                        className="icon agraicon w18px"
                    />
                </span>
            </li>
        </ul>
    )
}

export default Pagination;