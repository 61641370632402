import React from 'react';

import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useNavigate,
    useOutletContext
} from 'react-router-dom';

import GlobalTableTemplate from '../../utils/table/globaltable';
import { getWebsiteAddress } from '../../../store/actions/website';

const WebsiteSettingAddress = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        size
    } = globaldata;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theUser = useSelector(state => state.auth && state.auth.data);

    const [loading, loadingHandler] = React.useState(false);
    const [alldata, alldataHandler] = React.useState([]);

    let formname = 'Address'
    let address = [
        {
            name: 'Company name',
            code: 'companyname'
        },
        {
            name: 'Address',
            code: 'address'
        },
        {
            name: 'Email',
            code: 'email'
        },
        {
            name: 'Phone',
            code: 'phone'
        },
        {
            name: 'Phone 2',
            code: 'phone2'
        }
    ]

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                const address = await dispatch(getWebsiteAddress());
                if (address.status === 200 && address.data.success) {
                    alldataHandler(address.data.data)
                }
            }
        }
        fetchData()
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate, theUser])

    const allURLs = async () => {
        loadingHandler(true)
        const address = await dispatch(getWebsiteAddress());
        if (address.status === 200 && address.data.success) {
            alldataHandler(address.data.data)
        }
        loadingHandler(false)
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/website/setting')
    }

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                {
                    <GlobalTableTemplate
                        tableId={formname}
                        tableName={`${formname} Table`}
                        alldata={alldata}
                        datatoshow={address}
                        pageSize={5}
                        loading={loading}
                        allURLs={allURLs}
                        theTheme={theme}
                        navigateto={'website/address'}
                        backStatus={true}
                        backFunction={(event) => goBack(event)}
                    />
                }
            </div>
        </div>
    )
}

export default WebsiteSettingAddress;