export const GET_GALLERY = 'get_gallery';
export const ADD_GALLERY = 'add_gallery';
export const UPDATE_GALLERY = 'update_gallery';

export const GET_GALLERY_CATEGORY = 'get_gallery_category';
export const ADD_GALLERY_CATEGORY = 'add_gallery_category';
export const UPDATE_GALLERY_CATEGORY = 'update_gallery_category';

export const GET_GALLERY_TAGS = 'get_gallery_tags';
export const ADD_GALLERY_TAGS = 'add_gallery_tags';
export const UPDATE_GALLERY_TAGS = 'update_gallery_tags';

export const GET_MEDIA_GALLERY = 'get_media_gallery';
export const ADD_MEDIA_GALLERY = 'add_media_gallery';
export const DELETE_MEDIA_GALLERY = 'delete_media_gallery';