import { useState } from "react";


export default (input) => {
    const [touchStartX, setTouchStartX] = useState(null)
    const [touchEndX, setTouchEndX] = useState(null)
    
    const [touchStartY, setTouchStartY] = useState(null)
    const [touchEndY, setTouchEndY] = useState(null)

    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEndX(null)
        setTouchStartX(e.targetTouches[0].clientX)

        setTouchEndY(null)
        setTouchStartY(e.targetTouches[0].clientY)
    }

    const onTouchMove = (e) => {
        setTouchEndX(e.targetTouches[0].clientX)
        setTouchEndY(e.targetTouches[0].clientY)
    }

    const onTouchEnd = () => {
        if (!touchStartX || !touchEndX) return;
        const xDistance = touchStartX - touchEndX
        const yDistance = touchStartY - touchEndY
        if (Math.abs(yDistance) >= Math.abs(xDistance)) {
            return;
        }

        const isLeftSwipe = xDistance > minSwipeDistance
        const isRightSwipe = xDistance < -minSwipeDistance

        if (isLeftSwipe) {
            input.onSwipedLeft();
        }
        if (isRightSwipe) {
            input.onSwipedRight();
        }
    }

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd
    }
}