import * as data from './data.website';
import * as global from '../global/data.global';
import axios from '../api';

import { checkError } from '../global/function';

import {
    getAuthHeader,
    tokenName
} from '../../../views/utils/tools';

// SETTING
export const getWebsiteSetting = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/website/getwebsitesetting');
            dispatch(data.getWebsiteSetting(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const submitSetting = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const setting = await axios.post(`/api/website/submitsetting`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.submitWebsiteSetting(setting.data.data));
            return setting;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

// ADDRESS
export const getWebsiteAddress = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/website/getwebsiteaddress');
            dispatch(data.getWebsiteAddress(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addNewAddress = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const address = await axios.post(`/api/website/addressadd`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addWebsiteAddress(address.data.data));
            return address;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateAddress = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const address = await axios.post(`/api/website/addressupdate`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateWebsiteAddress(address.data.data));
            return address;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

// EMAIL
export const getWebsiteEmail = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/website/getwebsiteemail');
            dispatch(data.getWebsiteEmail(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addNewEmail = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const email = await axios.post(`/api/website/emailadd`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addWebsiteEmail(email.data.data));
            return email;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateEmail = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const email = await axios.post(`/api/website/emailupdate`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateWebsiteEmail(email.data.data));
            return email;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

// NUMBER
export const getWebsiteNumber = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/website/getwebsitenumber');
            dispatch(data.getWebsiteNumber(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addNewNumber = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const number = await axios.post(`/api/website/numberadd`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addWebsiteNumber(number.data.data));
            return number;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateNumber = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const number = await axios.post(`/api/website/numberupdate`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateWebsiteNumber(number.data.data));
            return number;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

// SOSMED
export const getWebsiteSosmed = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/website/getwebsitesosmed');
            dispatch(data.getWebsiteSosmed(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addNewSosmed = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const sosmed = await axios.post(`/api/website/sosmedadd`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addWebsiteSosmed(sosmed.data.data));
            return sosmed;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateSosmed = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const sosmed = await axios.post(`/api/website/sosmedupdate`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateWebsiteSosmed(sosmed.data.data));
            return sosmed;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}