import * as global from '../global/data.global';
import * as data from './data.client';
import axios from 'axios';

import { checkError } from '../global/function';

import {
    getAuthHeader,
    tokenName
} from '../../../views/utils/tools';

// import { clientGetToken } from '../../../views/utils/tools';

axios.defaults.headers.post['Content-Type'] = 'application/json';

export const getallClient = () => {
    return async (dispatch) => {
        try {
            const client = await axios.get(`/api/admin/alladmin`);
            dispatch(data.clientGetAll(client.data.data));
            return client;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getallRoles = () => {
    return async (dispatch) => {
        try {
            const roles = await axios.get(`/api/admin/allroles`);
            dispatch(data.rolesGetAll(roles.data.data));
            return roles;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addNewAdmin = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const admin = await axios.post(`/api/admin/adminadd`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addNewAdmin(admin.data.data));
            return admin;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateAdmin = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const admin = await axios.patch(`/api/admin/adminupdate`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateAdmin(admin.data.data));
            return admin;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}