import {
    GET_TESTIMONI,
    ADD_TESTIMONI,
    UPDATE_TESTIMONI,
    GET_TESTIMONI_CATEGORY,
    ADD_TESTIMONI_CATEGORY,
    UPDATE_TESTIMONI_CATEGORY,
    GET_TESTIMONI_TAGS,
    ADD_TESTIMONI_TAGS,
    UPDATE_TESTIMONI_TAGS,
    GET_MEDIA_TESTIMONI,
    ADD_MEDIA_TESTIMONI,
    DELETE_MEDIA_TESTIMONI
} from '../../types/testimoni';

export const getMediaTestimoni = (request) => ({
    type: GET_MEDIA_TESTIMONI,
    payload: request
})

export const addMediaTestimoni = (request) => ({
    type: ADD_MEDIA_TESTIMONI,
    payload: request
})

export const deleteMediaTestimoni = (request) => ({
    type: DELETE_MEDIA_TESTIMONI,
    payload: request
})

export const getTestimoni = (request) => ({
    type: GET_TESTIMONI,
    payload: request
})

export const addTestimoni = (request) => ({
    type: ADD_TESTIMONI,
    payload: request
})

export const updateTestimoni = (request) => ({
    type: UPDATE_TESTIMONI,
    payload: request
})

// CATEGORY
export const getTestimoniCategory = (request) => ({
    type: GET_TESTIMONI_CATEGORY,
    payload: request
})

export const addTestimoniCategory = (request) => ({
    type: ADD_TESTIMONI_CATEGORY,
    payload: request
})

export const updateTestimoniCategory = (request) => ({
    type: UPDATE_TESTIMONI_CATEGORY,
    payload: request
})

// TAGS
export const getTestimoniTags = (request) => ({
    type: GET_TESTIMONI_TAGS,
    payload: request
})

export const addTestimoniTags = (request) => ({
    type: ADD_TESTIMONI_TAGS,
    payload: request
})

export const updateTestimoniTags = (request) => ({
    type: UPDATE_TESTIMONI_TAGS,
    payload: request
})
