import axios from "axios";
import {
    getTokenCookie,
    tokenName
} from '../../views/utils/tools';

const axiosApiINstance = axios.create();
axiosApiINstance.interceptors.request.use(
    async config => {
        const token = getTokenCookie(tokenName);
        config.headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
)

// Response interceptor for API calls
axiosApiINstance.interceptors.response.use(
    response => {
        return response
    },
    async function (error) {
        const originalRequest = error.config
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.status >= 400 &&
            error.response.status < 500
        ) {
            error.message = error.response.data.message
            originalRequest._retry = true
        } else {
            if (
                error &&
                error.response &&
                error.response.status === 401 &&
                !originalRequest._retry
            ) {
                originalRequest._retry = true
            }
        }
        return Promise.reject(error)
    }
)

export default axiosApiINstance;