import React from 'react';

import {
  BrowserRouter
} from "react-router-dom";

import './resources/website/global.scss';
import './resources/styles/client.scss';
import './websites/lumimaxi/resources/lumimaxi.scss';

import GlobalHOC from './views/hoc/globalHoc';

import { useLocalStorageState } from './views/utils/tools';

import TheRoutes from './mainTheRoutes';

function App() {

  const [theme, themeHandler] = useLocalStorageState("clientTheme", "light");

  return (
    <BrowserRouter basename={'/'}>
      <GlobalHOC theme={theme} themeHandler={themeHandler}>
        <TheRoutes theme={theme} themeHandler={themeHandler} />
      </GlobalHOC>
    </BrowserRouter>
  );
}

export default App;