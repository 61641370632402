import * as global from '../../../store/actions/global/data.global';

export const validate = (element, formdata = []) => {

    let error = [true, ''];

    if (element.validation.email) {
        //eslint-disable-next-line
        const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const valid = expression.test(String(element.value).toLowerCase());
        const message = `${!valid ? 'Enter valid email' : ''}`
        error = !valid ? [valid, message] : error;
    }

    if (element.validation.phone) {
        const expression = /^(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+$/;
        const valid = expression.test(String(element.value).toLowerCase());
        const message = `${!valid ? 'Enter valid phone number' : ''}`
        error = !valid ? [valid, message] : error;
    }

    if (element.validation.price) {
        const expression = /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/;
        const valid = expression.test(String(element.value).toLowerCase());
        const message = `${!valid ? 'Enter valid price' : ''}`
        error = !valid ? [valid, message] : error;
    }

    if (element.validation.number) {
        const expression = /^\d+$/;
        const valid = expression.test(String(element.value).toLowerCase());
        const message = `${!valid ? 'Enter number only' : ''}`
        error = !valid ? [valid, message] : error;
    }

    if (element.validation.strong) {
        const expression = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{6,30}$/;
        const valid = expression.test(element.value);
        const message = `${!valid ? '1 uppercase, 1 number and min 6 letters' : ''}`
        error = !valid ? [valid, message] : error;
    }

    if (element.validation.website) {
        const expression = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm;
        const valid = expression.test(element.value);
        const message = `${!valid ? 'Enter valid URL only' : ''}`
        error = !valid ? [valid, message] : error;
    }

    if (element.validation.confirm) {
        const valid = element.value.trim() === formdata[element.validation.confirm].value;
        const message = `${!valid ? 'Data not match' : ''}`
        error = !valid ? [valid, message] : error;
    }

    if (element.validation.required) {
        const valid = element.value.trim() !== '';
        const message = `${!valid ? 'This field is required' : ''}`
        error = !valid ? [valid, message] : error;
    }

    return error;
}

export const update = (element, formdata, formName = '') => {
    const newFormdata = {
        ...formdata
    }
    const newElement = {
        ...newFormdata[element.id]
    }
    newElement.value = element.id === 'pin' || element.id === 'confirmpin' ? element.value : element.event.target.value;
    let validData = validate(newElement, formdata);
    newElement.valid = validData[0];
    newElement.validationMessage = validData[1];

    newElement.touched = element.id === 'pin' || element.id === 'confirmpin' ? true : element.blur;
    newFormdata[element.id] = newElement;

    return newFormdata;
}

export const updateTags = (element, formdata, formName = '', dispatch) => {
    const newFormdata = {
        ...formdata
    }
    const newElement = {
        ...newFormdata[element.id]
    }

    newElement.validationMessage = ""

    let newTarget = element.event.target.value;
    newTarget = newTarget.toLowerCase();
    newTarget = newTarget.replace(/\s+/g, '');
    newTarget = newTarget.replace(/[^a-zA-Z0-9 ]/g, '');

    if (element.event.key === 'Enter') {
        if (newTarget.length > newElement.maxLength) {
            if (newElement.value.length < newElement.max) {
                const existed = newElement.value.some(el => el.tag === newTarget)
                if (existed) {
                    dispatch((global.globalError(`duplicate value`)))
                } else {
                    newElement.value = [...newElement.value, { name: element.event.target.value, tag: newTarget }];
                    element.event.target.value = '';
                }
            }
        } else {
            newElement.validationMessage = `Minimum ${newElement.maxLength + 1} letters`
        }
    }
    if (element.event.key === 'Backspace' && newElement.value.length > 0 && newTarget === "") {
        let newValue = newElement.value;
        newElement.value = newValue.slice(0, -1);
        if (newElement.value && newElement.value.length === 0) {
            newElement.valid = false;
            newElement.touched = false;
        }
    }

    if (newElement.value && newElement.value.length > 0) {
        newElement.valid = true;
        newElement.validationMessage = "";
        newElement.touched = true;
    }
    if (newElement.value && newElement.value.length > 0 && newElement.value.length === newElement.max) {
        dispatch((global.globalError(`max ${newElement.max} tags`)))
    }

    newFormdata[element.id] = newElement;

    return newFormdata;
}

export const removeTags = (element, formdata, formName = '') => {
    const newFormdata = {
        ...formdata
    }
    const newElement = {
        ...newFormdata[element.id]
    }

    let newValue = newElement.value;
    newElement.value = newValue.filter((el, i) => i !== element.index);

    if (newElement.value && newElement.value.length === 0) {
        newElement.valid = false;
        newElement.validationMessage = '';
        newElement.touched = false;
    } else {
        newElement.valid = true;
        newElement.validationMessage = "";
        newElement.touched = true;
    }

    newFormdata[element.id] = newElement;

    return newFormdata;
}

export const updateSelect = (element, getdata) => {
    const temporaryFormdata = {
        ...getdata
    }

    const newElement = {
        ...temporaryFormdata[element.whichdata]
    }
    newElement.value = element.item
    if (newElement.value !== "") {
        newElement.valid = true;
        newElement.touched = true;
    } else {
        newElement.valid = false;
        newElement.touched = false;
    }

    temporaryFormdata[element.whichdata] = newElement;
    return temporaryFormdata;
}

export const updatePush = (element, getdata, allSosmed) => {
    const temporaryFormdata = {
        ...getdata
    }

    const newElement = {
        ...temporaryFormdata[element.id]
    }

    let newArray = [newElement.selected];
    let newValue = newElement.value;
    if (newValue && newValue.length > 0) {
        let exist = newValue.filter((data) => data.value === newElement.selected.value);
        if (exist && exist.length > 0) {
            newElement.valid = false;
            newElement.touched = false;
            newElement.validationMessage = 'duplicate value'
        } else {
            if (newElement.value && newElement.value.length === 5) {
                newElement.valid = false;
                newElement.touched = false;
                newElement.validationMessage = 'max 5 sosmed'
            } else {
                newElement.value = newValue.concat(newArray);
                newElement.valid = true;
                newElement.touched = true;
                let newSosmed = allSosmed.filter(object => !newElement.value.some(toDelete => toDelete.value === object.value));
                newElement.options = newSosmed;
            }
        }
    } else {
        newElement.value = newArray
        newElement.valid = true;
        newElement.touched = true;
        let newSosmed = allSosmed.filter((data) => data.value !== newElement.selected.value);
        newElement.options = newSosmed;
    }
    newElement.selected = "";
    newElement.search = "";
    if (newElement.value && newElement.value.length === 0) {
        newElement.valid = false;
        newElement.touched = false;
    }

    temporaryFormdata[element.id] = newElement;
    return temporaryFormdata;
}

export const removePush = (element, getdata, allSosmed) => {
    const temporaryFormdata = {
        ...getdata
    }

    const newElement = {
        ...temporaryFormdata[element.id]
    }

    if (newElement.value && newElement.value.length > 0) {
        newElement.value.splice(newElement.value.findIndex(n => n.value === element.dataid), 1)
        let newSosmed = allSosmed.filter(object => !newElement.value.some(toDelete => toDelete.value === object.value));
        newElement.options = newSosmed;
    } else {
        newElement.valid = false;
        newElement.touched = false;
        newElement.options = allSosmed;
    }
    newElement.selected = "";
    newElement.search = "";

    temporaryFormdata[element.id] = newElement;
    return temporaryFormdata;
}

export const updateToSelected = (element, getdata) => {
    const temporaryFormdata = {
        ...getdata
    }

    const newElement = {
        ...temporaryFormdata[element.whichdata]
    }

    newElement.selected = element.item;
    newElement.search = "";
    temporaryFormdata[element.whichdata] = newElement;
    return temporaryFormdata;
}

// function isNumber(evt) {
//     evt = (evt) ? evt : window.event;
//     var charCode = (evt.key) ? evt.key : evt.keyCode;
//     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
//         return false;
//     }
//     return true;
// }

export const updateSearch = (element, formdata, formName = '') => {
    const newFormdata = {
        ...formdata
    }
    const newElement = {
        ...newFormdata[element.id]
    }

    newElement.search = element.event.target.value;
    newFormdata[element.id] = newElement;

    return newFormdata;
}

export const removeKey = (key, { [key]: _, ...rest }) => rest;

export const generateData = (formdata, formName = '') => {
    let dataToSubmit = {};

    for (let key in formdata) {
        dataToSubmit[key] = formdata[key].value;
    }

    return dataToSubmit;
}

export const isFormValid = (formdata, formName = '') => {
    let formIsValid = true;

    for (let key in formdata) {
        formIsValid = formdata[key].valid && formIsValid;
    }

    return formIsValid;
}

export const resetFields = (formdata, formName, dataselected = {}) => {
    const newFormdata = { ...formdata };

    for (let key in newFormdata) {
        if (newFormdata[key].element === 'selectboolean') {
            newFormdata[key].value = dataselected[key] === true ? { value: true, name: 'YES' } : { value: false, name: 'NO' };
        } else if (newFormdata[key].element === 'selectphone') {
            newFormdata[key].value = '';
            newFormdata[key].search = '';
            newFormdata[key].searchValue = newFormdata[key].default;
        } else if (newFormdata[key].element === 'logo') {
            newFormdata[key].value = { url: "", alt: "" };
        } else if (newFormdata[key].value.isArray) {
            newFormdata[key].value = [];
        } else {
            newFormdata[key].value = '';
        }
        newFormdata[key].valid = newFormdata[key].validation.required ? false : true;
        newFormdata[key].touched = newFormdata[key].validation.required ? false : true;
        newFormdata[key].validationMessage = '';
    }

    return newFormdata;
}

export const defaultFillFields = (formdata, formName, dataselected = {}) => {
    const newFormdata = { ...formdata };

    for (let key in newFormdata) {
        if (newFormdata[key].element === 'selectboolean') {
            newFormdata[key].value = dataselected[key] === true ? { value: true, name: 'YES' } : { value: false, name: 'NO' };
        } else if (newFormdata[key].element === 'selectphone') {
            newFormdata[key].value = dataselected[key] ? dataselected[key] : '';
            newFormdata[key].search = '';
            newFormdata[key].searchValue = newFormdata[key].default;
        } else if (newFormdata[key].value.isArray) {
            newFormdata[key].value = dataselected[key] ? dataselected[key] : [];
        } else {
            newFormdata[key].value = dataselected[key] ? dataselected[key] : '';
        }
        newFormdata[key].valid = dataselected[key] ? true : newFormdata[key].validation.required ? false : true;
        newFormdata[key].touched = dataselected[key] ? true : newFormdata[key].validation.required ? false : true;
        newFormdata[key].validationMessage = '';
    }

    return newFormdata;
}

export function isEmpty(str) {
    return (!str || str.length === 0);
}

// export const convertToRupiah = (angka) => {
//     var num = angka.replace(/,/gi, "");
//     var num2 = num.split(/(?=(?:\d{3})+$)/).join(",");
//     return num2;
// }

export const convertToRupiah = (angka) => {
    var rupiah = '';
    var angkarev = angka.toString().split('').reverse().join('');
    for (var i = 0; i < angkarev.toString().length; i++) if (i % 3 === 0) rupiah += angkarev.toString().substr(i, 3) + ',';
    return rupiah.split('', rupiah.length - 1).reverse().join('');
}