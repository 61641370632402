import {
    GET_WEBSITE_SETTING,
    SUBMIT_WEBSITE_SETTING,
    GET_WEBSITE_ADDRESS,
    ADD_WEBSITE_ADDRESS,
    UPDATE_WEBSITE_ADDRESS,
    GET_WEBSITE_EMAIL,
    ADD_WEBSITE_EMAIL,
    UPDATE_WEBSITE_EMAIL,
    GET_WEBSITE_NUMBER,
    ADD_WEBSITE_NUMBER,
    UPDATE_WEBSITE_NUMBER,
    GET_WEBSITE_SOSMED,
    ADD_WEBSITE_SOSMED,
    UPDATE_WEBSITE_SOSMED
} from "../types/website";

export default function websiteReducer(state = {}, action) {
    switch (action.type) {

        case GET_WEBSITE_SETTING:
            return {
                ...state,
                getWebsiteSetting: action.payload
            }
        case SUBMIT_WEBSITE_SETTING:
            return {
                ...state,
                submitWebsiteSetting: action.payload
            }
        case GET_WEBSITE_ADDRESS:
            return {
                ...state,
                getWebsiteAddress: action.payload
            }
        case ADD_WEBSITE_ADDRESS:
            return {
                ...state,
                addAddress: action.payload
            }
        case UPDATE_WEBSITE_ADDRESS:
            return {
                ...state,
                updateAddress: action.payload
            }
        case GET_WEBSITE_EMAIL:
            return {
                ...state,
                getWebsiteEmail: action.payload
            }
        case ADD_WEBSITE_EMAIL:
            return {
                ...state,
                addEmail: action.payload
            }
        case UPDATE_WEBSITE_EMAIL:
            return {
                ...state,
                updateEmail: action.payload
            }
        case GET_WEBSITE_NUMBER:
            return {
                ...state,
                getWebsiteNumber: action.payload
            }
        case ADD_WEBSITE_NUMBER:
            return {
                ...state,
                addNumber: action.payload
            }
        case UPDATE_WEBSITE_NUMBER:
            return {
                ...state,
                updateNumber: action.payload
            }
        case GET_WEBSITE_SOSMED:
            return {
                ...state,
                getWebsiteSosmed: action.payload
            }
        case ADD_WEBSITE_SOSMED:
            return {
                ...state,
                addSosmed: action.payload
            }
        case UPDATE_WEBSITE_SOSMED:
            return {
                ...state,
                updateSosmed: action.payload
            }
        default:
            return state;
    }
}