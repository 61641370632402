import React from 'react';

import TemplateDashboard from '../../utils/template/dashboard';
import { FiGrid, FiPlus } from 'react-icons/fi';

const WebsiteNumber = (props) => {

    const pageNav = [
        {
            name: 'Number Lists',
            pathname: '/private/website/number/dashboard',
            icon: <FiGrid />,
            showName: true
        },
        {
            name: 'Add Number',
            pathname: '/private/website/number/add',
            icon: <FiPlus />,
            showName: true
        }
    ]

    const pageName = 'Number Dashboard';

    return (
        <TemplateDashboard
            props={props}
            pageName={pageName}
            pageNav={pageNav}
        />
    )
}

export default WebsiteNumber;