import React from 'react';
// import axios from 'axios';

import {
    useDispatch,
    // useSelector
} from 'react-redux';
import {
    useNavigate,
    // useOutletContext
} from 'react-router-dom';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, resetFields, update } from '../../utils/form/formActions';

import FormField from '../../utils/form/formField';

import AdminLoading from '../../utils/circle.loading';

import { addNewEmail, getWebsiteEmail } from '../../../store/actions/website';

import { FiArrowLeft, FiSend, FiUser } from 'react-icons/fi';
import { FaEnvelope } from 'react-icons/fa';


const WebsiteEmailAdd = (props) => {

    const { theme } = props;

    // const globaldata = useOutletContext();
    // const {
    // client, 
    // size
    // } = globaldata;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const theUser = useSelector(state => state.auth && state.auth.data);

    const [loading, loadingHandler] = React.useState(false);
    const [allEmail, allEmailHandler] = React.useState([]);

    // const [heightForm, heightFormHandler] = React.useState(0);

    const maxEmail = 3;

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                loadingHandler(true);
                const email = await dispatch(getWebsiteEmail());
                if (email.status === 200 && email.data.success) {
                    allEmailHandler(email.data.data)
                }
            }
        }
        fetchData().then(() => {
            loadingHandler(false)
        })
    }, [dispatch])

    const formName = 'Add Email';

    const [formdata, formdataHandler] = React.useState({
        saveas: {
            element: 'input',
            title: 'Save as',
            value: '',
            config: {
                name: 'SaveAs',
                type: 'text',
                placeholder: 'Save email as'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        email: {
            element: 'input',
            title: 'Email',
            value: '',
            config: {
                name: 'EmailName',
                type: 'text',
                placeholder: 'Enter email'
            },
            validation: {
                required: true,
                email: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const submitAddEmail = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let formIsValid = isFormValid(formdata, formName);

        if (formIsValid) {
            if (allEmail && allEmail.length === maxEmail) {
                dispatch(global.globalError(`Max email is ${maxEmail}`))
                loadingHandler(false);
            } else {
                let newDataToSubmit = {
                    ...dataToSubmit
                }
                if (allEmail && allEmail.length === 0) {
                    newDataToSubmit.active = true
                } else {
                    newDataToSubmit.active = false
                }

                const result = await dispatch(addNewEmail(newDataToSubmit))
                if (result === undefined || result === 'undefined') {
                    loadingHandler(false);
                }
                if (!result.data.success) {
                    dispatch(global.globalError('please try again'))
                    loadingHandler(false);
                }
                const newFormdata = resetFields(formdata, formName);
                formdataHandler(newFormdata)
                dispatch(global.globalSuccess('email added'))
                setTimeout(() => {
                    navigate('/private/website/email/dashboard')
                    loadingHandler(false);
                }, 2000);
            }
        } else {
            dispatch(global.globalError('Please check your data'))
            loadingHandler(false);
        }
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/website/email/dashboard')
    }

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                <div className='cardWrapper'>
                    <div className='cardHeader' style={{ paddingBottom: 0 }}>
                        <h5>{formName}</h5>
                        <div className='cardOptions'>
                            <ul>
                                <li id="loadingSubmit" className='iconRefresh button' onClick={(event) => goBack(event)}>
                                    <div className='center'>
                                        <span><FiArrowLeft /></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='cardBody'>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'saveas'}
                                                formdata={formdata.saveas}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiUser />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'email'}
                                                formdata={formdata.email}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FaEnvelope />}
                                                formdataHandler={formdataHandler}
                                                showName={true}
                                                disabled={loading ? true : false}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul>
                                    <li
                                        id="fullSubmit"
                                        className='iconRefresh button'
                                        onClick={
                                            loading ? null
                                                : (event) => submitAddEmail(event)
                                        }
                                    >
                                        <div className='center formSubmit'>
                                            {
                                                loading ? <AdminLoading theTheme={theme} />
                                                    : <span><FiSend /> Submit</span>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default WebsiteEmailAdd;