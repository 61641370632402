import React from 'react';
import GalleryCardScreen from './imagecard';
import GalleryScreen from './galleryScreen';

const GalleryMain = ({
    images
}) => {
    
    const [openModal, openModalHandler] = React.useState(false);

    const [slideNumber, slideNumberHandler] = React.useState(0);



    const handleImageClick = (data) => {
        document.body.style.overflow = 'hidden';
        slideNumberHandler(data.imageNumber);
        openModalHandler(true);
    }

    // const mediaClickHandler = async () => {
    //     document.body.style.overflow = 'overlay';
    //     // mediaRef.current.clearData();
    //     // pickedHandler([]);
    //     // const media = await dispatch(getAllMedia());
    //     // if (media.status === 200 && media.data.success) {
    //     //     allMediaHandler(media.data.data)
    //     // } else {
    //     //     dispatch(global.globalError('Network error'))
    //     // }
    //     openModalHandler(false);
    // }

    const renderImage = (image, index) => {
        const imgURL = image.secureurl ? image.secureurl : image.url
        const imageNumber = index;

        return (
            <GalleryCardScreen
                key={index}
                srcimg={imgURL}
                altimg={image.alternative}
                selectedImage={(event) => handleImageClick(event)}
                imageNumber={imageNumber}
            // theme={theme}
            />
        )
    }

    const handleCloseModal = () => {
        document.body.style.overflow = 'overlay';
        openModalHandler(false);
    }

    const prevSlide = () => {
        console.log('left')
        slideNumber === 0 ? slideNumberHandler(images.length - 1) : slideNumberHandler(slideNumber - 1)
    }

    const nextSlide = () => {
        console.log('right')
        slideNumber + 1 === images.length  ? slideNumberHandler(0) : slideNumberHandler(slideNumber + 1)
    }

    return (
        <div className='lumiGallery'>
            <div className='imgGallery'>
                <div className='imgCardInnerWrapper'>
                    {
                        images && images.map((image, index) => renderImage(image, index))
                    }
                </div>
            </div>
            {
                <GalleryScreen
                    openModal={openModal}
                    slideNumber={slideNumber}
                    handleCloseModal={handleCloseModal}
                    prevSlide={prevSlide}
                    nextSlide={nextSlide}
                    images={images}
                />
            }
        </div>
    )
}

export default GalleryMain;