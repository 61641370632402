import {
    CLIENT_AUTH
} from '../../types/auth';

import {
    CLIENT_SIGN_IN,
    CLIENT_SIGN_OUT,
    CLIENT_UPDATE_PIN,
    CLIENT_UPDATE_FIRST_TIME
} from '../../types/client';

export const clientIsAuth = (request) => ({
    type: CLIENT_AUTH,
    payload: request
})

export const clientSignIn = (client) => ({
    type: CLIENT_SIGN_IN,
    payload: client
})

export const clientSignOut = () => ({
    type: CLIENT_SIGN_OUT
})

export const clientUpdatePin = (request) => ({
    type: CLIENT_UPDATE_PIN,
    payload: request
})

export const clientUpdateFirstTime = (request) => ({
    type: CLIENT_UPDATE_FIRST_TIME,
    payload: request
})