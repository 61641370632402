import {
    GET_WEBSITE_SETTING,
    SUBMIT_WEBSITE_SETTING,
    GET_WEBSITE_ADDRESS,
    ADD_WEBSITE_ADDRESS,
    UPDATE_WEBSITE_ADDRESS,
    GET_WEBSITE_EMAIL,
    ADD_WEBSITE_EMAIL,
    UPDATE_WEBSITE_EMAIL,
    GET_WEBSITE_NUMBER,
    ADD_WEBSITE_NUMBER,
    UPDATE_WEBSITE_NUMBER,
    GET_WEBSITE_SOSMED,
    ADD_WEBSITE_SOSMED,
    UPDATE_WEBSITE_SOSMED
} from '../../types/website';


export const getWebsiteSetting = (request) => ({
    type: GET_WEBSITE_SETTING,
    payload: request
})

export const submitWebsiteSetting = (request) => ({
    type: SUBMIT_WEBSITE_SETTING,
    payload: request
})

// ADDRESS
export const getWebsiteAddress = (request) => ({
    type: GET_WEBSITE_ADDRESS,
    payload: request
})

export const addWebsiteAddress = (request) => ({
    type: ADD_WEBSITE_ADDRESS,
    payload: request
})

export const updateWebsiteAddress = (request) => ({
    type: UPDATE_WEBSITE_ADDRESS,
    payload: request
})

// EMAIL
export const getWebsiteEmail = (request) => ({
    type: GET_WEBSITE_EMAIL,
    payload: request
})

export const addWebsiteEmail = (request) => ({
    type: ADD_WEBSITE_EMAIL,
    payload: request
})

export const updateWebsiteEmail = (request) => ({
    type: UPDATE_WEBSITE_EMAIL,
    payload: request
})

// NUMBER
export const getWebsiteNumber = (request) => ({
    type: GET_WEBSITE_NUMBER,
    payload: request
})

export const addWebsiteNumber = (request) => ({
    type: ADD_WEBSITE_NUMBER,
    payload: request
})

export const updateWebsiteNumber = (request) => ({
    type: UPDATE_WEBSITE_NUMBER,
    payload: request
})

// SOSMED
export const getWebsiteSosmed = (request) => ({
    type: GET_WEBSITE_SOSMED,
    payload: request
})

export const addWebsiteSosmed = (request) => ({
    type: ADD_WEBSITE_SOSMED,
    payload: request
})

export const updateWebsiteSosmed = (request) => ({
    type: UPDATE_WEBSITE_SOSMED,
    payload: request
})