import React from 'react';

import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useNavigate,
    useParams,
    useOutletContext
} from 'react-router-dom';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, resetFields, updateSearch, update, updateTags, removeTags, updateSelect } from '../../utils/form/formActions';

import FormField from '../../utils/form/formField';
import MediaFloatingScreen from '../../utils/floatingmedia/floatingscreen';
import ClientBackdrop from '../../hoc/backdrop';

import AdminLoading from '../../utils/circle.loading';

import {
    addMediaTestimoni,
    deleteMediaTestimoni,
    getAllMediaTestimoni,
    getTestimoniFunction,
    updateTestimoniFunction
} from '../../../store/actions/testimoni';
import { getTestimoniCategory } from '../../../store/actions/testimoni';

import { FiArrowLeft, FiPlus, FiSend, FiUser } from 'react-icons/fi';
import { FaGlobe, FaLock } from 'react-icons/fa';
import AddCategoryFloating from './addcategory';

import GalleryMain from '../../utils/gallery';


const TestimoniEditPage = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        size
    } = globaldata;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let searchParams = useParams();
    let id = searchParams.id;

    const outsideClickRef = React.useRef();
    const mediaRef = React.useRef();

    const getTestimoni = useSelector(state => state.testimoni && state.testimoni.getTestimoni);

    const [dataselected, dataselectedHandler] = React.useState({});

    const [loading, loadingHandler] = React.useState(false);
    const [loadingupload, loadinguploadHandler] = React.useState(false);
    const [loadingdelete, loadingdeleteHandler] = React.useState(false);

    const [addcategory, addcategoryHandler] = React.useState(false);

    const [openmedia, openmediaHandler] = React.useState(false);

    const [picked, pickedHandler] = React.useState([]);
    const [pickedid, pickedidHandler] = React.useState();
    const [allCategory, allCategoryHandler] = React.useState([]);
    const [allMedia, allMediaHandler] = React.useState([]);

    const formName = 'Edit Testimoni';

    const [formdata, formdataHandler] = React.useState({
        name: {
            element: 'input',
            title: 'Testimoni Name',
            value: '',
            config: {
                name: 'SaveAs',
                type: 'text',
                placeholder: 'Save testimoni as'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        category: {
            element: 'selectobject',
            title: 'Category',
            value: {},
            search: '',
            config: {
                name: 'CategoryStatus',
                type: "text",
                placeholder: "Select Category"
            },
            options: [],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        tags: {
            element: 'tags',
            title: 'Tags',
            value: [],
            max: 4,
            maxLength: 3,
            config: {
                name: 'TagsName',
                type: 'text',
                placeholder: 'input tag here...'
            },
            validation: {
                required: false
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        images: {
            element: 'logo',
            title: 'Images',
            value: [],
            config: {
                type: 'pc'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        show: {
            element: 'selectboolean',
            title: 'Show at home',
            value: { value: false, name: 'NO' },
            config: {
                name: 'ShowAtHome',
                type: "text",
                placeholder: "Show at home"
            },
            options: [
                { value: true, name: 'YES' },
                { value: false, name: 'NO' },
            ],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                loadingHandler(true);
                const testimoniCategory = await dispatch(getTestimoniCategory());
                let testimoniData = []
                if (testimoniCategory.status === 200 && testimoniCategory.data.success) {
                    testimoniData = testimoniCategory.data.data;
                }
                await dispatch(getTestimoniFunction());
                const media = await dispatch(getAllMediaTestimoni());
                if (media.status === 200 && media.data.success) {
                    allMediaHandler(media.data.data)
                }
                formdataHandler((prevFormData) => {
                    var newFormdata = { ...prevFormData };
                    const temporaryFormdata = { ...newFormdata };
                    const newData = {
                        ...temporaryFormdata['category']
                    }
                    newData.options = testimoniData
                    temporaryFormdata['category'] = newData;
                    return temporaryFormdata;
                })
            }
        }
        fetchData().then(() => {
            loadingHandler(false)
        })
    }, [dispatch])

    React.useEffect(() => {
        let mounted = true;
        const implementData = async () => {
            if (mounted) {
                if (getTestimoni !== undefined) {
                    let selected = getTestimoni && getTestimoni.length > 0 && getTestimoni.filter(data => data._id === id)[0];
                    if (selected === undefined) navigate('/private/testimoni/dashboard')
                    dataselectedHandler(selected);
                    pickedHandler(selected['images'])
                    formdataHandler((prevFormData) => {
                        var newFormdata = { ...prevFormData };
                        const temporaryFormdata = { ...newFormdata };
                        for (let key in newFormdata) {
                            const newData = {
                                ...temporaryFormdata[key]
                            }
                            if (newData.element === 'selectobject') {
                                newData.value = selected[key][0];
                            } else if (newData.element === 'selectboolean') {
                                let theValue = { value: selected[key], name: selected[key] === true ? 'YES' : 'NO' };
                                newData.value = theValue;
                            } else {
                                newData.value = selected[key];
                            }
                            newData.valid = true;
                            newData.touched = true;
                            newData.validationMessage = '';
                            temporaryFormdata[key] = newData;
                        }
                        return temporaryFormdata;
                    })
                }
            }
        }
        implementData();
        return () => {
            mounted = false;
        }
    }, [getTestimoni, navigate, id])

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const updateSelectForm = (element, getdata, dataHandler) => {
        const newSelect = updateSelect(element, getdata);
        dataHandler(newSelect);
        outsideClickRef.current.closeFromOutside();
    }

    const updateTagsForm = (element) => {
        const newFormdata = updateTags(element, formdata, formName, dispatch);
        formdataHandler(newFormdata)
    }

    const removeTagsForm = (element) => {
        const newFormdata = removeTags(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const selectForm = (element, dataHandler) => {
        dataHandler((prevFormData) => {
            var newFormdata = { ...prevFormData };
            const temporaryFormdata = { ...newFormdata };
            const newElement = {
                ...temporaryFormdata[element.whichdata]
            }

            newElement.value = element.item;
            newElement.valid = true;
            newElement.touched = true;

            temporaryFormdata[element.whichdata] = newElement;
            return temporaryFormdata;
        })
        outsideClickRef.current.closeSelectFromOutside();
    }

    const updateFormSearch = (element, data, dataHandler, alldataToSearch, thekey) => {
        const newFormdata = updateSearch(element, data, formName);
        var newFormData = newFormdata;
        const temporaryFormdata = {
            ...newFormData
        }
        const newElement = {
            ...temporaryFormdata[element.id]
        }
        const results = alldataToSearch.filter(result =>
            result[thekey].toLowerCase().includes(newElement.search.toLowerCase())
        );
        newElement.options = results;
        temporaryFormdata[element.id] = newElement
        dataHandler(temporaryFormdata);
    }

    // const updateSelectForm = (element, getdata, dataHandler) => {
    //     var newFormdata = getdata;
    //     const temporaryFormdata = {
    //         ...newFormdata
    //     }
    //     const newElement = {
    //         ...temporaryFormdata[element.whichdata]
    //     }
    //     newElement.value = element.item
    //     temporaryFormdata[element.whichdata] = newElement;
    //     dataHandler(temporaryFormdata);
    //     outsideClickRef.current.closeSelectFromOutside();
    // }

    const submitUpdateData = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let formIsValid = isFormValid(formdata, formName);

        if (formIsValid) {
            dataToSubmit.show = dataToSubmit['show'].value;

            let newDataToSubmit = {
                id: dataselected._id,
                ...dataToSubmit
            }
            const result = await dispatch(updateTestimoniFunction(newDataToSubmit))
            if (result === undefined || result === 'undefined') {
                loadingHandler(false);
            }
            if (!result.data.success) {
                dispatch(global.globalError('please try again'))
                loadingHandler(false);
            }
            const newFormdata = resetFields(formdata, formName);
            formdataHandler(newFormdata)
            dispatch(global.globalSuccess('testimoni updated'))
            setTimeout(() => {
                navigate('/private/testimoni/dashboard')
                loadingHandler(false);
            }, 2000);
        } else {
            dispatch(global.globalError('Please check your data'))
            loadingHandler(false);
        }
    }

    const mediaClickHandler = async () => {
        document.body.style.overflow = 'overlay';
        mediaRef.current.clearData();
        pickedHandler([]);
        const media = await dispatch(getAllMediaTestimoni());
        if (media.status === 200 && media.data.success) {
            allMediaHandler(media.data.data)
        } else {
            dispatch(global.globalError('Network error'))
        }
        resetClickHandler();
    }

    const resetClickHandler = async () => {
        document.body.style.overflow = 'overlay';
        if (formdata['images'].value && formdata['images'].value.length > 0) {
            pickedHandler(formdata['images'].value)
        }
        addcategoryHandler(false);
        openmediaHandler(false);
    }

    // const openMedia = ({ event, id }) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     allMediaHandler((prevData) => {
    //         const newdata = prevData.filter((single) => Math.round(single.width / single.height) === 4);
    //         return newdata
    //     })
    //     pickedidHandler(id)
    //     openmediaHandler(true);
    //     // statusHandler(true);
    //     // let selectedImage = [];
    //     // if(dataselected.profileimage){
    //     //     selectedImage.push(dataselected.profileimage)
    //     // }
    //     // pickedHandler(selectedImage);
    //     // dispatch(mainMedia(true, 'admin', myid))
    // }

    // const openMediaSquare = ({ event, id }) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     allMediaHandler((prevData) => {
    //         const newdata = prevData.filter((single) => Math.round(single.width / single.height) === 1);
    //         return newdata
    //     })
    //     pickedidHandler(id)
    //     openmediaHandler(true);
    // }

    const onMediaSubmit = async ({ event, id }) => {
        event.preventDefault();
        event.stopPropagation();

        if (picked && picked.length > 0) {

            formdataHandler((prevData) => {
                var newFormdata = { ...prevData };
                const temporaryFormdata = { ...newFormdata };
                const newData = {
                    ...temporaryFormdata[id]
                }
                newData.value = picked;
                newData.valid = true;
                newData.touched = true;
                temporaryFormdata[id] = newData;
                return temporaryFormdata
            })
            openmediaHandler(false);
        } else {
            dispatch(global.globalError('Please select image'))
        }
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/testimoni/dashboard')
    }

    const changeStatusCategory = () => {
        addcategoryHandler(true);
    }

    const closeCategoryWindow = async () => {
        const newDataCategory = await dispatch(getTestimoniCategory());
        formdataHandler((prevFormData) => {
            var newFormdata = { ...prevFormData };
            const temporaryFormdata = { ...newFormdata };
            const newData = {
                ...temporaryFormdata['category']
            }
            newData.options = newDataCategory.data.data;
            temporaryFormdata['category'] = newData;
            return temporaryFormdata;
        })
        addcategoryHandler(false);
    }

    const submitAddImage = async ({ event, id }) => {
        event.preventDefault();
        event.stopPropagation();

        // allMediaHandler((prevData) => {
        //     const newdata = prevData.filter((single) => Math.round(single.width / single.height) === 1);
        //     return newdata
        // })
        pickedidHandler(id)
        openmediaHandler(true);
    }

    // const onInit = () => {
    //     console.log('lightGallery has been initialized');
    // };

    // const images = [
    //     { url: "https://github.com/ipenywis/img-lazy-loading/blob/master/src/images/abhinav-bhardwaj-fOcPg7lM1Fs-unsplash.jpg?raw=true", alt: "1" },
    //     { url: "https://github.com/ipenywis/img-lazy-loading/blob/master/src/images/abhinav-bhardwaj-fOcPg7lM1Fs-unsplash.jpg?raw=true", alt: "2" },
    //     { url: "https://github.com/ipenywis/img-lazy-loading/blob/master/src/images/abhinav-bhardwaj-fOcPg7lM1Fs-unsplash.jpg?raw=true", alt: "3" },
    //     { url: "/images/4.jpg", alt: "4", },
    //     { url: "/images/5.jpg", alt: "5", },
    //     { url: "/images/6.jpg", alt: "6", },
    //     { url: "/images/7.jpg", alt: "7", },
    //     { url: "/images/8.jpg", alt: "8", },
    //     { url: "/images/9.jpg", alt: "9", },
    //     { url: "/images/10.jpg", alt: "10", },
    //     { url: "/images/11.jpg", alt: "11", },
    //     { url: "/images/12.jpg", alt: "12", },
    //     { url: "/images/13.jpg", alt: "13", },
    //     { url: "/images/14.jpg", alt: "14", },
    //     { url: "/images/15.jpg", alt: "15", },
    //     { url: "/images/16.jpg", alt: "16", },
    //     { url: "/images/17.jpg", alt: "17", },
    //     { url: "/images/18.jpg", alt: "18", },
    //     { url: "/images/19.jpg", alt: "19", },
    // ]

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                <div className='cardWrapper'>
                    <div className='cardHeader' style={{ paddingBottom: 0 }}>
                        <h5>{formName}</h5>
                        <div className='cardOptions'>
                            <ul className='textbuttonWrapper'>
                                <li id="loadingSubmit" className='iconRefresh textbutton' onClick={(event) => changeStatusCategory(event)}>
                                    <div className='center'>
                                        <span>Add Category</span>
                                    </div>
                                </li>
                                <li id="loadingSubmit" className='iconRefresh button' onClick={(event) => goBack(event)}>
                                    <div className='center'>
                                        <span><FiArrowLeft /></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='cardBody'>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul className='mb30 mb-xs-0'>
                                    <li className='inputSearch'>
                                        <FormField
                                            id={'name'}
                                            formdata={formdata.name}
                                            change={(element) => updateForm(element)}
                                            theClass="inputclass"
                                            icons={<FiUser />}
                                            showName={true}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                    <li className='inputSearch'>
                                        <FormField
                                            ref={outsideClickRef}
                                            id={'category'}
                                            formdata={formdata.category}
                                            searchChange={(element) => updateFormSearch(element, formdata, formdataHandler, allCategory, 'name')}
                                            change={(element) => updateForm(element)}
                                            selectForm={(element) => selectForm(element, formdataHandler)}
                                            theClass="inputclass"
                                            icons={<FaLock />}
                                            showName={true}
                                            thekey={'name'}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                    <li className='inputSearch'>
                                        <FormField
                                            ref={outsideClickRef}
                                            id={'show'}
                                            formdata={formdata.show}
                                            selectForm={(element) => updateSelectForm(element, formdata, formdataHandler)}
                                            theClass="inputclass"
                                            icons={<FaGlobe />}
                                            formdataHandler={formdataHandler}
                                            showName={true}
                                            thekey={'name'}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <FormField
                                    id={'tags'}
                                    formdata={formdata.tags}
                                    change={(element) => updateTagsForm(element)}
                                    theClass="tagInput"
                                    formdataHandler={formdataHandler}
                                    theFunction={(element => removeTagsForm(element))}
                                    showName={true}
                                    disabled={loading ? true : false}
                                />
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul>
                                    <li
                                        id="fullSubmit"
                                        className='iconRefresh button'
                                        onClick={
                                            loading ? null
                                                : (element) => submitAddImage({ event: element, id: 'images' })
                                        }
                                    >
                                        <div className='center formSubmit'>
                                            {
                                                loading ? <AdminLoading theTheme={theme} />
                                                    : <span><FiPlus /> Edit Image</span>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 pb20 pl0 pr0'>
                            <div className='cardMedia'>
                                <div className='mediaList'>
                                    {
                                        formdata.images && formdata.images.value && formdata.images.value.length > 0 ?
                                            <GalleryMain
                                                images={formdata.images && formdata.images.value}
                                                theme={theme}
                                            />
                                            :
                                            <div className={`lumiGalleryWrapper ${theme}`}>
                                                No images
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul>
                                    <li
                                        id="fullSubmit"
                                        className='iconRefresh button'
                                        onClick={
                                            loading ? null
                                                : (event) => submitUpdateData(event)
                                        }
                                    >
                                        <div className='center formSubmit'>
                                            {
                                                loading ? <AdminLoading theTheme={theme} />
                                                    : <span><FiSend /> Submit</span>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <MediaFloatingScreen
                    ref={mediaRef}
                    media={allMedia}
                    mediaHandler={allMediaHandler}
                    size={size}
                    mainMediaOpen={openmedia}
                    theme={theme}
                    uploadmultiple={true}
                    enablemultiple={true}
                    onMediaSubmit={onMediaSubmit}
                    onCancelSubmit={mediaClickHandler}
                    picked={picked}
                    pickedHandler={pickedHandler}
                    pickedid={pickedid}
                    loadingupload={loadingupload}
                    loadinguploadHandler={loadinguploadHandler}
                    loadingdelete={loadingdelete}
                    loadingdeleteHandler={loadingdeleteHandler}
                    addFunction={addMediaTestimoni}
                    deleteFunction={deleteMediaTestimoni}
                    getFunction={getAllMediaTestimoni}
                    allowedCropSize={[1.33, 0.75]}
                />
            }
            {
                !loading && openmedia &&
                <ClientBackdrop click={mediaClickHandler} />
            }
            {
                <AddCategoryFloating
                    openScreen={addcategory}
                    closeCategoryWindow={closeCategoryWindow}
                    theme={theme}
                />
            }
            {
                !loading && addcategory &&
                <ClientBackdrop click={resetClickHandler} />
            }
        </div >
    )
}

export default TestimoniEditPage;