import React from 'react';
import {
    useDispatch
} from 'react-redux';
import {
    useNavigate
} from "react-router-dom";

import FormButton from '../utils/form/formButton';

import { clientIsAuth, updateFirstTime } from '../../store/actions/auth';

import * as global from '../../store/actions/global/data.global';
import CircleLoading from '../utils/circle.loading';

const WelcomeScreen = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, loadingHandler] = React.useState(true);
    const [data, dataHandler] = React.useState({});

    const { theme } = props;

    const logoURL = theme === 'dark' ? "/logo192.png" : "/lumisoft.png";

    const [localLoading, localLoadingHandler] = React.useState(false);

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(clientIsAuth())
            }
        }
        fetchData().then(async (response) => {
            if (response && response.data.success) {
                let theData = response.data.data;
                dataHandler(theData);
                if (!theData.isPin) {
                    navigate('/auth/pin')
                }
                if (theData.isPin && !theData.isFirstTime) {
                    navigate('/private')
                }
                setTimeout(() => {
                    loadingHandler(false)
                }, 1000)
            } else {
                navigate('/auth/login')
                setTimeout(() => {
                    loadingHandler(false)
                }, 1000)
            }
            
        })
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate]);

    const submitForm = async (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();
            localLoadingHandler(true);

            let dataToSubmit = {
                email: data.email
            }
         
            let userauth = await dispatch(updateFirstTime(dataToSubmit))
  
            if (userauth === undefined) {
                setTimeout(() => {
                    localLoadingHandler(false);
                }, 5000)
            } else {
                let usertocheck = userauth.data;
                if (!usertocheck.success) {
                    setTimeout(() => {
                        dispatch(global.globalError('Please check your data'))
                        localLoadingHandler(false);
                    }, 5000);
                } else if (usertocheck.data.isPin && !usertocheck.data.isFirstTime) {
                    dispatch(global.globalSuccess('Success, redirect to dashboard'))
                    setTimeout(() => {
                        navigate('/private')
                    }, 5000);
                } else if (!usertocheck.data.isPin) {
                    dispatch(global.globalSuccess('Success, redirect to pin page'))
                    setTimeout(() => {
                        navigate('/auth/pin')
                    }, 5000);
                } else {
                    setTimeout(() => {
                        dispatch(global.globalError('Please check your data'))
                        localLoadingHandler(false);
                    }, 5000);
                }
            }
        } catch (error) {
            setTimeout(() => {
                dispatch(global.globalError('something is wrong, please retry'))
                localLoadingHandler(false);
            }, 5000);
        }
    }

    if (loading) {
        return (
            <div style={{
                paddingTop: 78,
                paddingBottom: 80
            }}>
                <CircleLoading />
            </div>
        )
    }

    if (localLoading) {
        return (
            <div style={{
                paddingTop: 78,
                paddingBottom: 80
            }}>
                <CircleLoading />
            </div>
        )
    }


    return (
        <div className='loginInnerForm'>
            <div className='details'>
                <img
                    src={window.location.origin + logoURL}
                    alt=""
                    width="100px"
                />
                <h3>Hello !</h3>

            </div>
            <small>
                Welcome to our client dashboard
            </small>
            <small className='mt20'>
                Please click the button below to get started!
            </small>
            <form onSubmit={(event) => submitForm(event)} className="mt30">
                <div className='clientpinWrapper'>
                    <FormButton
                        theName={'submitNF'}
                        theClass={'submitButton'}
                        theType={'submit'}
                        theTitle={'Go to Dashboard'}
                    />
                </div>
            </form>
        </div>
    );

}

export default WelcomeScreen;