import React from 'react';

import {
    FiSun,
    FiMoon
} from 'react-icons/fi';

export const ButtonFloating = ({ theme, Icon, link }) => {
    return (
        <a
            rel="noopener noreferrer"
            href={`tel:${link}`}
            target="_blank"
            className={theme}
            style={{ paddingTop: '0', cursor: 'pointer' }}
        >
            <div
                className="floatingbutton"
            >
                <div className="buttonicon">
                    {Icon}
                </div>
            </div>
        </a>
    )

}

export const ThemeFloating = ({ theme, themeHandler }) => {
    let Icon = theme === 'dark' ? <FiSun size={25} /> : <FiMoon size={25} />
    return (
        <div
            className={theme}
            style={{ paddingTop: '0', cursor: 'pointer' }}
            onClick={() => themeHandler(theme === 'light' ? 'dark' : 'light')}
        >
            <div
                className="floatingbutton theme"
            >
                <div className="buttonicon">
                    {Icon}
                </div>
            </div>
        </div>
    )
}