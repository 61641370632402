import React from 'react';

const GalleryCardScreen = ({
    srcimg,
    altimg,
    selectedImage,
    imageNumber
    // theme
}) => {

    return (
        <div
            className={`singleGallery`}
            onClick={(event) => selectedImage({ event, imageNumber })}
        >
            <img
                src={srcimg}
                alt={altimg}
            />
        </div>
    )
}

export default GalleryCardScreen;