import React from 'react';
import moment from 'moment';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useNavigate
} from 'react-router-dom';

import { getallClient } from '../../../store/actions/client';
import TableTemplate from '../../utils/table';


const MainAdminPage = (props) => {

    const { theme } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAllAdmin = useSelector(state => state.client && state.client.clientGetAll);
    const theUser = useSelector(state => state.auth && state.auth.data);

    const [loading, loadingHandler] = React.useState(false);

    const tableId = 'adminlist';
    const tableName = 'Admin status';
    const pageSize = 5;
    const [alladmin, alladminHandler] = React.useState([]);
    const [currentPage, currentPageHandler] = React.useState(1);
    const [dataCount, dataCountHandler] = React.useState("");
    const [mydata, mydataHandler] = React.useState([]);

    const [filterData, filterDataHandler] = React.useState({
        filterText: {
            element: 'input',
            value: '',
            config: {
                name: 'Filter',
                type: 'text',
                placeholder: 'Search here'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });

    const dataToShow = React.useMemo(() => [
        {
            name: 'Email',
            code: 'email'
        },
        // {
        //     name: 'Email verified',
        //     code: 'isEmailVerified'
        // },
        // {
        //     name: 'Phone verified',
        //     code: 'isPhoneVerified'
        // },
        {
            name: 'Active',
            code: 'isActive'
        },
        {
            name: 'Created',
            code: 'createdAt'
        },
        {
            name: 'Updated',
            code: 'updatedAt'
        }
    ], [])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (theUser !== undefined && theUser.rolesName !== 'superadmin') {
                navigate('/')
            }
            if (getAllAdmin && getAllAdmin.length > 0) {
                alladminHandler(getAllAdmin)
            }
        }

        return () => {
            mounted = false;
        };
    }, [getAllAdmin, navigate, theUser])

    const allURLs = async () => {
        loadingHandler(true)
        const newadmin = await dispatch(getallClient());
        alladminHandler(newadmin.data.data)
        loadingHandler(false)
    }

    const gotoDetails = React.useCallback((data) => {
        navigate(`/private/admin/${data._id}`)
    }, [navigate])

    const gotoDetailsRef = React.useRef(gotoDetails);
    React.useEffect(() => {
        gotoDetailsRef.current = gotoDetails;
    }, [gotoDetails]);
    const ThisTable = React.useCallback((index, item) => {
        return (
            <tr key={index} onClick={() => gotoDetailsRef.current(item)}>
                {
                    dataToShow.map((dat, ind) => {
                        return (
                            <td key={ind}>
                                {
                                    dat.code === 'createdAt' || dat.code === 'updatedAt' ?
                                        `${moment(item[dat.code]).format('DD/MM/YYYY')}`
                                        : `${item[dat.code]}`
                                }
                            </td>
                        )
                    })
                }
            </tr>
        );
    }, [dataToShow])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            var results = [];
            var resultsdata = [];
            var offset = (currentPage - 1) * pageSize;
            var filteradmin = alladmin && alladmin.length > 0 && alladmin.filter(single => single.rolesName !== 'superadmin');
            results = filteradmin && filteradmin.length > 0 && filteradmin.filter(data =>
                (data.email.toLowerCase().indexOf(filterData.filterText.value.toLowerCase()) !== -1))
            resultsdata = results && results.length > 0 && results.map((item, index) => ThisTable(index, item))
            var semuadata = resultsdata ? [...resultsdata] : [];
            var mydatas = semuadata.slice(offset, offset + pageSize);
            dataCountHandler(results && results.length);
            mydataHandler(mydatas);
        }
        return () => {
            mounted = false;
        };
    }, [alladmin, currentPage, filterData.filterText.value, mydataHandler, pageSize, ThisTable])

    return (
        <>
            {
                <TableTemplate
                    tableId={tableId}
                    tableName={tableName}
                    filterData={filterData}
                    filterDataHandler={filterDataHandler}
                    mydata={mydata}
                    dataToShow={dataToShow}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    dataCountHandler={dataCountHandler}
                    mydataHandler={mydataHandler}
                    loading={loading}
                    allURLs={allURLs}
                    theTheme={theme}
                />
            }
        </>
    )
}

export default MainAdminPage;