import React from 'react';

import {
    useDispatch
} from 'react-redux';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, resetFields, update } from '../../utils/form/formActions';
import FormField from '../../utils/form/formField';
import AdminLoading from '../../utils/circle.loading';

import { addTestimoniCategory } from '../../../store/actions/testimoni';

import { FiSend, FiUser } from 'react-icons/fi';


const AddCategoryFloating = (({
    openScreen,
    closeCategoryWindow,
    theme
}) => {

    const dispatch = useDispatch();

    const [loading, loadingHandler] = React.useState(false);

    const formName = 'Category';

    const [formdata, formdataHandler] = React.useState({
        name: {
            element: 'input',
            title: 'Category Name',
            value: '',
            config: {
                name: 'CategorySaveAs',
                type: 'text',
                placeholder: 'Input category name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const submitAdd = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let formIsValid = isFormValid(formdata, formName);

        if (formIsValid) {
            const result = await dispatch(addTestimoniCategory(dataToSubmit))
            if (result === undefined || result === 'undefined') {
                loadingHandler(false);
            }
            if (!result.data.success) {
                dispatch(global.globalError('please try again'))
                loadingHandler(false);
            }
            const newFormdata = resetFields(formdata, formName);
            formdataHandler(newFormdata)
            dispatch(global.globalSuccess('category added'))
            setTimeout(() => {
                closeCategoryWindow();
                loadingHandler(false);
            }, 2000);
        } else {
            dispatch(global.globalError('Please check your data'))
            loadingHandler(false);
        }
    }

    const className = `floatingScreen ${theme} ${openScreen ? 'open' : ''}`;

    return (
        <div
            className={className}
        >
            <div className='mainBodyWrapper mediacss'>
                <div className='cardWrapper header'>
                    <div className='dashboardHeader'>
                        <div className='row'>
                            <div className='lumi-md-12 lumi-xs-12'>
                                <div className="dashboardProfile">
                                    <div className="greetWrapper">
                                        <p className="greetings mb0">
                                            Add Category
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cardWrapper body'>
                    <div className='floatingBody p30'>
                        <div className="row width100p">
                            <div className='lumi-md-12 lumi-xs-12 pb-xs-30'>
                                <div className='cardOptions form pt30'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'name'}
                                                formdata={formdata.name}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiUser />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='lumi-md-12 lumi-xs-12'>
                                <div className='cardOptions form'>
                                    <ul>
                                        <li
                                            id="fullSubmit"
                                            className='iconRefresh button'
                                            onClick={
                                                loading ? null
                                                    : (event) => submitAdd(event)
                                            }
                                        >
                                            <div className='center formSubmit'>
                                                {
                                                    loading ? <AdminLoading theTheme={theme} />
                                                        : <span><FiSend /> Submit</span>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
})


export default AddCategoryFloating;