import {
    CLIENT_GETALL,
    ROLES_GETALL,
    ADDADMIN_POST,
    UPDATEADMIN_PATCH
} from '../../types/client';

export const clientGetAll = (request) => ({
    type: CLIENT_GETALL,
    payload: request
})

export const rolesGetAll = (request) => ({
    type: ROLES_GETALL,
    payload: request
})

export const addNewAdmin = (request) => ({
    type: ADDADMIN_POST,
    payload: request
})

export const updateAdmin = (request) => ({
    type: UPDATEADMIN_PATCH,
    payload: request
})