import React from 'react';

import FormField from '../../utils/form/formField';
import { FiRotateCw, FiSearch } from 'react-icons/fi';
import AdminLoading from '../../utils/circle.loading';

const TableTemplate = ({
    tableId,
    tableName,
    filterData,
    filterDataHandler,
    mydata,
    dataToShow,
    loading,
    allURLs,
    theTheme
}) => {

    let functionType = typeof allURLs;

    const updateForm = (element, formdata) => {
        const newFormdata = {
            ...formdata
        }
        const newElement = {
            ...newFormdata[element.id]
        }
        newElement.value = element.event.target.value;
        newFormdata[element.id] = newElement;
        filterDataHandler(newFormdata);
    }

    // const dataCountCallBack = React.useCallback((results) => {
    //     dataCountHandler(results.length);
    // }, [dataCountHandler])

    const TableHead = (data) => {

        return data.map((dat, index) => {
            return (
                <th scope='col' key={index}>{dat.name}</th>
            )
        })

    }

    return (
        <div className='cardWrapper'>
            <div className='cardHeader'>
                <h5>{tableName}</h5>
                <div className='cardOptions'>
                    <ul>
                        <li className='inputSearch'>
                            <FormField
                                id={'filterText'}
                                formdata={filterData.filterText}
                                change={(element) => updateForm(element, filterData)}
                                theClass="inputclass"
                                icons={<FiSearch />}
                                showName={false}
                            />
                        </li>
                        {
                            functionType !== 'undefined' ?
                                <li className='iconRefresh button' onClick={() => allURLs()}>
                                    <div className='center'>
                                        <span><FiRotateCw /></span>
                                    </div>
                                </li>
                                : null
                        }
                    </ul>
                </div>
            </div>
            <div className='cardBody'>
                <div className='tableWrapper'>
                    {
                        loading ? <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', minHeight: '100px' }}><AdminLoading theTheme={theTheme} /></div> :
                            <table id={tableId} className='table tablebordernone'>
                                <thead>
                                    <tr>
                                        {
                                            TableHead(dataToShow)
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        mydata && mydata.length > 0 ? mydata :
                                            <tr>
                                                <td
                                                    colSpan={dataToShow && dataToShow.length}
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <strong>No data</strong>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                    }
                </div>
            </div>
        </div>
    )
}

export default TableTemplate;