import React from 'react';

import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useNavigate,
    useOutletContext
} from 'react-router-dom';

import { getTestimoniFunction } from '../../../store/actions/testimoni';
import FolderTemplate from '../../utils/table/folder';
import { mobile, tablet, bigTablet, isPC } from '../../utils/globalsize';

const TestimoniDashboard = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        size
    } = globaldata;

    const isMobile = mobile(size);
    const isTablet = tablet(size);
    const isBiggerTablet = bigTablet(size);
    const isBigPC = isPC(size);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theUser = useSelector(state => state.auth && state.auth.data);

    const [loading, loadingHandler] = React.useState(false);
    const [alldata, alldataHandler] = React.useState([]);

    let formname = 'Testimoni'

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                // if (theUser !== undefined && theUser.rolesName !== 'superadmin') {
                //     navigate('/')
                // }
                const data = await dispatch(getTestimoniFunction());
                if (data.status === 200 && data.data.success) {
                    alldataHandler(data.data.data)
                }
            }
        }
        fetchData()
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate, theUser])

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                {
                    <FolderTemplate
                        tableId={formname}
                        tableName={`${formname} lists`}
                        alldata={alldata}
                        pageSize={5}
                        loading={loading}
                        theTheme={theme}
                        navigateto={'testimoni'}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        isBiggerTablet={isBiggerTablet}
                        isBigPC={isBigPC}
                    />
                }
            </div>
        </div>
    )
}

export default TestimoniDashboard;