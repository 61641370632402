import {
    GET_TESTIMONI,
    ADD_TESTIMONI,
    UPDATE_TESTIMONI,
    GET_TESTIMONI_CATEGORY,
    ADD_TESTIMONI_CATEGORY,
    UPDATE_TESTIMONI_CATEGORY,
    GET_TESTIMONI_TAGS,
    ADD_TESTIMONI_TAGS,
    UPDATE_TESTIMONI_TAGS,
    GET_MEDIA_TESTIMONI,
    ADD_MEDIA_TESTIMONI,
    DELETE_MEDIA_TESTIMONI
} from '../types/testimoni';

export default function testimoniReducer(state = {}, action) {
    switch (action.type) {
        case GET_MEDIA_TESTIMONI:
            return {
                ...state,
                getMediaTestimoni: action.payload
            }
        case ADD_MEDIA_TESTIMONI:
            return {
                ...state,
                addMediaTestimoni: action.payload
            }
        case DELETE_MEDIA_TESTIMONI:
            return {
                ...state,
                deleteMediaTestimoni: action.payload
            }
        case GET_TESTIMONI:
            return {
                ...state,
                getTestimoni: action.payload
            }
        case ADD_TESTIMONI:
            return {
                ...state,
                addTestimoni: action.payload
            }
        case UPDATE_TESTIMONI:
            return {
                ...state,
                updateTestimoni: action.payload
            }
        case GET_TESTIMONI_CATEGORY:
            return {
                ...state,
                getTestimoniCategory: action.payload
            }
        case ADD_TESTIMONI_CATEGORY:
            return {
                ...state,
                addTestimoniCategory: action.payload
            }
        case UPDATE_TESTIMONI_CATEGORY:
            return {
                ...state,
                updateTestimoniCategory: action.payload
            }
        case GET_TESTIMONI_TAGS:
            return {
                ...state,
                getTestimoniTags: action.payload
            }
        case ADD_TESTIMONI_TAGS:
            return {
                ...state,
                addTestimoniTags: action.payload
            }
        case UPDATE_TESTIMONI_TAGS:
            return {
                ...state,
                updateTestimoniTags: action.payload
            }
        default:
            return state;
    }
}