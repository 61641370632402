import * as data from './data.website';
import * as global from '../global/data.global';
import axios from 'axios';

import { checkError } from '../global/function';

export const getWebsiteSetting = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/website/getwebsitesetting');
            dispatch(data.getWebsiteSettings(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getWebsiteAddress = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/website/getwebsiteaddress');
            dispatch(data.getWebsiteAddress(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}