import React from 'react';

import PinItem from './pinItem';

import { FaEye, FaEyeSlash } from 'react-icons/fa'

const FormPin = ({
    id,
    length,
    formdata,
    formdataHandler,
    focus,
    onComplete,
    change,
    disabled,
    theClass,
    ...other
}) => {
    let value = formdata.value;
    let type = formdata.validation.number ? 'number' : 'text';
    let secret = formdata.validation.secret ? true : false;
    let required = formdata.validation.required ? true : false;
    let maxLength = length > 6 ? 6 : length;

    const [values, setValues] = React.useState(
        Array(maxLength)
            .fill('')
            .map((_, i) => value.toString()[i] || '')
    );

    const [elements] = React.useState([]);

    React.useEffect(() => {
        if (focus) elements[0].focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isRevealPassword, isRevealPasswordHandler] = React.useState(false);

    React.useEffect(() => {
        formdataHandler((prevFormData) => {
            const newFormData = { ...prevFormData };

            const newElement = { ...newFormData[id] };
            newElement.validation.secret = isRevealPassword ? false : true;
            newFormData[id] = newElement;
            
            return newFormData;
        });
    }, [isRevealPassword, formdataHandler, id])

    const onItemChange = async (value, index) => {
        let currentIndex = index;

        const newValues = values.slice();
        newValues[index] = value;
        setValues(newValues);

        // Next focus
        if (value.length === 1 && index < maxLength - 1) {
            currentIndex++;
            elements[currentIndex].focus();
        }

        // Notify parent
        const pin = newValues.join('');
        await change({ value: pin, currentIndex, id });
        if (pin.length === maxLength) {
            onComplete(pin);
        }
    };

    const onBackspace = (index) => {
        if (index > 0) {
            elements[index - 1].focus();
        }
    };

    const togglePassword = () => {
        isRevealPasswordHandler(!isRevealPassword);
    }

    return (
        <div className='formGroup'>
            <div className='formWrapper'>
                <div style={{
                    flexDirection: 'column'
                }}>
                    <span
                        className="customiconpin"
                        onClick={() => togglePassword()}
                    >
                        {
                            isRevealPassword ? <FaEye /> : <FaEyeSlash />
                        }
                    </span>
                    <div className='pincode-input-container'>
                        {values.map((e, i) => (
                            <PinItem
                                {...other}
                                required={required}
                                key={i}
                                value={e}
                                ref={(n) => (elements[i] = n)}
                                disabled={disabled}
                                secret={secret}
                                type={type}
                                theClass={theClass}
                                onBackspace={() => onBackspace(i)}
                                onChange={(value) => onItemChange(value, i)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormPin;


