import React from 'react';

import useSwipe from '../useSwipe';

import { FaRegCircleXmark, FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";

const GalleryScreen = (({
    openModal,
    slideNumber,
    handleCloseModal,
    prevSlide,
    nextSlide,
    images
}) => {

    const className = `galleryScreen ${openModal ? 'open' : ''}`;
    const imgURL = images && images[slideNumber].secureurl ? images && images[slideNumber].secureurl : images[slideNumber].url;
    const imgAlternative = images && images[slideNumber].alternative ? images && images[slideNumber].alternative : images[slideNumber].alt;

    const swipeHandlers = useSwipe({ onSwipedLeft: () => prevSlide(), onSwipedRight: () => nextSlide() })

    return (
        <div
            className={className}
        >
            <div className="gallerybackdrop" onClick={handleCloseModal} />
            <span
                className='btnClose'
                onClick={handleCloseModal}
            >
                <FaRegCircleXmark />
            </span>
            <span
                className='btnPrev'
                onClick={prevSlide}
            >
                <FaCircleChevronLeft />
            </span>
            <span
                className='btnNext'
                onClick={nextSlide}
            >
                <FaCircleChevronRight />
            </span>
            <span
                className='galleryInfo'
            >
                {slideNumber + 1}/{images.length}
            </span>
            <div
                className='fullscreenWrapper'
                {...swipeHandlers}
            >
                <div
                    className="fullscreenImage"
                >
                    <img src={imgURL} alt={imgAlternative} />
                </div>
            </div>
        </div>
    )
})

export default GalleryScreen;