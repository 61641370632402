import {
    GET_MEDIA_SLIDER,
    GET_SLIDER
} from '../../../types/frontend/types';

export const getMediaSlider = (request) => ({
    type: GET_MEDIA_SLIDER,
    payload: request
})

export const getSlider = (request) => ({
    type: GET_SLIDER,
    payload: request
})
