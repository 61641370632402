import React from 'react';

import {
    useDispatch
} from 'react-redux';
import {
    useNavigate,
    useOutletContext
} from 'react-router-dom';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, resetFields, updateSearch, update, updateTags, removeTags, updateSelect, validate } from '../../utils/form/formActions';

import FormField from '../../utils/form/formField';
import MediaFloatingScreen from '../../utils/floatingmedia/floatingscreen';
import ClientBackdrop from '../../hoc/backdrop';

import AdminLoading from '../../utils/circle.loading';

import {
    addMediaSlider,
    deleteMediaSlider,
    getAllMediaSlider,
    addSliderFunction
} from '../../../store/actions/slider';

import { FiPlus, FiSend, FiUser } from 'react-icons/fi';
import { FaGlobe, FaLock } from 'react-icons/fa';

import GalleryMain from '../../utils/gallery';


const SliderAddPage = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        size
    } = globaldata;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const outsideClickRef = React.useRef();
    const mediaRef = React.useRef();

    const [loading, loadingHandler] = React.useState(false);
    const [loadingupload, loadinguploadHandler] = React.useState(false);
    const [loadingdelete, loadingdeleteHandler] = React.useState(false);

    const [openmedia, openmediaHandler] = React.useState(false);

    const [picked, pickedHandler] = React.useState([]);
    const [pickedid, pickedidHandler] = React.useState();
    const [allMedia, allMediaHandler] = React.useState([]);

    const formName = 'Add Slider';
    const formEventName = 'Event Details'

    const [formdata, formdataHandler] = React.useState({
        name: {
            element: 'input',
            title: 'Slider Name',
            value: '',
            config: {
                name: 'SaveAs',
                type: 'text',
                placeholder: 'Save slider as'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        title: {
            element: 'input',
            title: 'Slider Title',
            value: '',
            config: {
                name: 'SaveAs',
                type: 'text',
                placeholder: 'Slider Title'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        subtitle: {
            element: 'input',
            title: 'Slider Subtitle',
            value: '',
            config: {
                name: 'SaveAs',
                type: 'text',
                placeholder: 'Slider Subtitle'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        goto: {
            element: 'input',
            title: 'Redirect to (optional)',
            value: '',
            config: {
                name: 'SaveAs',
                type: 'text',
                placeholder: 'Please input the link'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        images: {
            element: 'logo',
            title: 'Images',
            value: [],
            config: {
                type: 'pc'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        show: {
            element: 'selectboolean',
            title: 'Show at home',
            value: { value: false, name: 'NO' },
            config: {
                name: 'ShowAtHome',
                type: "text",
                placeholder: "Show at home"
            },
            options: [
                { value: true, name: 'YES' },
                { value: false, name: 'NO' },
            ],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });

    const [formevent, formeventHandler] = React.useState({
        show: {
            element: 'selectboolean',
            title: 'Show event in the slider',
            value: { value: false, name: 'NO' },
            config: {
                name: 'ShowEvent',
                type: "text",
                placeholder: "Show event"
            },
            options: [
                { value: true, name: 'YES' },
                { value: false, name: 'NO' },
            ],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        name: {
            element: 'input',
            title: 'Event Name',
            value: '',
            config: {
                name: 'SaveAsEvent',
                type: 'text',
                placeholder: 'Save event as'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        venue: {
            element: 'input',
            title: 'Venue name',
            value: '',
            config: {
                name: 'SaveAsVenue',
                type: 'text',
                placeholder: 'Venue name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        location: {
            element: 'input',
            title: 'Input Latitude and Longitude',
            value: '',
            config: {
                name: 'SaveLocation',
                type: 'text',
                placeholder: 'ex: -6.161624183943376, 106.92663225674043'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        startdate: {
            element: 'date',
            title: 'Start date',
            value: '',
            config: {
                name: 'SaveStartDate',
                type: 'text',
                placeholder: 'Start date'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        enddate: {
            element: 'date',
            title: 'End date',
            value: '',
            config: {
                name: 'SaveEndDate',
                type: 'text',
                placeholder: 'End date'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        hourfrom: {
            element: 'input',
            title: 'Open Hour',
            value: '',
            config: {
                name: 'SaveAsOpenHour',
                type: 'text',
                placeholder: 'Event Open Hour'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        houruntil: {
            element: 'input',
            title: 'Close Hour',
            value: '',
            config: {
                name: 'SaveAsCloseHour',
                type: 'text',
                placeholder: 'Event Close Hour'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        notes: {
            element: 'input',
            title: 'Notes',
            value: '',
            config: {
                name: 'SaveNotes',
                type: 'text',
                placeholder: 'Notes'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
    });

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                loadingHandler(true);
                const media = await dispatch(getAllMediaSlider());
                if (media.status === 200 && media.data.success) {
                    allMediaHandler(media.data.data)
                }
            }
        }
        fetchData().then(() => {
            loadingHandler(false)
        })
    }, [dispatch])

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const updateEventForm = (element) => {
        const newFormdata = update(element, formevent, formEventName);
        formeventHandler(newFormdata)
    }

    const datehandleChange = (element) => {
        var newFormdata = formevent;

        const temporaryFormdata = {
            ...newFormdata
        }

        const newElement = {
            ...temporaryFormdata[element.id]
        }

        if (element.event._d) {
            newElement.value = element.event._d.valueOf().toString();
            let validData = validate(newElement, formevent);
            newElement.valid = validData[0];
            newElement.validationMessage = validData[1];
            newElement.touched = true;
        } else {
            newElement.value = ""
        }
        temporaryFormdata[element.id] = newElement;
        formeventHandler(temporaryFormdata);
    }

    const updateSelectForm = (element, getdata, dataHandler) => {
        const newSelect = updateSelect(element, getdata);
        dataHandler(newSelect);
        outsideClickRef.current.closeFromOutside();
    }

    const submitAddData = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let dataEventToSubmit = generateData(formevent, formEventName);
        let formIsValid = isFormValid(formdata, formName);
        let formEventIsValid = isFormValid(formevent, formEventName);

        if (formIsValid) {
            dataToSubmit.show = dataToSubmit['show'].value;
            dataEventToSubmit.show = dataEventToSubmit['show'].value;
            if (dataEventToSubmit.show) {
                if (formEventIsValid) {
                    if (dataEventToSubmit.startdate > dataEventToSubmit.enddate) {
                        dispatch(global.globalError('End date same or bigger date'));
                        loadingHandler(false);
                    }

                } else {
                    dispatch(global.globalError('Please check event data'))
                    loadingHandler(false);
                }
            }

            dataToSubmit.event = dataEventToSubmit;
            const result = await dispatch(addSliderFunction(dataToSubmit))
            if (result === undefined || result === 'undefined') {
                loadingHandler(false);
            }
            if (!result.data.success) {
                dispatch(global.globalError('please try again'))
                loadingHandler(false);
            }
            dispatch(global.globalSuccess('slider added'))
            setTimeout(() => {
                const newFormdata = resetFields(formdata, formName);
                formdataHandler(newFormdata)
                const newEventFormdata = resetFields(formevent, formEventName);
                formeventHandler(newEventFormdata)
                navigate('/private/slider/dashboard')
                loadingHandler(false);
            }, 2000);
        } else {
            dispatch(global.globalError('Please check your data'))
            loadingHandler(false);
        }
    }

    const mediaClickHandler = async () => {
        document.body.style.overflow = 'overlay';
        mediaRef.current.clearData();
        pickedHandler([]);
        const media = await dispatch(getAllMediaSlider());
        if (media.status === 200 && media.data.success) {
            allMediaHandler(media.data.data)
        } else {
            dispatch(global.globalError('Network error'))
        }
        resetClickHandler();
    }

    const resetClickHandler = async () => {
        document.body.style.overflow = 'overlay';
        if (formdata['images'].value && formdata['images'].value.length > 0) {
            pickedHandler(formdata['images'].value)
        }
        openmediaHandler(false);
    }

    const onMediaSubmit = async ({ event, id }) => {
        event.preventDefault();
        event.stopPropagation();

        if (picked && picked.length > 0) {

            formdataHandler((prevData) => {
                var newFormdata = { ...prevData };
                const temporaryFormdata = { ...newFormdata };
                const newData = {
                    ...temporaryFormdata[id]
                }
                newData.value = picked;
                newData.valid = true;
                newData.touched = true;
                temporaryFormdata[id] = newData;
                return temporaryFormdata
            })
            openmediaHandler(false);
        } else {
            dispatch(global.globalError('Please select image'))
        }
    }

    const submitAddImage = async ({ event, id }) => {
        event.preventDefault();
        event.stopPropagation();
        pickedidHandler(id)
        openmediaHandler(true);
    }

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                <div className='cardWrapper'>
                    <div className='cardHeader' style={{ paddingBottom: 0 }}>
                        <h5>{formName}</h5>
                    </div>
                    <div className='cardBody'>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul className='mb30 mb-xs-0'>
                                    <li className='inputSearch'>
                                        <FormField
                                            id={'name'}
                                            formdata={formdata.name}
                                            change={(element) => updateForm(element)}
                                            theClass="inputclass"
                                            icons={<FiUser />}
                                            showName={true}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                    <li className='inputSearch'>
                                        <FormField
                                            id={'title'}
                                            formdata={formdata.title}
                                            change={(element) => updateForm(element)}
                                            theClass="inputclass"
                                            icons={<FiUser />}
                                            showName={true}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                    <li className='inputSearch'>
                                        <FormField
                                            id={'subtitle'}
                                            formdata={formdata.subtitle}
                                            change={(element) => updateForm(element)}
                                            theClass="inputclass"
                                            icons={<FiUser />}
                                            showName={true}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                    <li className='inputSearch'>
                                        <FormField
                                            id={'goto'}
                                            formdata={formdata.goto}
                                            change={(element) => updateForm(element)}
                                            theClass="inputclass"
                                            icons={<FiUser />}
                                            showName={true}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                    <li className='inputSearch'>
                                        <FormField
                                            ref={outsideClickRef}
                                            id={'show'}
                                            formdata={formdata.show}
                                            selectForm={(element) => updateSelectForm(element, formdata, formdataHandler)}
                                            theClass="inputclass"
                                            icons={<FaGlobe />}
                                            formdataHandler={formdataHandler}
                                            showName={true}
                                            thekey={'name'}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul className='mb30 mb-xs-0'>
                                    <li className='inputSearch'>
                                        <FormField
                                            ref={outsideClickRef}
                                            id={'show'}
                                            formdata={formevent.show}
                                            selectForm={(element) => updateSelectForm(element, formevent, formeventHandler)}
                                            theClass="inputclass"
                                            icons={<FaGlobe />}
                                            formdataHandler={formeventHandler}
                                            showName={true}
                                            thekey={'name'}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {
                            formevent.show && formevent.show.value.value ?
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <div className='cardOptions form'>
                                        <ul className='mb30 mb-xs-0'>
                                            <li className='inputSearch'>
                                                <FormField
                                                    id={'name'}
                                                    formdata={formevent.name}
                                                    change={(element) => updateEventForm(element)}
                                                    theClass="inputclass"
                                                    icons={<FiUser />}
                                                    showName={true}
                                                    disabled={loading ? true : false}
                                                />
                                            </li>
                                            <li className='inputSearch'>
                                                <FormField
                                                    id={'venue'}
                                                    formdata={formevent.venue}
                                                    change={(element) => updateEventForm(element)}
                                                    theClass="inputclass"
                                                    icons={<FiUser />}
                                                    showName={true}
                                                    disabled={loading ? true : false}
                                                />
                                            </li>
                                            <li className='inputSearch'>
                                                <FormField
                                                    id={'location'}
                                                    formdata={formevent.location}
                                                    change={(element) => updateEventForm(element)}
                                                    theClass="inputclass"
                                                    icons={<FiUser />}
                                                    showName={true}
                                                    disabled={loading ? true : false}
                                                />
                                            </li>
                                            <li className='inputSearch'>
                                                <FormField
                                                    id={'notes'}
                                                    formdata={formevent.notes}
                                                    change={(element) => updateEventForm(element)}
                                                    theClass="inputclass"
                                                    icons={<FiUser />}
                                                    showName={true}
                                                    disabled={loading ? true : false}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            formevent.show && formevent.show.value.value ?
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <div className='cardOptions form'>
                                        <ul className='mb30 mb-xs-0'>
                                            <li className='inputSearch'>
                                                <FormField
                                                    id={'startdate'}
                                                    formdata={formevent.startdate}
                                                    change={(element) => datehandleChange(element)}
                                                    theClass="inputclass"
                                                    icons={<FiUser />}
                                                    showName={true}
                                                    disabled={loading ? true : false}
                                                />
                                            </li>
                                            <li className='inputSearch'>
                                                <FormField
                                                    id={'enddate'}
                                                    formdata={formevent.enddate}
                                                    change={(element) => datehandleChange(element)}
                                                    theClass="inputclass"
                                                    icons={<FiUser />}
                                                    showName={true}
                                                    disabled={loading ? true : false}
                                                />
                                            </li>
                                            <li className='inputSearch'>
                                                <FormField
                                                    id={'hourfrom'}
                                                    formdata={formevent.hourfrom}
                                                    change={(element) => updateEventForm(element)}
                                                    theClass="inputclass"
                                                    icons={<FiUser />}
                                                    showName={true}
                                                    disabled={loading ? true : false}
                                                />
                                            </li>
                                            <li className='inputSearch'>
                                                <FormField
                                                    id={'houruntil'}
                                                    formdata={formevent.houruntil}
                                                    change={(element) => updateEventForm(element)}
                                                    theClass="inputclass"
                                                    icons={<FiUser />}
                                                    showName={true}
                                                    disabled={loading ? true : false}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                : null
                        }
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul>
                                    <li
                                        id="fullSubmit"
                                        className='iconRefresh button'
                                        onClick={
                                            loading ? null
                                                : (element) => submitAddImage({ event: element, id: 'images' })
                                        }
                                    >
                                        <div className='center formSubmit'>
                                            {
                                                loading ? <AdminLoading theTheme={theme} />
                                                    : <span><FiPlus /> Add Image</span>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 pb20 pl0 pr0'>
                            <div className='cardMedia'>
                                <div className='mediaList'>
                                    {
                                        formdata.images && formdata.images.value && formdata.images.value.length > 0 ?
                                            <GalleryMain
                                                images={formdata.images && formdata.images.value}
                                                theme={theme}
                                            />
                                            :
                                            <div className={`lumiGalleryWrapper ${theme}`}>
                                                No images
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul>
                                    <li
                                        id="fullSubmit"
                                        className='iconRefresh button'
                                        onClick={
                                            loading ? null
                                                : (event) => submitAddData(event)
                                        }
                                    >
                                        <div className='center formSubmit'>
                                            {
                                                loading ? <AdminLoading theTheme={theme} />
                                                    : <span><FiSend /> Submit</span>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <MediaFloatingScreen
                    ref={mediaRef}
                    media={allMedia}
                    mediaHandler={allMediaHandler}
                    size={size}
                    mainMediaOpen={openmedia}
                    theme={theme}
                    uploadmultiple={true}
                    enablemultiple={true}
                    onMediaSubmit={onMediaSubmit}
                    onCancelSubmit={mediaClickHandler}
                    picked={picked}
                    pickedHandler={pickedHandler}
                    pickedid={pickedid}
                    loadingupload={loadingupload}
                    loadinguploadHandler={loadinguploadHandler}
                    loadingdelete={loadingdelete}
                    loadingdeleteHandler={loadingdeleteHandler}
                    addFunction={addMediaSlider}
                    deleteFunction={deleteMediaSlider}
                    getFunction={getAllMediaSlider}
                    allowedCropSize={[2]}
                />
            }
            {
                !loading && openmedia &&
                <ClientBackdrop click={mediaClickHandler} />
            }
        </div >
    )
}

export default SliderAddPage;