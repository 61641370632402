import React from 'react';

import {
    useDispatch,
    useSelector
} from 'react-redux';

const CantDecideSection = ({
    isMobile
}) => {

    const thedata = useSelector(state => state.websitefront.getWebsiteSetting && state.websitefront.getWebsiteSetting.length > 0 && state.websitefront.getWebsiteSetting[0])

    return (
        <div className="section" style={{ marginBottom: isMobile ? '150px' : '150px', paddingTop: isMobile ? '0px' : '40px' }}>
            <div className="container">
                <div className="row">
                    <div className="lumi-md-6 lumi-xs-12">
                        <div className="headingblock">
                            <h2>Still Can't Decide!?</h2>
                            <small>Contact us</small>
                            <p>
                                If you have any questions, or want to discuss with us about your plan, dont hesitate to contact us.<br />
                                We are ready to serve you, {isMobile ? <br /> : null}<strong>{thedata && thedata.openhourinfo}</strong> {thedata && thedata.openhourextrainfo !== "" ? <br /> : null}
                                {thedata && thedata.openhourextrainfo !== "" ? thedata && thedata.openhourextrainfo : null}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CantDecideSection;