import React from 'react';

import { printIcons } from '../widget/sosmedicons';

const FooterPage = ({
    thedata,
    theme
}) => {

    let thisyear = new Date().getFullYear();

    return (
        <div id="footer">
            <div className="container">
                <div className="row">
                    <div className="footerwrapper">
                        <div className="lumi-md-6 lumi-xs-12 pl-xs-0 pr-xs-0">
                            <div className="footercard">
                                <h3 className='mb10'>Address</h3>
                                <address className="mb10">
                                    <strong>Offices: </strong>
                                    <br />
                                    {thedata && thedata.address.address}
                                </address>
                                <abbr title="Phone Number">
                                    <strong>Phone: </strong>
                                </abbr>
                                {`${thedata && thedata.number.whatsapp.extension}${thedata && thedata.number.whatsapp.phone}`}
                                <br />
                                <abbr title="Whatsapp">
                                    <strong>Whatsapp: </strong>
                                </abbr>
                                {`${thedata && thedata.whatsapp.whatsapp.extension}${thedata && thedata.whatsapp.whatsapp.phone}`}
                                <br />
                                <abbr title="Email Address">
                                    <strong>Email: </strong>
                                </abbr>
                                {`${thedata && thedata.email.email}`}
                            </div>
                        </div>
                        <div className="lumi-md-6 lumi-xs-12 pl-xs-0 pr-xs-0">
                            <div className="footercard">
                                <aside className="footersocial mb50">
                                    <h3 className='mb10'>Luminous Sposa</h3>
                                    <p className='mb10'>{thedata && thedata.subtitle}</p>
                                    <ul className="list">
                                        {
                                            printIcons(thedata && thedata.mainsosmed, theme)
                                        }
                                    </ul>
                                </aside>
                                <aside className="footersocial">
                                    <h3 className='mb10'>Ellsha Makeup Artist & Attire</h3>
                                    <p className='mb10'>For a lifetime of unforgettable memories</p>
                                    <ul className="list">
                                        {
                                            printIcons(thedata && thedata.subsosmed, theme)
                                        }
                                    </ul>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container homecopyright">
                <div className="row">
                    <div className="lumi-md-12 lumi-xs-12">
                        <p className="footercopy">
                            © {thisyear}
                            <a
                                href="https://lumisoft.co.id"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                &nbsp;{thedata && thedata.title}
                            </a>. All Rights Reserved /
                            <a
                                href="/"
                                target="_blank"
                                rel="noopener noreferrer">
                                Terms of Use
                            </a> /
                            <a
                                href="/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterPage;