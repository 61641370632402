import React from 'react';

import UploadCardWrapper from './uploadcardwrapper';

const UploadWrapper = ({
    title,
    images,
    isMobile,
    isTablet,
    isBiggerTablet,
    isBigPC
}) => {

    const [currentPage, currentPageHandler] = React.useState(1);
    const [totalCount, totalCountHandler] = React.useState(0);
    const pageSize = isMobile ? 6 : isTablet ? 9 : isBiggerTablet ? 16 : isBigPC ? 24 : 18;

    const currentTableData = React.useMemo(() => {
        var semuadata = [];
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        semuadata = [...images];
        totalCountHandler(images.length);
        var mydatas = semuadata.slice(firstPageIndex, lastPageIndex);
        return mydatas
    }, [currentPage, images, pageSize]);

    let divide = totalCount / pageSize;
    let percentage = (totalCount % pageSize) > 0 ? 1 : 0;
    let lastPage = parseInt(divide + percentage)

    return (
        <div className='imgWrapper'>
            <div
                className="row"
            >
                <div
                    className="lumi-md-12 lumi-xs-12"
                >
                    <div className='mediaTitleWrapper'>
                        <div className='mediaTitle'>
                            {title}
                        </div>
                        <div
                            className='mediaPick'
                        >
                            <div
                                className='mediaPickDetails'
                            >
                                {currentPage} / {lastPage} pages
                            </div>
                        </div>
                    </div>
                </div>
                <UploadCardWrapper
                    currentTableData={currentTableData}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    currentPageHandler={currentPageHandler}
                    lastPage={lastPage}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    isBiggerTablet={isBiggerTablet}
                    isBigPC={isBigPC}
                />
            </div>
        </div>
    )
}

export default UploadWrapper;