import * as data from './data.slider';
import * as global from '../global/data.global';
import axios from '../api';
import axiosmultipart from '../apimultipart';

import { checkError } from '../global/function';

import {
    getAuthHeader,
    tokenName
} from '../../../views/utils/tools';

import {
    ADD_MEDIA_SLIDER, DELETE_MEDIA_SLIDER
} from '../../types/slider';

export const getAllMediaSlider = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/slider/getmediaslider');
            dispatch(data.getMediaSlider(request.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export async function addMediaSlider(dataToSubmit) {
    try {
        const formData = new FormData();
        dataToSubmit.forEach((img, i) => {
            formData.append(`image${i}`, img);
        });

        formData.append('upload_preset', process.env.REACT_APP_UPLOADPRESET);
        formData.append('cloud_name', process.env.REACT_APP_CLOUDNAME);

        const request = axiosmultipart.post(
            `/api/slider/addmediaslider`,
            formData
        ).then(response => response.data);

        return {
            type: ADD_MEDIA_SLIDER,
            payload: request
        }
    } catch (error) {
        return error
    }
}

export async function deleteMediaSlider(dataToSubmit) {
    try {
        const request = axios.post(`/api/slider/deletemediaslider`, dataToSubmit).then(response => response.data);

        return {
            type: DELETE_MEDIA_SLIDER,
            payload: request
        }
    } catch (error) {
        return error
    }
}

export const getSliderFunction = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/slider/getslider');
            dispatch(data.getSlider(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addSliderFunction = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/slider/addslider`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addSlider(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateSliderFunction = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/slider/updateslider`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateSlider(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}