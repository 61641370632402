import React from 'react';

import { GoMail } from 'react-icons/go';
import { printIcons } from '../widget/sosmedicons';

const SocialMedia = ({
    theme,
    thedata
}) => {

    return (
        <div className="right">
            {
                thedata === undefined ? null :
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`mailto:${thedata && thedata.email.email}?subject=Dear ${thedata && thedata.title}&body=Could you please help us?`}
                            >
                                <GoMail size={25} />
                            </a>
                        </li>
                        {
                            printIcons(thedata && thedata.websitesosmed, theme)
                        }
                    </ul>
            }
        </div>
    )
}

export default SocialMedia;