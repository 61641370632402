import React from 'react';
import {
    useDispatch
} from 'react-redux';
import {
    useNavigate
} from "react-router-dom";

import SignInForm from './signinForm';

import { clientIsAuth } from '../../store/actions/auth';

import CircleLoading from '../utils/circle.loading';

const LoginPage = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, loadingHandler] = React.useState(true);

    const { theme } = props;

    const logoURL = theme === 'dark' ? "/logo192.png" : "/lumisoft.png";

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(clientIsAuth())
            }
        }
        fetchData().then(async (response) => {
            if (response && response.data.success) {
                let theData = response.data.data;
                if (!theData.isPin) {
                    navigate('/auth/pin')
                }
                if (theData.isPin && theData.isFirstTime) {
                    navigate('/auth/welcome')
                }
                if (theData.isPin && !theData.isFirstTime) {
                    navigate('/private')
                }
                setTimeout(() => {
                    loadingHandler(false)
                }, 1000)
            }
            setTimeout(() => {
                loadingHandler(false)
            }, 1000)
        })
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate]);

    if (loading) {
        return (
            <div style={{
                paddingTop: 78,
                paddingBottom: 80
            }}>
                <CircleLoading />
            </div>
        )
    }

    return (
        <div className='loginInnerForm'>
            <div className='details'>
                <img
                    src={window.location.origin + logoURL}
                    alt=""
                    width="100px"
                />
                <h3>Sign in here</h3>
                <SignInForm
                    {...props}
                />

            </div>
            <small>
                if you already validate your email, and you are here for the first time, please fill in the password with your own password.
            </small>
            <small className='mt20'>
                The password will automatically become your password.
            </small>
        </div>
    )
}

export default LoginPage;