import {
    CLIENT_GETALL,
    ROLES_GETALL,
    ADDADMIN_POST,
    UPDATEADMIN_PATCH
} from "../types/client";

export default function clientReducer(state = {}, action) {
    switch (action.type) {
        case CLIENT_GETALL:
            return {
                ...state,
                clientGetAll: action.payload
            }
        case ROLES_GETALL:
            return {
                ...state,
                rolesGetAll: action.payload
            }
        case ADDADMIN_POST:
            return {
                ...state,
                addAdmin: action.payload
            }
        case UPDATEADMIN_PATCH:
            return {
                ...state,
                updateAdmin: action.payload
            }
        default:
            return state;
    }
}