import React from 'react';

import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useNavigate,
    useOutletContext
} from 'react-router-dom';

import GlobalTableTemplate from '../../utils/table/globaltable';
import { getWebsiteSosmed } from '../../../store/actions/website';
import { useWindowSize } from '../../utils/windowsize';
import { mobile, tablet, bigTablet, isPC } from '../../utils/globalsize';

const WebsiteSosmedDashboard = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        // size
    } = globaldata;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const size = useWindowSize();

    const isMobile = mobile(size);

    const theUser = useSelector(state => state.auth && state.auth.data);

    const [loading, loadingHandler] = React.useState(false);
    const [alldata, alldataHandler] = React.useState([]);

    let formname = 'Number'
    let sosmed = [
        {
            name: 'Save as',
            code: 'saveas'
        },
        {
            name: 'Provider',
            code: 'provider'
        },
        {
            name: 'Active',
            code: 'active'
        }
    ]

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                // if (theUser !== undefined && theUser.rolesName !== 'superadmin') {
                //     navigate('/')
                // }
                const sosmed = await dispatch(getWebsiteSosmed());
                if (sosmed.status === 200 && sosmed.data.success) {
                    alldataHandler(sosmed.data.data)
                }
            }
        }
        fetchData()
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate, theUser])

    const allURLs = async () => {
        loadingHandler(true)
        const sosmed = await dispatch(getWebsiteSosmed());
        alldataHandler(sosmed.data.data)
        loadingHandler(false)
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/website/setting')
    }

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                {
                    <GlobalTableTemplate
                        tableId={formname}
                        tableName={`${formname} Table`}
                        alldata={alldata}
                        datatoshow={sosmed}
                        pageSize={10}
                        loading={loading}
                        allURLs={allURLs}
                        theTheme={theme}
                        navigateto={'website/sosmed'}
                        backStatus={true}
                        backFunction={(event) => goBack(event)}
                        isMobile={isMobile}
                    />
                }
            </div>
        </div>
    )
}

export default WebsiteSosmedDashboard;