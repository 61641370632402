import React from 'react';

import {
    useNavigate,
    // NavLink 
} from 'react-router-dom';


import SocialMedia from '../socialmedia';
import SideMainNavigation from './side.nav';

const SideWebsiteNav = ({
    show,
    theme,
    click,
    isMobile,
    isTablet
}) => {

    const navigate = useNavigate();
    
    const className = `sideMobileWrapper ${show ? 'open' : ''}`;
    const logoUrl = theme === 'light' ? require('../../resources/images/LuminousSposa_Logo_9.png') : require('../../resources/images/LuminousSposa_Logo_12.png')

    return (
        <nav className={className}>
            <div className='sidelogo'>
                <div
                    className="logoImageWrapper"
                    onClick={() => navigate('/')}
                >
                    <img
                        className="logoImage"
                        src={logoUrl}
                        alt=""
                        width="200px"
                    />

                </div>
            </div>
            <SocialMedia 
                theme={theme}
            />
            <SideMainNavigation
                isMobile={isMobile}
                isTablet={isTablet}
                sideWebsiteBackdrop={click}
            />
        </nav>
    )
}

export default SideWebsiteNav;