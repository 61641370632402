import {
    CLIENT_AUTH
} from '../types/auth';

import {
    CLIENT_SIGN_IN,
    CLIENT_SIGN_OUT,
    CLIENT_UPDATE_PIN,
    CLIENT_UPDATE_FIRST_TIME
} from '../types/client';

let DEFAULT_CLIENT_STATE = {
    data: {
        _id: null,
        email: null,
        firstname: null,
        lastname: null,
        phone: null,
        extension: null,
        nationality: null,
        countrycode: null,
        carrier: null,
        pin: null,
        password: null,
        token: null,
        isAuth: null, //false
        isFirstTime: null, //true
        isPassword: null, //false
        isPin: null, //false
        isActive: null, //true
        identitynumber: null,
        identitypictures: null,
        isVerified: null, //false
        isEmailVerified: null, //false
        isPhoneVerified: null, //false
        roles: null, //admin
        upline: [],
        profileimage: {},
        downline: []
    },
    auth: null
}

export default function authReducer(state = DEFAULT_CLIENT_STATE, action) {
    switch (action.type) {
        case CLIENT_AUTH:
            return { ...state, ...action.payload }
        case CLIENT_SIGN_IN:
            return { ...state, ...action.payload }
        case CLIENT_SIGN_OUT:
            return {
                ...state,
                data: { ...DEFAULT_CLIENT_STATE.data },
                auth: false
            }
        case CLIENT_UPDATE_PIN:
            return { ...state, ...action.payload }
        case CLIENT_UPDATE_FIRST_TIME:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}