import {
    GET_GALLERY,
    ADD_GALLERY,
    UPDATE_GALLERY,
    GET_GALLERY_CATEGORY,
    ADD_GALLERY_CATEGORY,
    UPDATE_GALLERY_CATEGORY,
    GET_GALLERY_TAGS,
    ADD_GALLERY_TAGS,
    UPDATE_GALLERY_TAGS,
    GET_MEDIA_GALLERY,
    ADD_MEDIA_GALLERY,
    DELETE_MEDIA_GALLERY
} from '../../types/gallery';

export const getMediaGallery = (request) => ({
    type: GET_MEDIA_GALLERY,
    payload: request
})

export const addMediaGallery = (request) => ({
    type: ADD_MEDIA_GALLERY,
    payload: request
})

export const deleteMediaGallery = (request) => ({
    type: DELETE_MEDIA_GALLERY,
    payload: request
})

export const getGallery = (request) => ({
    type: GET_GALLERY,
    payload: request
})

export const addGallery = (request) => ({
    type: ADD_GALLERY,
    payload: request
})

export const updateGallery = (request) => ({
    type: UPDATE_GALLERY,
    payload: request
})

// CATEGORY
export const getGalleryCategory = (request) => ({
    type: GET_GALLERY_CATEGORY,
    payload: request
})

export const addGalleryCategory = (request) => ({
    type: ADD_GALLERY_CATEGORY,
    payload: request
})

export const updateGalleryCategory = (request) => ({
    type: UPDATE_GALLERY_CATEGORY,
    payload: request
})

// TAGS
export const getGalleryTags = (request) => ({
    type: GET_GALLERY_TAGS,
    payload: request
})

export const addGalleryTags = (request) => ({
    type: ADD_GALLERY_TAGS,
    payload: request
})

export const updateGalleryTags = (request) => ({
    type: UPDATE_GALLERY_TAGS,
    payload: request
})
