import React from 'react';

import {
    useDispatch
} from 'react-redux';

import { useNavigate } from "react-router-dom";

import * as global from '../../store/actions/global/data.global';

import FormPin from '../utils/form/formPin';
import FormButton from '../utils/form/formButton';

import { generateData, isFormValid, update } from '../utils/form/formActions';
import { updatePin } from '../../store/actions/auth';
import CircleLoading from '../utils/circle.loading';

const PinForm = (props) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, loadingHandler] = React.useState(false);
    const formName = 'Input pin';
    const theLength = 6;
    const [formdata, formdataHandler] = React.useState({
        pin: {
            element: 'pin',
            value: "",
            config: {
                name: 'Pin',
                type: 'numeric',
                placeholder: 'Enter your pin'
            },
            validation: {
                required: true,
                number: true,
                secret: false
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    const updateForm = async (element) => {
        const newFormdata = await update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const oneStepBeforeSubmit = () => {
       
    }

    const submitForm = async () => {
        try {
            loadingHandler(true);

            let dataToSubmit = generateData(formdata, formName);
            let formIsValid = isFormValid(formdata, formName);
            // COBA DI CHECK LAGI WAKTU LOGIN, REQUEST DATANYA DAH BENER BLOM TUH
            // BUAT BUTTON LOGOUTNYA
     
            let totalDataToSubmit = {
                ...dataToSubmit,
                email: props.client.data.email
            }

            if (formIsValid) {
                let userauth = await dispatch(updatePin(totalDataToSubmit))
                if(userauth === undefined){
                    setTimeout(() => {
                        loadingHandler(false);
                    }, 5000)
                } else {
                    let usertocheck = userauth.data;
                    if (!usertocheck.success) {
                        setTimeout(() => {
                            dispatch(global.globalError('Please check your data'))
                            loadingHandler(false);
                        }, 5000);
                    } else if(usertocheck.data.isPin && !usertocheck.data.isFirstTime){
                        dispatch(global.globalSuccess('Success, redirect to dashboard'))
                        setTimeout(() => {
                            navigate('/private')
                        }, 5000);
                    } else if(usertocheck.data.isFirstTime) {
                        dispatch(global.globalSuccess('Success, redirect to welcome page'))
                        setTimeout(() => {
                            navigate('/auth/welcome')
                        }, 5000);
                    } else {
                        setTimeout(() => {
                            dispatch(global.globalError('Please check your data'))
                            loadingHandler(false);
                        }, 5000);
                    }
                } 

            } else {
                setTimeout(() => {
                    dispatch(global.globalError('Please check your data'))
                    loadingHandler(false);
                }, 5000);
            }
        } catch (error) {
            setTimeout(() => {
                dispatch(global.globalError('something is wrong, please retry'))
                loadingHandler(false);
            }, 5000);
        }
    }

    if (loading) {
        return (
            <div style={{
                paddingTop: 78,
                paddingBottom: 80
            }}>
                <CircleLoading />
            </div>
        )
    }

    return (
        <form onSubmit={(event) => submitForm(event)}>
            <div className='clientpinWrapper'>
                <FormPin
                    id={'pin'}
                    formdata={formdata.pin}
                    formdataHandler={formdataHandler}
                    change={(element) => updateForm(element)}
                    theClass="inputclasspinsix"
                    length={theLength}
                    onComplete={oneStepBeforeSubmit}
                    focus={true}
                    disabled={false}
                />
                <FormButton
                    theName={'submitNF'}
                    theClass={'submitButton'}
                    theType={'submit'}
                    theTitle={'Submit'}
                />
            </div>
        </form>
    )
}

export default PinForm;