import React from 'react';

import {
    IoSearch
} from "react-icons/io5";
import ImageCardWrapper from './imagecardwrapper';

const ImageWrapper = ({
    title,
    images,
    multiple = false,
    pickedHandler,
    picked,
    isMobile,
    isTablet,
    isBiggerTablet,
    isBigPC
}) => {

    const [filterText, filterTextHandler] = React.useState("");
    const [currentPage, currentPageHandler] = React.useState(1);
    const [totalCount, totalCountHandler] = React.useState(0);
    const pageSize = isMobile ? 6 : isTablet ? 9 : isBiggerTablet ? 12 : isBigPC ? 12 : 12;
    let bydefaultnodata = images && images.length > 0 ? false : true;

    const currentTableData = React.useMemo(() => {
        var results = [];
        var semuadata = [];
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        if (filterText !== "") {
            results = images && images.length > 0 && images.filter(data =>
                (data.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1))
            semuadata = [...results];
            totalCountHandler(semuadata.length)
        } else {
            semuadata = [...images];
            totalCountHandler(images.length);
        }
        var mydatas = semuadata.slice(firstPageIndex, lastPageIndex);
        return mydatas
    }, [currentPage, images, pageSize, filterText]);

    let divide = totalCount / pageSize;
    let percentage = (totalCount % pageSize) > 0 ? 1 : 0;
    let lastPage = parseInt(divide + percentage)

    const handleChange = (event) => {
        filterTextHandler(event.target.value);
        currentPageHandler(1);
    }

    return (
        <div className='imgWrapper'>
            <div
                className="row"
            >
                <div
                    className="lumi-md-12 lumi-xs-12"
                >
                    <div className='mediaTitleWrapper'>
                        <div className='mediaTitle'>
                            {title}
                        </div>
                        <div
                            className='mediaPick'
                        >
                            <div
                                className='mediaPickDetails'
                            >
                                <div className="searchContainer">
                                    <div className="searchWrapper">
                                        <div className="iconPosition inactive">
                                            <IoSearch
                                                className="icon agraicon"
                                            />
                                        </div>
                                        <input
                                            autoCapitalize="none"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            className="tableSearch"
                                            type="text"
                                            name="Search"
                                            placeholder="Search"
                                            title="Type in a name"
                                            value={filterText}
                                            onChange={(event) => handleChange(event)}
                                            disabled={bydefaultnodata}
                                            style={{
                                                transition: 'none'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className='mediaPickDetails'
                            >
                                {currentPage} / {lastPage} pages
                            </div>
                            <div
                                className='mediaPickDetails'
                            >
                                {picked.length} picked
                            </div>
                        </div>
                    </div>
                </div>
                <ImageCardWrapper
                    multiple={multiple}
                    currentTableData={currentTableData}
                    picked={picked}
                    pickedHandler={pickedHandler}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    currentPageHandler={currentPageHandler}
                    lastPage={lastPage}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    isBiggerTablet={isBiggerTablet}
                    isBigPC={isBigPC}
                />
            </div>
        </div>
    )
}

export default ImageWrapper;