import React from 'react';

import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    // Navigate, 
    useNavigate,
    // useOutletContext
} from 'react-router-dom';

import { getWebsiteAddress, getWebsiteEmail, getWebsiteNumber, getWebsiteSosmed, getWebsiteSetting, submitSetting } from '../../../store/actions/website';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, removePush, update, updatePush, updateSearch, updateSelect, updateToSelected } from '../../utils/form/formActions';
import FormField from '../../utils/form/formField';

// import { mobile, tablet, bigTablet, isPC } from '../../utils/globalsize';

import AdminLoading from '../../utils/circle.loading';

import { FiFileText, FiGitBranch, FiPhone, FiPlus, FiSend } from 'react-icons/fi';
import { FaEnvelope, FaMapPin, } from 'react-icons/fa';



const WebsiteSettingTitle = (props) => {

    const { theme } = props;

    // const globaldata = useOutletContext();
    // const {
    //     // client, 
    //     size
    // } = globaldata;

    // const isMobile = mobile(size);
    // const isTablet = tablet(size);
    // const isBiggerTablet = bigTablet(size);
    // const isBigPC = isPC(size);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theUser = useSelector(state => state.auth && state.auth.data);
    const theSetting = useSelector(state => state.website && state.website.getWebsiteSetting);

    const outsideClickRef = React.useRef();

    const [loading, loadingHandler] = React.useState(false);

    const [allSosmed, allSosmedHandler] = React.useState([]);
    // const [allNumber, allNumberHandler] = React.useState([]);
    // const [allEmail, allEmailHandler] = React.useState([]);
    // const [allSosmed, allSosmedHandler] = React.useState([]);

    const formName = 'Title';
    const [formdata, formdataHandler] = React.useState({
        title: {
            element: 'input',
            title: 'Title',
            value: '',
            config: {
                name: 'Title',
                type: 'text',
                placeholder: 'Enter Title'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        subtitle: {
            element: 'input',
            title: 'Subtitle',
            value: '',
            config: {
                name: 'Subtitle',
                type: 'text',
                placeholder: 'Enter Subtitle'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        description: {
            element: 'input',
            title: 'Description',
            value: '',
            config: {
                name: 'Description',
                type: 'text',
                placeholder: 'Enter Description'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        maplocation: {
            element: 'input',
            title: 'Map Location',
            value: '',
            config: {
                name: 'MapLocation',
                type: 'text',
                placeholder: 'Enter Latitude & Longitude'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        openhourinfo: {
            element: 'input',
            title: 'Open hour info',
            value: '',
            config: {
                name: 'OpenHourInfo',
                type: 'text',
                placeholder: 'Enter Open Hour info'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        openhourextrainfo: {
            element: 'input',
            title: 'Open Hour Extra information',
            value: '',
            config: {
                name: 'OpenHourExtraInfo',
                type: 'text',
                placeholder: 'Enter Extra information'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        address: {
            element: 'selectboolean',
            title: 'Address',
            value: { value: '', name: 'Main Address' },
            config: {
                name: 'address',
                type: "text",
                placeholder: "Main Address"
            },
            options: [],
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        email: {
            element: 'selectboolean',
            title: 'Email',
            value: { value: '', name: 'Main Email' },
            config: {
                name: 'email',
                type: "text",
                placeholder: "Main Email"
            },
            options: [],
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        number: {
            element: 'selectboolean',
            title: 'Number',
            value: { value: '', name: 'Main Number' },
            config: {
                name: 'number',
                type: "text",
                placeholder: "Main Number"
            },
            options: [],
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        whatsapp: {
            element: 'selectboolean',
            title: 'Whatsapp',
            value: { value: '', name: 'Whatsapp Number' },
            config: {
                name: 'whatsappnumber',
                type: "text",
                placeholder: "Whatsapp Number"
            },
            options: [],
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        websitesosmed: {
            element: 'selectbooleanwithbutton',
            title: 'Website Sosmed',
            value: [],
            selected: "",
            search: '',
            config: {
                name: 'websitesosmed',
                type: "text",
                placeholder: "Website Sosmed"
            },
            options: [],
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        mainsosmed: {
            element: 'selectbooleanwithbutton',
            title: 'Main Sosmed',
            value: [],
            selected: "",
            search: '',
            config: {
                name: 'mainsosmed',
                type: "text",
                placeholder: "Main Sosmed"
            },
            options: [],
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        subsosmed: {
            element: 'selectbooleanwithbutton',
            title: 'Sub Sosmed',
            value: [],
            selected: "",
            search: '',
            config: {
                name: 'subsosmed',
                type: "text",
                placeholder: "Sub Sosmed"
            },
            options: [],
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
    });

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                loadingHandler(true);
                const address = await dispatch(getWebsiteAddress());
                let addressmutate = []
                if (address.status === 200 && address.data.success) {
                    let addressdata = address.data.data;
                    addressmutate = addressdata.map((single) => ({ value: single._id, name: single.address }))
                    // allAddressHandler(addressmutate)
                };
                const number = await dispatch(getWebsiteNumber());
                let numbermutate = []
                if (number.status === 200 && number.data.success) {
                    let numberdata = number.data.data;
                    numbermutate = numberdata.map((single) => ({ value: single._id, name: `${single.whatsapp.extension}${single.whatsapp.phone}` }))
                    // allNumberHandler(numbermutate)
                };
                const email = await dispatch(getWebsiteEmail());
                let emailmutate = []
                if (email.status === 200 && email.data.success) {
                    let emaildata = email.data.data;
                    emailmutate = emaildata.map((single) => ({ value: single._id, name: single.email }))
                    // allEmailHandler(emailmutate)
                };
                const sosmed = await dispatch(getWebsiteSosmed());
                let sosmedmutate = []
                if (sosmed.status === 200 && sosmed.data.success) {
                    let sosmeddata = sosmed.data.data;
                    let sosmedfilter = sosmeddata.filter((data) => data.active === true);
                    sosmedmutate = sosmedfilter.map((single) => ({ value: single._id, name: `${single.link}`, saveas: `${single.saveas}`, provider: `${single.provider}` }))
                    allSosmedHandler(sosmedmutate)
                };

                const setting = await dispatch(getWebsiteSetting());
                let settingdata = setting.data.data;
                let singlesingle = settingdata && settingdata[0];

                formdataHandler((prevFormData) => {
                    var newFormdata = { ...prevFormData };
                    const temporaryFormdata = { ...newFormdata };
                    for (let key in newFormdata) {
                        const newData = {
                            ...temporaryFormdata[key]
                        }
                        if (newData.element === 'selectboolean') {
                            if (newData.title === 'Address') {
                                newData.options = addressmutate
                                if (settingdata && settingdata.length > 0) {
                                    newData.value = { value: singlesingle.address._id, name: singlesingle.address.address }
                                    newData.valid = true;
                                    newData.touched = true;
                                }
                            } else if (newData.title === 'Email') {
                                newData.options = emailmutate
                                if (settingdata && settingdata.length > 0) {
                                    newData.value = { value: singlesingle.email._id, name: singlesingle.email.email }
                                    newData.valid = true;
                                    newData.touched = true;
                                }
                            } else if (newData.title === 'Number') {
                                newData.options = numbermutate
                                if (settingdata && settingdata.length > 0) {
                                    newData.value = { value: singlesingle.number._id, name: `${singlesingle.number.whatsapp.extension}${singlesingle.number.whatsapp.phone}` }
                                    newData.valid = true;
                                    newData.touched = true;
                                }
                            } else {
                                // } else if (newData.title === 'Whatsapp') {
                                newData.options = numbermutate
                                if (settingdata && settingdata.length > 0) {
                                    newData.value = { value: singlesingle.whatsapp._id, name: `${singlesingle.whatsapp.whatsapp.extension}${singlesingle.whatsapp.whatsapp.phone}` }
                                    newData.valid = true;
                                    newData.touched = true;
                                }
                            }
                            // else {
                            //     newData.options = sosmedmutate
                            //     if (settingdata && settingdata.length > 0) {
                            //         newData.value = { value: singlesingle.sosmed._id, name: `${singlesingle.sosmed.provider}${singlesingle.sosmed.link}` }
                            //         newData.valid = true;
                            //         newData.touched = true;
                            //     }
                            // }
                        } else if (newData.element === 'selectbooleanwithbutton') {

                            if (newData.title === 'Website Sosmed') {
                                newData.options = sosmedmutate
                                if (settingdata && settingdata.length > 0) {
                                    let newFetch = sosmedmutate.filter((data) => singlesingle.websitesosmed.some(toCheck => toCheck._id === data.value));
                                    newData.value = newFetch;
                                    newData.valid = true;
                                    newData.touched = true;
                                }
                            } else if (newData.title === 'Main Sosmed') {
                                newData.options = sosmedmutate
                                if (settingdata && settingdata.length > 0) {
                                    let newFetch = sosmedmutate.filter((data) => singlesingle.mainsosmed.some(toCheck => toCheck._id === data.value));
                                    newData.value = newFetch;
                                    newData.valid = true;
                                    newData.touched = true;
                                }
                            } else {
                                newData.options = sosmedmutate
                                if (settingdata && settingdata.length > 0) {
                                    let newFetch = sosmedmutate.filter((data) => singlesingle.subsosmed.some(toCheck => toCheck._id === data.value));
                                    newData.value = newFetch;
                                    newData.valid = true;
                                    newData.touched = true;
                                }
                            }

                        } else {
                            if (settingdata && settingdata.length > 0) {
                                newData.value = singlesingle[key]
                                newData.valid = true;
                                newData.touched = true;
                            }
                        }
                        temporaryFormdata[key] = newData;
                    }
                    return temporaryFormdata;
                })
            }
        }
        fetchData().then(() => {
            loadingHandler(false)
        })
        return () => {
            mounted = false;
        }
    }, [dispatch, navigate, theUser])

    // const allURLs = async () => {
    //     loadingHandler(true)
    //     // const newadmin = await dispatch(getallClient());
    //     // alladminHandler(newadmin.data.data)
    //     loadingHandler(false)
    // }

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const updateSelectForm = (element, getdata, dataHandler) => {
        const newSelect = updateSelect(element, getdata);
        dataHandler(newSelect);
        outsideClickRef.current.closeFromOutside();
    }

    const updateSelected = (element, getdata, dataHandler) => {
        const newSelect = updateToSelected(element, getdata);
        dataHandler(newSelect);
        outsideClickRef.current.closeFromOutside();
    }

    const updateArrayForm = (element, getdata, dataHandler, allSosmed) => {
        const newSelect = updatePush(element, getdata, allSosmed);
        dataHandler(newSelect);
    }

    const removeArrayForm = (element, getdata, dataHandler, allSosmed) => {
        const newSelect = removePush(element, getdata, allSosmed);
        dataHandler(newSelect);
    }

    const updateFormSearch = (element, data, dataHandler, alldataToSearch, thekey) => {
        const newFormdata = updateSearch(element, data, formName);
        var newFormData = newFormdata;
        const temporaryFormdata = {
            ...newFormData
        }
        const newElement = {
            ...temporaryFormdata[element.id]
        }
        const results = alldataToSearch.filter(result =>
            result[thekey].toLowerCase().includes(newElement.search.toLowerCase())
        );
        newElement.options = results;
        temporaryFormdata[element.id] = newElement
        dataHandler(temporaryFormdata);
    }

    const submitForm = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let formIsValid = isFormValid(formdata, formName);

        if (formIsValid) {
            let newDataToSubmit = {
                ...dataToSubmit
            }

            // let arrayOfObjectToArray = (theArray) => {
            //     theArray.map(function (obj) {
            //         return obj.value;
            //     })
            // }
            newDataToSubmit.address = dataToSubmit['address'].value;
            newDataToSubmit.email = dataToSubmit['email'].value;
            newDataToSubmit.number = dataToSubmit['number'].value;
            newDataToSubmit.whatsapp = dataToSubmit['whatsapp'].value;

            let newWebsiteSosmed = dataToSubmit['websitesosmed'].map(function (obj) {
                return obj.value;
            })
            newDataToSubmit.websitesosmed = newWebsiteSosmed;

            let newMainSosmed = dataToSubmit['mainsosmed'].map(function (obj) {
                return obj.value;
            })
            newDataToSubmit.mainsosmed = newMainSosmed;

            let newSubSosmed = dataToSubmit['subsosmed'].map(function (obj) {
                return obj.value;
            })
            newDataToSubmit.subsosmed = newSubSosmed;

            if (theSetting && theSetting.length > 0) {
                newDataToSubmit._id = theSetting[0]._id;
            }

            const result = await dispatch(submitSetting(newDataToSubmit))
            if (result === undefined || result === 'undefined') {
                loadingHandler(false);
            }
            if (!result.data.success) {
                dispatch(global.globalError('please try again'))
                loadingHandler(false);
            }

            await dispatch(getWebsiteSetting());
            dispatch(global.globalSuccess('setting updated'))
            loadingHandler(false);
        } else {
            dispatch(global.globalError('Please check your data'))
            loadingHandler(false);
        }
    }

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                <div className='cardWrapper'>
                    <div className='cardHeader' style={{ paddingBottom: 0 }}>
                        <h5>{formName}</h5>
                    </div>
                    <div className='cardBody'>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'title'}
                                                formdata={formdata.title}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiFileText />}
                                                showName={true}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'subtitle'}
                                                formdata={formdata.subtitle}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiFileText />}
                                                showName={true}
                                            />
                                        </li>

                                    </ul>
                                </div>
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'description'}
                                                formdata={formdata.description}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiFileText />}
                                                showName={true}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'maplocation'}
                                                formdata={formdata.maplocation}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiFileText />}
                                                showName={true}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'openhourinfo'}
                                                formdata={formdata.openhourinfo}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiFileText />}
                                                showName={true}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'openhourextrainfo'}
                                                formdata={formdata.openhourextrainfo}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiFileText />}
                                                showName={true}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>

                                        <li className='inputSearch'>
                                            <FormField
                                                ref={outsideClickRef}
                                                id={'address'}
                                                formdata={formdata.address}
                                                selectForm={(element) => updateSelectForm(element, formdata, formdataHandler)}
                                                theClass="inputbutton"
                                                icons={<FaMapPin />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                ref={outsideClickRef}
                                                id={'number'}
                                                formdata={formdata.number}
                                                selectForm={(element) => updateSelectForm(element, formdata, formdataHandler)}
                                                theClass="inputbutton"
                                                icons={<FiPhone />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                ref={outsideClickRef}
                                                id={'whatsapp'}
                                                formdata={formdata.whatsapp}
                                                selectForm={(element) => updateSelectForm(element, formdata, formdataHandler)}
                                                theClass="inputbutton"
                                                icons={<FiPhone />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                ref={outsideClickRef}
                                                id={'email'}
                                                formdata={formdata.email}
                                                selectForm={(element) => updateSelectForm(element, formdata, formdataHandler)}
                                                theClass="inputbutton"
                                                icons={<FaEnvelope />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className='lumi-md-4 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                ref={outsideClickRef}
                                                id={'websitesosmed'}
                                                formdata={formdata.websitesosmed}
                                                searchChange={(element) => updateFormSearch(element, formdata, formdataHandler, allSosmed, 'name')}
                                                change={(element) => updateForm(element)}
                                                selectForm={(element) => updateSelected(element, formdata, formdataHandler)}
                                                addForm={(element) => updateArrayForm(element, formdata, formdataHandler, allSosmed)}
                                                removeForm={(element) => removeArrayForm(element, formdata, formdataHandler, allSosmed)}
                                                theClass="inputarray"
                                                icons={<FiPlus />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className='lumi-md-4 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                ref={outsideClickRef}
                                                id={'mainsosmed'}
                                                formdata={formdata.mainsosmed}
                                                searchChange={(element) => updateFormSearch(element, formdata, formdataHandler, allSosmed, 'name')}
                                                change={(element) => updateForm(element)}
                                                selectForm={(element) => updateSelected(element, formdata, formdataHandler)}
                                                addForm={(element) => updateArrayForm(element, formdata, formdataHandler, allSosmed)}
                                                removeForm={(element) => removeArrayForm(element, formdata, formdataHandler, allSosmed)}
                                                theClass="inputarray"
                                                icons={<FiPlus />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className='lumi-md-4 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                ref={outsideClickRef}
                                                id={'subsosmed'}
                                                formdata={formdata.subsosmed}
                                                searchChange={(element) => updateFormSearch(element, formdata, formdataHandler, allSosmed, 'name')}
                                                change={(element) => updateForm(element)}
                                                selectForm={(element) => updateSelected(element, formdata, formdataHandler)}
                                                addForm={(element) => updateArrayForm(element, formdata, formdataHandler, allSosmed)}
                                                removeForm={(element) => removeArrayForm(element, formdata, formdataHandler, allSosmed)}
                                                theClass="inputarray"
                                                icons={<FiPlus />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul>
                                    <li
                                        id="fullSubmit"
                                        className='iconRefresh button'
                                        onClick={
                                            loading ? null
                                                : (event) => submitForm(event)
                                        }
                                    >
                                        <div className='center formSubmit'>
                                            {
                                                loading ? <AdminLoading theTheme={theme} />
                                                    : <span><FiSend /> Submit</span>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WebsiteSettingTitle;