/////////////////////////////////////////////////////////////////////
//////////////////////////// LOADING ////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const ISLOADING = 'isloading';

/////////////////////////////////////////////////////////////////////
//////////////////////////// FILTER /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const FILTER_OPEN = 'filter_open';

/////////////////////////////////////////////////////////////////////
//////////////////////////// GLOBAL /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GLOBAL_SUCCESS = 'global_success';
export const GLOBAL_ERROR = 'global_error';
export const GLOBAL_CLEAR = 'global_clear';

/////////////////////////////////////////////////////////////////////
//////////////////////////// GALLERY ////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_GALLERY = 'get_gallery';
export const GET_GALLERY_CATEGORY = 'get_gallery_category';
export const GET_GALLERY_CATEGORY_DISTINCT = 'get_gallery_category_distinct';
export const GET_GALLERY_TAGS = 'get_gallery_tags';
export const GET_MEDIA_GALLERY = 'get_media_gallery';
export const GET_FILTERED_GALLERY = 'get_filtered_gallery';

/////////////////////////////////////////////////////////////////////
//////////////////////////// WEBSITE ////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_WEBSITE_SETTINGS = 'get_website_settings';
export const GET_WEBSITE_ADDRESS = 'get_website_address';

/////////////////////////////////////////////////////////////////////
//////////////////////////// TESTIMONI //////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_TESTIMONI = 'get_testimoni';
export const GET_TESTIMONI_CATEGORY = 'get_testimoni_category';
export const GET_TESTIMONI_CATEGORY_DISTINCT = 'get_testimoni_category_distinct';
export const GET_TESTIMONI_TAGS = 'get_testimoni_tags';
export const GET_MEDIA_TESTIMONI = 'get_media_testimoni';
export const GET_FILTERED_TESTIMONI = 'get_filtered_testimoni';

/////////////////////////////////////////////////////////////////////
//////////////////////////// SLIDER /////////////////////////////////
/////////////////////////////////////////////////////////////////////
export const GET_MEDIA_SLIDER = 'get_media_slider';
export const GET_SLIDER = 'get_slider';