import React from 'react';

export const usePagination = ({
  currentPage,
  totalCount,
  pageSize
}) => {
  const paginationRange = React.useMemo(() => {
    let divide = totalCount / pageSize;
    let percentage = (totalCount % pageSize) > 0 ? 1 : 0;
    let lastPage = parseInt(divide + percentage)
    let additional = lastPage > 2 ? 2 : 1;
    var start = Math.max(1, currentPage - 1);
    var end = Math.min(start + additional, totalCount);

    if (currentPage === lastPage) {
      end = lastPage
    }
    var x = Array.apply(null, { length: end + 1 - start }).map(function (_, idx) {
      
      return idx + start
    })
    
    return x;
  }, [currentPage, totalCount, pageSize])
  return paginationRange;
};
