import React from 'react';

const PageNotFound = (props) => {

    const { theme } = props;

    // thewebsitedomain/404

    return (
        <div style={{ height: '100%' }} id="lumisoft-maxi" className={`${theme}`}>
            <div className="row frontendlumisoftnomatch">
                <div className="lumi-md-12 lumi-xs-12">
                    <div className="lumisoftnomatchwrapper">
                        <h1 className="lumisoftnomatchtitle">404</h1>
                        <h3 className="textUppercase">Page not found!</h3>
                        <p className="lead">Seems you're looking for something that doesn't exist. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;