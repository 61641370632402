import {
    FiImage,
    FiHome,
    FiPhone
} from 'react-icons/fi'

export const mobileSize = {
    width: 767.98
}

export const tabletSize = {
    minWidth: 767.98,
    minHeight: 1025.98
}

export const isBiggerTablet = {
    minWidth: 1025.99,
    minHeight: 1279.98
}

export const minScroll = 10;

export const mainNav = [
    {
        name: 'Home',
        pathname: '/',
        icon: <FiHome />,
        showName: true
    },
    {
        name: 'Gallery',
        pathname: '/gallery',
        icon: <FiImage />,
        showName: true
    },
    {
        name: 'Testimony',
        pathname: '/testimony',
        icon: <FiImage />,
        showName: true
    },
    {
        name: 'Contact',
        pathname: '/contact',
        icon: <FiPhone />,
        showName: true
    }
]