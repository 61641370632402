import * as data from './data.testimoni';
import * as global from '../global/data.global';
import axios from '../api';
import axiosmultipart from '../apimultipart';

import { checkError } from '../global/function';

import {
    getAuthHeader,
    tokenName
} from '../../../views/utils/tools';

import {
    ADD_MEDIA_TESTIMONI, DELETE_MEDIA_TESTIMONI
} from '../../types/testimoni';

export const getAllMediaTestimoni = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/testimoni/getmediatestimoni');
            dispatch(data.getMediaTestimoni(request.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export async function addMediaTestimoni(dataToSubmit) {
    try {
        const formData = new FormData();
        dataToSubmit.forEach((img, i) => {
            formData.append(`image${i}`, img);
        });

        formData.append('upload_preset', process.env.REACT_APP_UPLOADPRESET);
        formData.append('cloud_name', process.env.REACT_APP_CLOUDNAME);

        const request = axiosmultipart.post(
            `/api/testimoni/addmediatestimoni`,
            formData
        ).then(response => response.data);

        return {
            type: ADD_MEDIA_TESTIMONI,
            payload: request
        }
    } catch (error) {
        return error
    }
}

export async function deleteMediaTestimoni(dataToSubmit) {
    try {
        const request = axios.post(`/api/testimoni/deletemediatestimoni`, dataToSubmit).then(response => response.data);

        return {
            type: DELETE_MEDIA_TESTIMONI,
            payload: request
        }
    } catch (error) {
        return error
    }
}

export const getTestimoniFunction = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/testimoni/gettestimoni');
            dispatch(data.getTestimoni(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addTestimoniFunction = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/testimoni/addtestimoni`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addTestimoni(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateTestimoniFunction = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/testimoni/updatetestimoni`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateTestimoni(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getTestimoniCategory = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/testimoni/gettestimonicategory');
            dispatch(data.getTestimoniCategory(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addTestimoniCategory = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/testimoni/addtestimonicategory`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addTestimoniCategory(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateTestimoniCategory = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/testimoni/updatetestimonicategory`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateTestimoniCategory(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getTestimoniTags = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/testimoni/gettestimonitags');
            dispatch(data.getTestimoniTags(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addTestimoniTags = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/testimoni/addtestimonitags`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addTestimoniTags(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateTestimoniTags = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/testimoni/updatetestimonitags`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateTestimoniTags(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}