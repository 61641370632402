import {
    GET_TESTIMONI,
    GET_TESTIMONI_CATEGORY,
    GET_TESTIMONI_CATEGORY_DISTINCT,
    GET_TESTIMONI_TAGS,
    GET_MEDIA_TESTIMONI,
    GET_FILTERED_TESTIMONI
} from '../../types/frontend/types';

export default function testimoniReducer(state = {}, action) {
    switch (action.type) {
        case GET_FILTERED_TESTIMONI:
            return {
                ...state,
                filteredTestimoni: action.payload.newtestimoni,
                testimoniLength: action.payload.testimonilength,
            };
        case GET_MEDIA_TESTIMONI:
            return {
                ...state,
                getMediaTestimoni: action.payload
            }
        case GET_TESTIMONI:
            return {
                ...state,
                getTestimoni: action.payload
            }
        case GET_TESTIMONI_CATEGORY:
            return {
                ...state,
                getTestimoniCategory: action.payload
            }
        case GET_TESTIMONI_CATEGORY_DISTINCT:
            return {
                ...state,
                getTestimoniCategoryDistinct: action.payload
            }
        case GET_TESTIMONI_TAGS:
            return {
                ...state,
                getTestimoniTags: action.payload
            }
        default:
            return state;
    }
}