import React from 'react';

import {
    Routes,
    Route,
    useLocation,
    Navigate
} from "react-router-dom";

import PublicRoute from './views/hoc/publicRoute';
import PrivateRoute from './views/hoc/privateRoute';
import NoRoute from './views/authentication/404';

import LoginPage from './views/authentication/login';
import SignOutScreen from './views/authentication/logout';
import WelcomeScreen from './views/authentication/welcome';
import PinScreen from './views/authentication/pin';

import DashboardPage from './views/privateRoutes/dashboard';
import NotificationPage from './views/privateRoutes/notification';
import MediaPage from './views/privateRoutes/media';
import ProfilePage from './views/privateRoutes/profile';
import TicketPage from './views/privateRoutes/ticket';

import AdminPage from './views/privateRoutes/admin';
import AdminMainPage from './views/privateRoutes/admin/dashboard';
import AdminAddPage from './views/privateRoutes/admin/add';
import AdminDetailsPage from './views/privateRoutes/admin/details';

import WebsiteAddressPage from './views/privateRoutes/websiteaddress';
import WebsiteAddressDashboard from './views/privateRoutes/websiteaddress/address';
import WebsiteAddressAdd from './views/privateRoutes/websiteaddress/addaddress';
import WebsiteAddressDetails from './views/privateRoutes/websiteaddress/details';

import WebsiteSettingPage from './views/privateRoutes/websitesettings';
import WebsiteSettingTitle from './views/privateRoutes/websitesettings/title';

import WebsiteEmail from './views/privateRoutes/websiteemail';
import WebsiteEmailDashboard from './views/privateRoutes/websiteemail/email';
import WebsiteEmailAdd from './views/privateRoutes/websiteemail/addemail';
import WebsiteEmailDetails from './views/privateRoutes/websiteemail/details';

import WebsiteNumber from './views/privateRoutes/websitenumber';
import WebsiteNumberDashboard from './views/privateRoutes/websitenumber/number';
import WebsiteNumberAdd from './views/privateRoutes/websitenumber/addnumber';
import WebsiteNumberDetails from './views/privateRoutes/websitenumber/details';

import WebsiteSosmed from './views/privateRoutes/websitesosmed';
import WebsiteSosmedDashboard from './views/privateRoutes/websitesosmed/sosmed';
import WebsiteSosmedAdd from './views/privateRoutes/websitesosmed/addsosmed';
import WebsiteSosmedDetails from './views/privateRoutes/websitesosmed/details';

import GalleryPage from './views/privateRoutes/gallery';
import GalleryDashboard from './views/privateRoutes/gallery/dashboard';
import GalleryAddPage from './views/privateRoutes/gallery/addgallery';
import GalleryEditPage from './views/privateRoutes/gallery/editgallery';

import TestimoniPage from './views/privateRoutes/testimoni';
import TestimoniDashboard from './views/privateRoutes/testimoni/dashboard';
import TestimoniAddPage from './views/privateRoutes/testimoni/addtestimoni';
import TestimoniEditPage from './views/privateRoutes/testimoni/edittestimoni';

import SliderPage from './views/privateRoutes/slider';
import SliderDashboard from './views/privateRoutes/slider/dashboard';
import SliderAddPage from './views/privateRoutes/slider/addslider';
import SliderEditPage from './views/privateRoutes/slider/editslider';

// FRONTEND WEBSITE
import WebsiteLayout from './websites/lumimaxi/hoc/layout';
import HomePage from './websites/lumimaxi/homepage';
import WebsiteGalleryPage from './websites/lumimaxi/gallerypage';
import WebsiteTestimoniPage from './websites/lumimaxi/testimonipage';
import SingleGallery from './websites/lumimaxi/gallerypage/singlepage';
import SingleTestimoni from './websites/lumimaxi/testimonipage/singlepage';
import WebsiteNoRoute from './websites/lumimaxi/404';
import ContactPage from './websites/lumimaxi/contactpage';

const TheRoutes = ({ theme, themeHandler }) => {

    const location = useLocation();

    const [displayLocation, displayLocationHandler] = React.useState(location);
    const [transitionStage, transitionStageHandler] = React.useState('fadeIn');

    React.useEffect(() => {
        if (location !== displayLocation) displayLocationHandler('fadeOut');
    }, [location, displayLocation]);

    return (
        <div
            className={`clienttransition ${transitionStage}`}
            onAnimationEnd={() => {
                if (transitionStage === "fadeOut") {
                    transitionStageHandler("fadeIn");
                    displayLocationHandler(location);
                }
            }}
        >
            <Routes>
                <Route
                    path={"/"}
                    element={
                        <WebsiteLayout
                            theme={theme}
                            themeHandler={themeHandler}
                        />
                    }>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} element={<HomePage theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/gallery`} element={<WebsiteGalleryPage theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/gallery/:id`} element={<SingleGallery theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/testimony`} element={<WebsiteTestimoniPage theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/testimony/:id`} element={<SingleTestimoni theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} element={<ContactPage theme={theme} />} />
                    <Route
                        path={`${process.env.PUBLIC_URL}/404`}
                        element={<WebsiteNoRoute theme={theme} />}
                    />
                    <Route
                        path="/*"
                        element={<Navigate replace to="/404" />}
                    />
                </Route>
                <Route
                    path={'private'}
                    element={
                        <PrivateRoute
                            theme={theme}
                            themeHandler={themeHandler}
                        />
                    }>
                    <Route exact index element={<DashboardPage theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/private/notification`} element={<NotificationPage theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/private/profile`} element={<ProfilePage theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/private/media`} element={<MediaPage theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/private/ticket`} element={<TicketPage theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/private/gallery`} element={<GalleryPage theme={theme} />}>
                        <Route exact path={`${process.env.PUBLIC_URL}/private/gallery/dashboard`} element={<GalleryDashboard theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/gallery/add`} element={<GalleryAddPage theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/gallery/:id`} element={<GalleryEditPage theme={theme} />} />
                    </Route>
                    <Route exact path={`${process.env.PUBLIC_URL}/private/testimoni`} element={<TestimoniPage theme={theme} />}>
                        <Route exact path={`${process.env.PUBLIC_URL}/private/testimoni/dashboard`} element={<TestimoniDashboard theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/testimoni/add`} element={<TestimoniAddPage theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/testimoni/:id`} element={<TestimoniEditPage theme={theme} />} />
                    </Route>
                    <Route exact path={`${process.env.PUBLIC_URL}/private/slider`} element={<SliderPage theme={theme} />}>
                        <Route exact path={`${process.env.PUBLIC_URL}/private/slider/dashboard`} element={<SliderDashboard theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/slider/add`} element={<SliderAddPage theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/slider/:id`} element={<SliderEditPage theme={theme} />} />
                    </Route>
                    <Route exact path={`${process.env.PUBLIC_URL}/private/website`} element={<WebsiteSettingPage theme={theme} />}>
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/setting`} element={<WebsiteSettingTitle theme={theme} />} />
                    </Route>
                    <Route exact path={`${process.env.PUBLIC_URL}/private/website/address`} element={<WebsiteAddressPage theme={theme} />}>
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/address/dashboard`} element={<WebsiteAddressDashboard theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/address/add`} element={<WebsiteAddressAdd theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/address/:id`} element={<WebsiteAddressDetails theme={theme} />} />
                    </Route>
                    <Route exact path={`${process.env.PUBLIC_URL}/private/website/email`} element={<WebsiteEmail theme={theme} />}>
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/email/dashboard`} element={<WebsiteEmailDashboard theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/email/add`} element={<WebsiteEmailAdd theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/email/:id`} element={<WebsiteEmailDetails theme={theme} />} />
                    </Route>
                    <Route exact path={`${process.env.PUBLIC_URL}/private/website/number`} element={<WebsiteNumber theme={theme} />}>
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/number/dashboard`} element={<WebsiteNumberDashboard theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/number/add`} element={<WebsiteNumberAdd theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/number/:id`} element={<WebsiteNumberDetails theme={theme} />} />
                    </Route>
                    <Route exact path={`${process.env.PUBLIC_URL}/private/website/sosmed`} element={<WebsiteSosmed theme={theme} />}>
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/sosmed/dashboard`} element={<WebsiteSosmedDashboard theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/sosmed/add`} element={<WebsiteSosmedAdd theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/website/sosmed/:id`} element={<WebsiteSosmedDetails theme={theme} />} />
                    </Route>
                    <Route exact path={`${process.env.PUBLIC_URL}/private/admin`} element={<AdminPage theme={theme} />}>
                        <Route exact path={`${process.env.PUBLIC_URL}/private/admin/dashboard`} element={<AdminMainPage theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/admin/add`} element={<AdminAddPage theme={theme} />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/private/admin/:id`} element={<AdminDetailsPage theme={theme} />} />
                    </Route>
                </Route>
                <Route
                    path="auth"
                    element={<PublicRoute theme={theme} />}>
                    <Route exact path={`${process.env.PUBLIC_URL}/auth/pin`} element={<PinScreen theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/auth/welcome`} element={<WelcomeScreen theme={theme} />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/auth/login`} element={<LoginPage theme={theme} />} />
                </Route>
                <Route exact path={`${process.env.PUBLIC_URL}/logout`} element={<SignOutScreen theme={theme} />} />
                <Route
                    path="404"
                    element={<NoRoute theme={theme} />}
                />
                <Route
                    path="*"
                    element={<Navigate to="/404" replace />}
                />
            </Routes>
        </div>
    )
}

export default TheRoutes;