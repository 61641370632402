import {
    GET_MEDIA
} from "../types/media";

export default function mediaReducer(state = {}, action) {
    switch (action.type) {
        case GET_MEDIA:
            return {
                ...state,
                getAllMedia: action.payload
            }
        default:
            return state;
    }
}