import React from 'react';

const UploadCardScreen = ({
    srcimg,
    altimg,
    mediaimg,
    index,
    first,
    last,
    isMobile
}) => {

    function fn(text, count) {
        return text.slice(0, count);
        // return text.slice(0, count) + (text.length > count ? "..." : "");
    }

    let totalText = isMobile ? 11 : 25;

    return (
        <div
            className={`lumi-md-2 lumi-sm-4 lumi-lg-3 lumi-xlg-3 lumi-xs-6 imgCardWrapper ${first ? 'firstimage' : ''} ${last ? 'lastimage' : ''}`}
            key={index}
        >
            <div className='imgCardInnerWrapper'>
                <div className="imgCard">
                    <img
                        src={srcimg}
                        alt={altimg}
                    />
                </div>
                <div className='imgText'>{fn(mediaimg.name, totalText)} ...</div>
            </div>
        </div>
    )
}

export default UploadCardScreen;