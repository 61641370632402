import React from 'react';
import Carousel from './carousel';

const SliderSection = (props) => {
    const sliderDetails = props.slider && props.slider.length > 0 ? props.slider : 0;
    return (
        <div id="frontendslider" style={{ position: 'relative' }}>
            <Carousel isMobile={props.isMobile} isTablet={props.isTablet} slider={sliderDetails} />
        </div>
    );
};

export default SliderSection;