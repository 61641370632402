import { combineReducers } from 'redux';
import auth from './reducers/auth';
import client from './reducers/client';
import global from './reducers/global';
import media from './reducers/media';
import website from './reducers/website';
import gallery from './reducers/gallery';
import testimoni from './reducers/testimoni';
import slider from './reducers/slider';

// FRONTEND

import loadingfront from './reducers/frontend/loading_reducer';
import globalfront from './reducers/frontend/global';
import galleryfront from './reducers/frontend/gallery';
import websitefront from './reducers/frontend/website';
import testimonifront from './reducers/frontend/testimoni';
import sliderfront from './reducers/frontend/slider';
import filterfront from './reducers/frontend/filter';

const rootReducer = combineReducers({
    auth,
    client,
    global,
    media,
    website,
    gallery,
    testimoni,
    slider,
    loadingfront,
    globalfront,
    galleryfront,
    websitefront,
    testimonifront,
    sliderfront,
    filterfront
})

export default rootReducer;