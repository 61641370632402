import React from 'react';
import { useDispatch } from 'react-redux';

import Loading from '../utils/loading';
import { clientSignOut } from '../../store/actions/auth';
import { useNavigate } from 'react-router-dom';
import { clientGetToken } from '../utils/tools';

const SignOutScreen = (props) => {

    const { theme } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const auth = clientGetToken();
    
    React.useEffect(() => {
        let mounted = true;
        async function signOutFunction() {
            if (mounted && auth !== '') {
                dispatch(clientSignOut());
                setTimeout(() => {
                    navigate('/auth/login');
                }, 3000)
            } else {
                setTimeout(() => {
                    navigate('/auth/login');
                }, 2000)
            }
        }
        signOutFunction()
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate, auth])

    return (
        <Loading
            theme={theme}
        />
    );

}

export default SignOutScreen;