import React from 'react';
import {
    useDispatch
} from 'react-redux';
import {
    useNavigate
} from "react-router-dom";

import PinForm from './pinForm';

import { clientIsAuth } from '../../store/actions/auth';

import { FiLogOut } from 'react-icons/fi';

import CircleLoading from '../utils/circle.loading';

const PinScreen = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, loadingHandler] = React.useState(true);
    const [data, dataHandler] = React.useState({});

    const { theme } = props;

    const logOutFunction = () => {
        navigate('/logout')
    }

    const logoURL = theme === 'dark' ? "/logo192.png" : "/lumisoft.png";

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(clientIsAuth())
            }
        }
        fetchData().then(async (response) => {
            if (response && response.data.success) {
                let theData = response.data.data;
                dataHandler(response.data);
                if (theData.isPin && theData.isFirstTime) {
                    navigate('/auth/welcome')
                }
                if (theData.isPin && !theData.isFirstTime) {
                    navigate('/private')
                }
                setTimeout(() => {
                    loadingHandler(false)
                }, 1000)
            } else {
                navigate('/auth/login')
                setTimeout(() => {
                    loadingHandler(false)
                }, 1000)
            }
            
        })
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate]);

    if (loading) {
        return (
            <div style={{
                paddingTop: 78,
                paddingBottom: 80
            }}>
                <CircleLoading />
            </div>
        )
    }

    return (
        <div className='loginInnerForm'>
            <div className='details'>
                <img
                    src={window.location.origin + logoURL}
                    alt=""
                    width="100px"
                />
                <h3>Please input your pin</h3>

                {
                    data && data.success ?
                        <div className='logoutIcon mb30' onClick={() => logOutFunction()}>
                            <div className='logoutContainer'>
                                {
                                    data.email
                                }
                            </div>
                            <div className='logoutContainer'>
                                <div className='logoutButton'>
                                    <FiLogOut />
                                </div>
                                <div>
                                    Logout
                                </div>
                            </div>
                        </div>
                        : null
                }
                <PinForm
                    {...props}
                    client={data}
                />

            </div>
            <small>
                Please input your new 6 digits pin
            </small>
            <small className='mt20'>
                Dont lose this pin because this will help you in your authentication
            </small>
        </div>
    );

}

export default PinScreen;