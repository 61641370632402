import React, {
    forwardRef
} from 'react';

import { useDispatch } from 'react-redux';

import ImageWrapper from './imagewrapper';
import UploadWrapper from './uploadwrapper';

import { cropSizeSetting } from '../tools';

import {
    base64StringtoFile,
    extractImageFileExtensionFromBase64
} from './reusableutils';

import * as global from '../../../store/actions/global/data.global';

import { mobile, tablet, bigTablet, isPC } from '../globalsize';
import {
    IoImages,
    IoTrash,
    IoArrowUndo,
    IoCloudUpload,
    IoCrop,
    IoCloseCircle,
    IoCheckmarkCircle
} from "react-icons/io5";

import LumiCrop, {
    centerCrop,
    makeAspectCrop,
    // Crop,
    // PixelCrop,
    // convertToPixelCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import MediaLoading from './medialoading';

const ImageMain = forwardRef(({
    media,
    filternumber = 0,
    mediaHandler,
    size,
    theme,
    uploadmultiple,
    enablemultiple,
    picked,
    pickedHandler,
    loadingupload,
    loadinguploadHandler,
    loadingdelete,
    loadingdeleteHandler,
    addFunction,
    deleteFunction,
    getFunction,
    allowedCropSize = []
}, ref) => {

    const dispatch = useDispatch();

    const isMobile = mobile(size);
    const isTablet = tablet(size);
    const isBiggerTablet = bigTablet(size);
    const isBigPC = isPC(size);

    const imgRef = React.useRef(null);
    const mainImgRef = React.useRef(null);
    const mainFileInputRef = React.useRef(null);
    const mainPreviewCanvasRef = React.useRef(null);
    const [crop, setCrop] = React.useState({
        unit: '%',
        width: 100
    });

    // const [scale, scaleHandler] = React.useState(0);
    // const [rotate, rotateHandler] = React.useState(0);
    const [aspect, aspectHandler] = React.useState(16 / 9);

    React.useEffect(() => {
        if(allowedCropSize && allowedCropSize.length > 0) {
            aspectHandler(allowedCropSize[0])
        }
    }, [allowedCropSize])

    const [currentImage, currentImageHandler] = React.useState(0);
    const [completedCrop, setCompletedCrop] = React.useState(null);

    const [urlimage, urlimageHandler] = React.useState([]);
    const [imageToUpload, imageToUploadHandler] = React.useState([]);
    const [imgSrc, imgSrcHandler] = React.useState([]);
    const [imgBulk, imgBulkHandler] = React.useState([]);

    const [cropconfirm, cropconfirmHandler] = React.useState(false);
    const [deleteconfirm, deleteconfirmHandler] = React.useState(false);

    // const [media, mediaHandler] = React.useState([]);

    // const [loadingupload, loadinguploadHandler] = React.useState(false);
    // const [loadingdelete, loadingdeleteHandler] = React.useState(false);

    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    function generateImage(imgRef, mainPreviewCanvasRef, completedCrop, data) {
        const image = imgRef;
        const canvas = mainPreviewCanvasRef;
        const crop = completedCrop;

        const ctx = canvas.getContext('2d');

        if (!ctx) {
            throw new Error('No 2d context')
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // const pixelRatio = window.devicePixelRatio;
        const pixelRatio = 2;
        // const pixelRatio = window.devicePixelRatio
        // console.log(pixelRatio, "<<<pikxel")

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        // ctx.scale(2,2);
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const base64Image = canvas.toDataURL('image/jpeg', 1.0);
        var today = new Date();
        let newDate = today.getTime();
        const myFilename = `${newDate}.` + data.ext;
        urlimageHandler(prevState => [...prevState, {
            url: base64Image,
            ext: data.ext,
            name: data.name,
            altname: myFilename
        }]);
        const imageData64 = canvas.toDataURL('image/' + data.ext);
        const myNewCroppedFile = base64StringtoFile(imageData64, data.name);
        imageToUploadHandler(imagePrev => [...imagePrev, myNewCroppedFile]);
        let dataCount = imgSrc && imgSrc.length;
        let realDataCount = dataCount - 1;
        if (realDataCount !== currentImage) {
            currentImageHandler(currentImage + 1)
        }
        if (realDataCount === currentImage) {
            cropconfirmHandler(true);
        }
    }

    const onLoad = React.useCallback((img) => {
        mainImgRef.current = img;
    }, []);

    const imageMaxSize = 1000000000 // bytes
    const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
    const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

    // React.useEffect(() => {
    //     let mounted = true;
    //     const fetchData = async () => {
    //         if (mounted) {
    //             return dispatch(getFunction())
    //         }
    //     }
    //     fetchData().then(async (response) => {
    //         if (response && response.data.success) {
    //             let theData = response.data.data;
    //             mediaHandler(theData);
    //         }
    //     })
    // }, [dispatch])

    // useDebounceEffect(
    //     async () => {
    //         if (
    //             completedCrop?.width &&
    //             completedCrop?.height &&
    //             imgRef.current &&
    //             mainPreviewCanvasRef.current
    //         ) {
    //             // We use canvasPreview as it's much faster than imgPreview.
    //             canvasPreview(
    //                 imgRef.current,
    //                 mainPreviewCanvasRef.current,
    //                 completedCrop,
    //                 scale,
    //                 rotate,
    //             )
    //         }
    //     },
    //     100,
    //     [completedCrop, scale, rotate],
    // )

    // function handleToggleAspectClick() {
    //     if (aspect) {
    //         setAspect(undefined)
    //     } else {
    //         setAspect(16 / 9)

    //         if (imgRef.current) {
    //             const { width, height } = imgRef.current
    //             const newCrop = centerAspectCrop(width, height, 16 / 9)
    //             setCrop(newCrop)
    //             // Updates the preview
    //             setCompletedCrop(convertToPixelCrop(newCrop, width, height))
    //         }
    //     }
    // }

    const handleClearToDefault = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const canvas = mainPreviewCanvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        imgSrcHandler([]);
        imgBulkHandler([]);
        mainFileInputRef.current.value = null;
    }

    const handleAllClear = () => {
        imgSrcHandler([]);
        imgBulkHandler([]);
        urlimageHandler([]);
        imageToUploadHandler([]);
        currentImageHandler(0);
        cropconfirmHandler(false);
        mainFileInputRef.current.value = null;
    }

    const handleAllClearToDefault = (event) => {
        event.preventDefault();
        event.stopPropagation();

        pickedHandler([]);
        handleAllClear();
    }

    async function checkData(file) {
        const currentFileType = file.type;
        const currentFileSize = file.size;
        if (currentFileSize > imageMaxSize) {
            dispatch(global.globalError(`Image too large: ${currentFileSize}`))
            return false
        }
        if (!acceptedFileTypesArray.includes(currentFileType)) {
            dispatch(global.globalError(`Please upload images only`))
            return false
        }
        return true
    }

    const verifyFile = async (files) => {
        const promise = await Promise.all([...files].map(checkData));
        const allEqual = promise.every(val => val === true);
        return allEqual
    }

    async function calculateData(file) {
        return new Promise((resolve, reject) => {
            let myFileItemReader = new FileReader();

            myFileItemReader.onload = function () {
                resolve({
                    url: myFileItemReader.result,
                    ext: extractImageFileExtensionFromBase64(myFileItemReader.result),
                    name: file.name
                });
            }

            myFileItemReader.onerror = function () {
                reject(myFileItemReader)
            }

            myFileItemReader.readAsDataURL(file);

        })

        // myFileItemReader.addEventListener("load", () => {
        //     const myResult = myFileItemReader.result
        //     imgSrcHandler(myResult);
        //     imgNameHandler(file.name);
        //     imgSrcExtHandler(extractImageFileExtensionFromBase64(myResult));
        // }, false)

        // myFileItemReader.readAsDataURL(file);
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function setWidthAndAspect(aspect) {
        const theImage = imgRef.current;
        const theHeight = theImage?.clientHeight;
        const theWidth = theImage?.clientWidth;
        setCrop(centerAspectCrop(theWidth, theHeight, aspect))
        aspectHandler(aspect);
    }

    const onActionHandler = async (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const isVerified = await verifyFile(files)
            if (isVerified) {
                setCrop(undefined);
                const promise = await Promise.all([...files].map(calculateData));
                imgSrcHandler(promise);
            }
        }
    }

    const onBulkActionHandler = async (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const isVerified = await verifyFile(files)
            if (isVerified) {
                const promise = await Promise.all([...files].map(calculateData));
                imgBulkHandler(promise);
            }
        }
    }

    const openBulkFile = (event) => {
        event.preventDefault();
        event.stopPropagation();
        urlimageHandler([]);
        imageToUploadHandler([]);
        currentImageHandler(0);
        document.getElementById("bulkFileDropZone").click();
    }

    const openFile = (event) => {
        event.preventDefault();
        event.stopPropagation();
        urlimageHandler([]);
        imageToUploadHandler([]);
        currentImageHandler(0);
        document.getElementById("fileDropZone").click();
    }

    const clearSelected = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingdeleteHandler(true);

        if (picked && picked.length > 0) {
            dispatch(deleteFunction(picked)).then(response => {
                if (response.payload.success) {
                    setTimeout(async () => {
                        async function fetchData() {
                            try {
                                let getMedia = await dispatch(getFunction());
                                if (getMedia.status === 200) {
                                    let theData = getMedia.data.data
                                    mediaHandler(theData);
                                    imgSrcHandler([]);
                                    imgBulkHandler([]);
                                    urlimageHandler([]);
                                    imageToUploadHandler([]);
                                    currentImageHandler(0);
                                    cropconfirmHandler(false);
                                    pickedHandler([]);
                                    deleteconfirmHandler(false);
                                    loadingdeleteHandler(false);
                                    dispatch(global.globalSuccess('Success, delete image'))
                                } else {
                                    dispatch(global.globalError('Failed, please try again'))
                                }
                            } catch (error) {
                                // console.log(error, '<<<<<error')
                                dispatch(global.globalError(error.message))
                            }
                        }
                        await fetchData();
                    }, 2000)
                } else {
                    loadingdeleteHandler(false);
                    dispatch(global.globalError(response.payload.message))
                }
            })
        } else {
            dispatch(global.globalError('Please pick one image'))
        }
    }

    const deleteConfirm = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        deleteconfirmHandler(true);
    }

    const cancelDelete = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        deleteconfirmHandler(false);
    }

    const uploadFile = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadinguploadHandler(true);

        if (imgBulk && imgBulk.length > 0) {
            async function changeToFile(data) {
                return new Promise((resolve, reject) => {
                    const myNewCroppedFile = base64StringtoFile(data.url, data.name);
                    resolve(myNewCroppedFile);
                })
            }

            const bulkpromise = await Promise.all(imgBulk.map(changeToFile))

            dispatch(addFunction(bulkpromise)).then(response => {
                if (response.payload.success) {
                    setTimeout(async () => {
                        async function fetchData() {
                            try {
                                let getMedia = await dispatch(getFunction());
                                if (getMedia.status === 200) {
                                    let theData = getMedia.data.data
                                    let mediaFiltered = [];
                                    if (filternumber !== 0) {
                                        mediaFiltered = theData.filter((single) => Math.round(single.width / single.height) === filternumber);
                                    } else {
                                        mediaFiltered = theData
                                    }
                                    mediaHandler(mediaFiltered);
                                    handleAllClear()
                                    loadinguploadHandler(false);
                                    dispatch(global.globalSuccess('Success, add image'))
                                } else {
                                    dispatch(global.globalError('Failed, please try again'))
                                }
                            } catch (error) {
                                dispatch(global.globalError(error.message))
                            }
                        }
                        await fetchData();
                    }, 2000)
                } else {
                    loadinguploadHandler(false);
                    dispatch(global.globalError(response.payload.message))
                }
            })
        } else if (imageToUpload && imageToUpload.length > 0) {
            dispatch(addFunction(imageToUpload)).then(response => {
                if (response.payload.success) {
                    setTimeout(async () => {
                        async function fetchData() {
                            try {
                                let getMedia = await dispatch(getFunction());
                                if (getMedia.status === 200) {
                                    let theData = getMedia.data.data
                                    let mediaFiltered = [];
                                    if (filternumber !== 0) {
                                        mediaFiltered = theData.filter((single) => Math.round(single.width / single.height) === filternumber);
                                    } else {
                                        mediaFiltered = theData
                                    }
                                    mediaHandler(mediaFiltered);
                                    handleAllClear()
                                    loadinguploadHandler(false);
                                    dispatch(global.globalSuccess('Success, uploading image'))
                                } else {
                                    dispatch(global.globalError('Failed, please try again'))
                                }
                            } catch (error) {
                                dispatch(global.globalError(error.message))
                            }
                        }
                        await fetchData();
                    }, 2000)
                } else {
                    loadinguploadHandler(false);
                    dispatch(global.globalError(response.payload.message))
                }
            })
        } else {
            loadinguploadHandler(false);
            dispatch(global.globalError('Please try again!'))
        }
    }

    React.useImperativeHandle(ref, () => {
        return {
            clearData() {
                imgSrcHandler([]);
                imgBulkHandler([]);
                urlimageHandler([]);
                currentImageHandler(0);
                cropconfirmHandler(false);
                loadinguploadHandler(false);
                mainFileInputRef.current.value = null;
            }
        }
    });

    const checkValue = (allowedCropSize, cropSizeSetting) => {

        let sameValue = cropSizeSetting.filter(item => allowedCropSize.some(single => single.toFixed(2) === item.value.toFixed(2)));
        return sameValue.map((theone, index) => (
            <div
                className="prevImgButton"
                onClick={() => setWidthAndAspect(theone.value)}
                key={index}
            >
                {theone.name}
            </div>
        ))
    }

    const ShowCropButton = ({
        allowedCropSize,
        cropSizeSetting
    }) => {
        if (allowedCropSize && allowedCropSize.length > 0) {
            return (
                <div
                    className='theRow'
                >
                    {
                        checkValue(allowedCropSize, cropSizeSetting)
                    }
                </div>
            )
        } else {
            return (
                <div
                    className='theRow'
                >
                    {
                        cropSizeSetting.map((single, index) => (
                            <div
                                className="prevImgButton"
                                onClick={() => setWidthAndAspect(single.value)}
                                key={index}
                            >
                                {single.name}
                            </div>
                        ))

                    }
                </div>
            )
        }
    }

    const showCropButtonMobile = async () => {

    }

    return (
        <div className='cardWrapper body'>
            {
                loadingdelete ? null :
                    picked && picked.length > 0 && deleteconfirm ?
                        <div className='dashboardBody'>
                            <div className='row width100p'>
                                <div className='lumi-md-12 lumi-xs-12'>
                                    <div
                                        className={`mainImageScreen ${theme}`}
                                        style={{
                                            height: isMobile ? size.height * 95 / 100 : size.height * 65 / 100,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <div
                                            className="mediaLibraryWrapper"
                                        >
                                            <div className="mediaLibraryTitle">
                                                Delete {picked && picked.length} images, are you sure?
                                            </div>
                                        </div>
                                        <div className="mediaLibraryButtonWrapper">
                                            <div className="mediaLibraryButton red"
                                                onClick={(event) => cancelDelete(event)}
                                            >
                                                <IoCloseCircle
                                                    className="icon agraicon"
                                                />
                                            </div>
                                            <div className="mediaLibraryButton green"
                                                style={{
                                                    marginRight: media && media.length > 0 ? 20 : 0
                                                }}
                                                onClick={(event) => clearSelected(event)}
                                            >
                                                <IoCheckmarkCircle
                                                    className="icon agraicon"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
            }
            {
                loadingdelete ?
                    <div className='dashboardBody'>
                        <MediaLoading
                            theme={theme}
                            size={size}
                            isMobile={isMobile}
                            text={'Please wait, Deleting...'}
                        />
                    </div>
                    : null
            }
            {
                deleteconfirm ? null :
                    loadingdelete ? null :
                        <div className='dashboardBody'>
                            <input
                                ref={mainFileInputRef}
                                type="file"
                                accept={acceptedFileTypes}
                                multiple={uploadmultiple}
                                id="fileDropZone"
                                name="fileDropZone"
                                onChange={(event) => onActionHandler(event)}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <input
                                ref={mainFileInputRef}
                                type="file"
                                accept={acceptedFileTypes}
                                multiple={uploadmultiple}
                                id="bulkFileDropZone"
                                name="bulkFileDropZone"
                                onChange={(event) => onBulkActionHandler(event)}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <canvas
                                ref={mainPreviewCanvasRef}
                                style={{
                                    // width: Math.round(completedCrop?.width ?? 0),
                                    // height: Math.round(completedCrop?.height ?? 0)
                                    width: 0,
                                    height: 0
                                }}
                            />
                            {
                                imgSrc && imgSrc.length > 0 && !cropconfirm ?
                                    imgSrc.map((image, index) => {
                                        if (currentImage === index) {
                                            // console.log(image.url, '<<<<image')
                                            return (
                                                <div
                                                    key={index}
                                                    className='cropWrapper'
                                                >
                                                    <div className="row">
                                                        <div className="lumi-md-12 lumi-xs-12">
                                                            <div
                                                                className="showImgCropWrapper"
                                                            >
                                                                <LumiCrop
                                                                    onImageLoaded={onLoad}
                                                                    crop={crop}
                                                                    keepSelection={true}
                                                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                                                    onComplete={(c) => setCompletedCrop(c)}
                                                                    aspect={aspect}
                                                                >
                                                                    <img
                                                                        ref={imgRef}
                                                                        src={image.url}
                                                                        alt={image.name}
                                                                        style={{
                                                                            // transform: `scale(${scale}) rotate(${rotate}deg)`,
                                                                            maxHeight: isMobile ? size.width : size.height * 70 / 100,
                                                                            objectFit: 'cover',
                                                                            height: isMobile ? '100%' : size.height * 75 / 100
                                                                        }}
                                                                        onLoad={onImageLoad}
                                                                    />
                                                                </LumiCrop>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div
                                                        className='row'
                                                    >
                                                        <div className="lumi-md-12 lumi-xs-12">
                                                            <div
                                                                className="prevImgWrapper"
                                                            >
                                                                {
                                                                    <ShowCropButton
                                                                        allowedCropSize={allowedCropSize}
                                                                        cropSizeSetting={cropSizeSetting}
                                                                    />
                                                                    // showCropButton(allowedCropSize, cropSizeSetting)
                                                                }
                                                                {/* {
                                                                    isMobile ? showCropButtonMobile(allowedCropSize) :
                                                                        showCropButton(allowedCropSize)
                                                                } */}
                                                                {/* <div
                                                                    className='theRow'
                                                                >

                                                                    <div
                                                                        className="prevImgButton"
                                                                        onClick={() => setWidthAndAspect(4 / 1)}
                                                                    >
                                                                        4 : 1
                                                                    </div>
                                                                    <div
                                                                        className="prevImgButton"
                                                                        onClick={() => setWidthAndAspect(4 / 1.5)}
                                                                    >
                                                                        4 : 1.5
                                                                    </div>
                                                                    <div
                                                                        className="prevImgButton"
                                                                        onClick={() => setWidthAndAspect(4 / 3)}
                                                                    >
                                                                        4 : 3
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className='theRow'
                                                                >
                                                                    <div
                                                                        className="prevImgButton"
                                                                        onClick={() => setWidthAndAspect(4 / 2)}
                                                                    >
                                                                        4 : 2
                                                                    </div>
                                                                    <div
                                                                        className="prevImgButton"
                                                                        onClick={() => setWidthAndAspect(2 / 4)}
                                                                    >
                                                                        2 : 4
                                                                    </div>
                                                                    <div
                                                                        className="prevImgButton"
                                                                        onClick={() => setWidthAndAspect(3 / 4)}
                                                                    >
                                                                        3 : 4
                                                                    </div>

                                                                </div>
                                                                <div
                                                                    className='theRow'
                                                                >

                                                                    <div
                                                                        className="prevImgButton"
                                                                        onClick={() => setWidthAndAspect(1 / 1)}
                                                                    >
                                                                        1 : 1
                                                                    </div>
                                                                    <div
                                                                        className="prevImgButton"
                                                                        onClick={() => setWidthAndAspect(9 / 16)}
                                                                    >
                                                                        9 : 16
                                                                    </div>
                                                                    <div
                                                                        className="prevImgButton"
                                                                        onClick={() => setWidthAndAspect(16 / 9)}
                                                                    >
                                                                        16 : 9
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            <div
                                                                className="prevImgWrapper"
                                                            >
                                                                <div className='columnWrapper'>
                                                                    <div className='theRow'>
                                                                        <span
                                                                            style={{
                                                                                fontSize: 14
                                                                            }}
                                                                        >
                                                                            Move crop area above before click crop button
                                                                        </span>
                                                                    </div>
                                                                    <div className='theRow'>
                                                                        <div
                                                                            className="prevImgButton"
                                                                            onClick={(event) => handleClearToDefault(event)}
                                                                        >
                                                                            Clear
                                                                        </div>
                                                                        <div
                                                                            className="nextImgButton"
                                                                            onClick={() => generateImage(imgRef.current, mainPreviewCanvasRef.current, completedCrop, image)}
                                                                        >
                                                                            Crop
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else return null
                                    })
                                    : null
                            }
                            {
                                loadingupload ? null :
                                    imgBulk && imgBulk.length > 0 ? null :
                                        imgSrc && imgSrc.length === 0 && !cropconfirm ?
                                            <div className='row width100p'>
                                                <div className='lumi-md-12 lumi-xs-12'>
                                                    <div className={`mainImageScreen ${theme}`}>
                                                        <div
                                                            className="mediaLibraryWrapper"
                                                        >
                                                            <div className="mediaLibraryTitle">
                                                                Media Library
                                                            </div>
                                                        </div>
                                                        {
                                                            picked && picked.length > 0 ?
                                                                <div className="mediaLibraryButtonWrapper">
                                                                    <div className="mediaLibraryButton"
                                                                        onClick={(event) => handleAllClearToDefault(event)}
                                                                    >
                                                                        <IoArrowUndo
                                                                            className="icon agraicon"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="mediaLibraryButton"
                                                                        onClick={(event) => deleteConfirm(event)}
                                                                    >
                                                                        <IoTrash
                                                                            className="icon agraicon"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="mediaLibraryButtonWrapper">
                                                                    <div className="mediaLibraryButton"
                                                                        onClick={(event) => openBulkFile(event)}
                                                                    >
                                                                        <IoImages
                                                                            className="icon agraicon"
                                                                        />
                                                                    </div>
                                                                    <div className="mediaLibraryButton"
                                                                        onClick={(event) => openFile(event)}
                                                                    >
                                                                        <IoCrop
                                                                            className="icon agraicon"
                                                                        />
                                                                    </div>
                                                                </div>
                                                        }

                                                        <div className='showImgWrapper'>
                                                            <ImageWrapper
                                                                title={'All Images'}
                                                                images={media}
                                                                multiple={enablemultiple}
                                                                pickedHandler={pickedHandler}
                                                                picked={picked}
                                                                isMobile={isMobile}
                                                                isTablet={isTablet}
                                                                isBiggerTablet={isBiggerTablet}
                                                                isBigPC={isBigPC}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                            }
                            {
                                loadingupload ?
                                    <MediaLoading
                                        theme={theme}
                                        size={size}
                                        isMobile={isMobile}
                                        text={'Please wait, Uploading...'}
                                    />
                                    :
                                    imgBulk && imgBulk.length > 0 ?
                                        <div className="row width100p">
                                            <div className="lumi-md-12 lumi-xs-12">
                                                <div className={`mainImageScreen ${theme}`}>
                                                    <div
                                                        className="mediaLibraryWrapper"
                                                    >
                                                        <div className="mediaLibraryTitle">
                                                            Cropped Images
                                                        </div>
                                                    </div>
                                                    <div className="mediaLibraryButtonWrapper">
                                                        <div className="mediaLibraryButton"
                                                            onClick={(event) => handleAllClearToDefault(event)}
                                                        >
                                                            <IoArrowUndo
                                                                className="icon agraicon"
                                                            />
                                                        </div>
                                                        <div className="mediaLibraryButton"
                                                            style={{
                                                                marginRight: media && media.length > 0 ? 20 : 0
                                                            }}
                                                            onClick={(event) => uploadFile(event)}
                                                        >
                                                            <IoCloudUpload
                                                                className="icon agraicon"
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="showImgWrapper">
                                                        <UploadWrapper
                                                            title={'All Images'}
                                                            images={imgBulk}
                                                            isMobile={isMobile}
                                                            isTablet={isTablet}
                                                            isBiggerTablet={isBiggerTablet}
                                                            isBigPC={isBigPC}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        urlimage && urlimage.length > 0 && cropconfirm ?
                                            <div className='row width100p'>
                                                <div className='lumi-md-12 lumi-xs-12'>
                                                    <div className={`mainImageScreen ${theme}`}>
                                                        <div
                                                            className="mediaLibraryWrapper"
                                                        >
                                                            <div className="mediaLibraryTitle">
                                                                Cropped results
                                                            </div>
                                                        </div>
                                                        <div className="mediaLibraryButtonWrapper">
                                                            <div className="mediaLibraryButton"
                                                                onClick={(event) => handleAllClearToDefault(event)}
                                                            >
                                                                <IoArrowUndo
                                                                    className="icon agraicon"
                                                                />
                                                            </div>
                                                            <div className="mediaLibraryButton"
                                                                onClick={(event) => uploadFile(event)}
                                                            >
                                                                <IoCloudUpload
                                                                    className="icon agraicon"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="showImgWrapper">
                                                            {/* <img src={urlimage ? `${urlimage}` : null} alt="" /> */}
                                                            <ImageWrapper
                                                                title={'All Images'}
                                                                images={urlimage}
                                                                multiple={enablemultiple}
                                                                pickedHandler={pickedHandler}
                                                                picked={picked}
                                                                isMobile={isMobile}
                                                                isTablet={isTablet}
                                                                isBiggerTablet={isBiggerTablet}
                                                                isBigPC={isBigPC}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                            }
                        </div>
            }
        </div>
    )
})

export default ImageMain;