import React from 'react';

import {
    useDispatch
} from 'react-redux';

import { useOutletContext } from "react-router-dom";

import { HeaderPage } from '../../utils/globalfunction';
import ImageMain from '../../utils/media/imagemain';

import { addMedia, deleteMedia, getAllMedia } from '../../../store/actions/media';

const MediaPage = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        size
    } = globaldata;

    const dispatch = useDispatch();

    const uploadmultiple = true;
    const enablemultiple = true;

    const mediaRef = React.useRef();
    const [picked, pickedHandler] = React.useState([]);
    const [media, mediaHandler] = React.useState([]);

    const [loadingupload, loadinguploadHandler] = React.useState(false);
    const [loadingdelete, loadingdeleteHandler] = React.useState(false);

    // const [formError, formErrorHandler] = React.useState(false);
    // const [errorMessage, errorMessageHandler] = React.useState('DATA INVALID, PLEASE RECHECK!');

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(getAllMedia())
            }
        }
        fetchData().then(async (response) => {
            if (response && response.data.success) {
                let theData = response.data.data;
                mediaHandler(theData);
            }
        })
    }, [dispatch])

    return (
        <div className='mainBodyWrapper mediacss'>
            {
                HeaderPage('Media Dashboard')
            }
            <ImageMain
                ref={mediaRef}
                media={media}
                mediaHandler={mediaHandler}
                size={size}
                theme={theme}
                uploadmultiple={uploadmultiple}
                enablemultiple={enablemultiple}
                picked={picked}
                pickedHandler={pickedHandler}
                loadingupload={loadingupload}
                loadinguploadHandler={loadinguploadHandler}
                loadingdelete={loadingdelete}
                loadingdeleteHandler={loadingdeleteHandler}
                addFunction={addMedia}
                deleteFunction={deleteMedia}
                getFunction={getAllMedia}
                allowedCropSize={[]}
            />
        </div >
    )
}

export default MediaPage;