import React from 'react';

import {
    useSelector
} from 'react-redux';

import { useOutletContext } from 'react-router-dom';
import { useWindowSize } from '../../../utils/windowsize';
import { mobileSize } from '../data/settings';

import CantDecideSection from '../cantdecide';

const ContactPage = (props) => {

    const { 
        theme
     } = props;

    const context = useOutletContext();
    const thedata = context && context.thedata

    const address = useSelector((state) => state.websitefront.getWebsiteAddress);

    const size = useWindowSize();
    const isMobile = size.width <= mobileSize.width;

    const logoUrl = theme === 'light' ? require('../resources/images/LuminousSposa_Logo_9.png') : require('../resources/images/LuminousSposa_Logo_12.png')
    
    return (
        <section id="gallerypage" className={`homepage ${theme}`}>
            <div className="contentwrap" style={{ transform: "none" }}>
                <div className="section dividersection" style={{ marginTop: isMobile ? '0px' : '70px' }}>
                    <div className="container">
                        <div
                            className="headingblock center mb30"
                        >
                            <h2 className='aboutTitle'>
                                Get in Touch
                            </h2>
                        </div>
                        <div className="row mb50">
                            <div className="lumi-md-12 lumi-xs-12 m-xs-0 p-xs-0">
                                <div className='addressBlock'>
                                    <span className="dot"></span>
                                    <div className="addressWrap">
                                        <img src={logoUrl} alt="" />
                                        <p>{thedata && thedata.address.address}</p>
                                        <a
                                            rel="noopener noreferrer"
                                            href={`https://www.google.com/maps/dir/?api=1&destination=${thedata && thedata.maplocation}`}
                                            target="_blank"
                                        >
                                            Google Direction
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CantDecideSection
                    isMobile={isMobile}
                />
            </div>
        </section>
    )
}

export default ContactPage;