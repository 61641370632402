import React from 'react';

const MediaLoading = ({
    theme,
    size,
    isMobile,
    text
}) => {

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                <div className={`mainImageScreen ${theme}`}>
                    <div
                        className="mediaLibraryWrapper"
                        style={{
                            height: isMobile ? size.height * 95 / 100 : size.height * 65 / 100
                        }}
                    >
                        <div className="mediaLibraryTitle">
                            {text}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MediaLoading;