import React from 'react';

const ImageCardScreen = ({
    srcimg,
    altimg,
    mediaimg,
    index,
    first,
    last,
    isSelected,
    selectedImage,
    isMobile,
    isTablet,
    isBiggerTablet,
    isBigPC
}) => {

    const [isLoading, setIsLoading] = React.useState(true);
    const [isInView, setIsInView] = React.useState(false);
    const [theHeight, heightHandler] = React.useState(0);
    const [theWidth, widthHandler] = React.useState(0);
    const root = React.useRef(); // the container

    React.useEffect(() => {
        // sets `isInView` to true until root is visible on users browser
        heightHandler(root.current.offsetWidth);
        widthHandler(root.current.offsetWidth);
        const observer = new IntersectionObserver(onIntersection, { threshold: 0 });
        observer.observe(root.current);

        function onIntersection(entries) {
            const { isIntersecting } = entries[0];

            if (isIntersecting) { // is in view
                observer.disconnect();
            }

            setIsInView(isIntersecting);
        }
    }, []);

    function onLoad() {
        if (isInView) {
            setIsLoading(false);
        } else {
            setIsLoading(true);

        }
    }

    function fn(text, count) {
        return text.slice(0, count);
        // return text.slice(0, count) + (text.length > count ? "..." : "");
    }

    let totalText = isMobile ? 11 : isTablet ? 10 : isBiggerTablet ? 14 : isBigPC ? 18 : 22;

    return (
        <div
            className={`lumi-md-2 lumi-sm-4 lumi-lg-3 lumi-xlg-3 lumi-xs-6 imgCardWrapper ${first ? 'firstimage' : ''} ${last ? 'lastimage' : ''} ${isSelected ? 'selected' : ''}`}
            key={index}
            onClick={() => selectedImage(mediaimg)}
        >
            <div className='imgCardInnerWrapper'>
                <div
                    ref={root}
                    className={`imgCard ${isLoading ? 'skeleton isLoading' : null}`}
                >
                    <div
                        className="imgLoader"
                        style={{
                            height: theHeight,
                            width: theWidth
                        }}
                    />
                    <img
                        src={isInView ? srcimg : null}
                        alt={altimg}
                        onLoad={onLoad}
                    />
                </div>
                <div className='imgText'>{fn(mediaimg.name, totalText)} ...</div>
            </div>
        </div>
    )
}

export default ImageCardScreen;