import React from 'react';
import moment from 'moment';
import {
    useNavigate
} from 'react-router-dom';

import FormField from '../../utils/form/formField';
import { FiArrowLeft, FiRotateCw, FiSearch } from 'react-icons/fi';
import AdminLoading from '../../utils/circle.loading';
import Pagination from '../media/pagination';

const GlobalTableTemplate = ({
    tableId,
    tableName,
    alldata,
    loading,
    allURLs,
    theTheme,
    datatoshow,
    pageSize,
    navigateto,
    backStatus,
    backFunction,
    isMobile
}) => {

    const navigate = useNavigate();

    const [currentPage, currentPageHandler] = React.useState(1);
    const [dataCount, dataCountHandler] = React.useState("");
    const [mydata, mydataHandler] = React.useState([]);

    const [filterData, filterDataHandler] = React.useState({
        filterText: {
            element: 'input',
            value: '',
            config: {
                name: 'Filter',
                type: 'text',
                placeholder: 'Search here'
            },
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });

    const dataToShow = React.useMemo(() => datatoshow, [datatoshow])

    let functionType = typeof allURLs;

    React.useEffect(() => {
        let mounted = true;
        const implementData = async () => {
            if (mounted) {
                if (alldata && alldata.length > 0) {
                    mydataHandler(alldata);
                }
            }
        }
        implementData();
        return () => {
            mounted = false;
        }
    }, [alldata])

    const gotoDetails = React.useCallback((data) => {
        navigate(`/${navigateto}/${data._id}`)
    }, [navigate])

    const gotoDetailsRef = React.useRef(gotoDetails);

    React.useEffect(() => {
        gotoDetailsRef.current = gotoDetails;
    }, [gotoDetails]);

    const ThisTable = React.useCallback((index, item) => {
        return (
            <tr key={index} onClick={() => gotoDetailsRef.current(item)}>
                {
                    dataToShow.map((dat, ind) => {
                        return (
                            <td key={ind}>
                                {
                                    dat.code === 'createdAt' || dat.code === 'updatedAt' ?
                                        `${moment(item[dat.code]).format('DD/MM/YYYY')}`
                                        : dat.code === 'phone' || dat.code === 'whatsapp' ?
                                            `${item[dat.code].extension + item[dat.code].phone}`
                                            : dat.code === 'phone2' ?
                                                item[dat.code] === undefined || item[dat.code] === null || item[dat.code].phone === 0 || item[dat.code].phone === "" ? 'no number'
                                                    : `${item[dat.code].extension + item[dat.code].phone}`
                                                : `${item[dat.code].length > 30 ? `${item[dat.code].substring(0, 30)}...` : item[dat.code]}`
                                }
                            </td>
                        )
                    })
                }
            </tr>
        );
    }, [dataToShow])

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            var results = [];
            var resultsdata = [];
            var offset = (currentPage - 1) * pageSize;

            const filterObject = (obj, theText) => {
                return Object.values(obj).some((value) => {
                    if (typeof value === 'object') {
                        return filterObject(value, theText); // Recursively search nested objects
                    }
                    return value.toString().toLowerCase().includes(theText.toLowerCase());
                });
            };

            results = alldata && alldata.length > 0 && alldata.filter(data => filterObject(data, filterData.filterText.value.toLowerCase()));
            // results = alldata && alldata.length > 0 && alldata.filter(data => Object.values(data).join(' ').toLowerCase().includes(filterData.filterText.value.toLowerCase()));
            resultsdata = results && results.length > 0 && results.map((item, index) => ThisTable(index, item));
            var semuadata = resultsdata ? [...resultsdata] : [];
            var mydatas = semuadata.slice(offset, offset + pageSize);
            dataCountHandler(results && results.length);
            mydataHandler(mydatas);
        }
        return () => {
            mounted = false;
        };
    }, [alldata, currentPage, filterData.filterText.value, mydataHandler, pageSize, ThisTable])


    let divide = dataCount / pageSize;
    let percentage = (dataCount % pageSize) > 0 ? 1 : 0;
    let lastPage = parseInt(divide + percentage)

    const updateForm = (element, formdata) => {
        const newFormdata = {
            ...formdata
        }
        const newElement = {
            ...newFormdata[element.id]
        }
        newElement.value = element.event.target.value;
        newFormdata[element.id] = newElement;
        filterDataHandler(newFormdata);
    }

    // const dataCountCallBack = React.useCallback((results) => {
    //     dataCountHandler(results.length);
    // }, [dataCountHandler])

    const TableHead = (data) => {

        return data.map((dat, index) => {
            return (
                <th scope='col' key={index}>{dat.name}</th>
            )
        })

    }

    return (
        <div className='cardWrapper'>
            <div className='cardHeader'>
                <h5>{tableName}</h5>
                <div className='cardOptions'>
                    <ul>
                        <li className='inputSearch'>
                            <FormField
                                id={'filterText'}
                                formdata={filterData.filterText}
                                change={(element) => updateForm(element, filterData)}
                                theClass="inputclass"
                                icons={<FiSearch />}
                                showName={false}
                            />
                        </li>
                        {
                            functionType !== 'undefined' ?
                                <li className='iconRefresh button' onClick={() => allURLs()}>
                                    <div className='center'>
                                        <span><FiRotateCw /></span>
                                    </div>
                                </li>
                                : null
                        }
                        {
                            backStatus ?
                                <li className='iconRefresh button' onClick={(event) => backFunction(event)}>
                                    <div className='center'>
                                        <span><FiArrowLeft /></span>
                                    </div>
                                </li>
                                : null
                        }
                    </ul>
                </div>
            </div>
            <div className='cardBody'>
                <div className='tableWrapper'>
                    {
                        loading ? <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', minHeight: '100px' }}><AdminLoading theTheme={theTheme} /></div> :
                            <table id={tableId} className='table tablebordernone'>
                                <thead>
                                    <tr>
                                        {
                                            TableHead(dataToShow)
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        mydata && mydata.length > 0 ? mydata :
                                            <tr>
                                                <td
                                                    colSpan={dataToShow && dataToShow.length}
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <strong>No data</strong>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                    }
                    <div className='paginationWrapper'>
                        <nav
                            className="pagination mt30"
                        >
                            <Pagination
                                totalCount={dataCount}
                                pageSize={pageSize}
                                onPageChanged={(page) => currentPageHandler(page)}
                                currentPage={currentPage}
                                width={isMobile}
                                lastPage={lastPage}
                            />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GlobalTableTemplate;