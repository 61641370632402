import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    FiBell,
    FiUser,
    // FiSun,
    // FiMoon
} from "react-icons/fi";
import { FaBars } from 'react-icons/fa';

const ClientHeader = (props) => {

    const {
        isMobile,
        theTheme,
        sideClientToggle
    } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const logoURL = theTheme === 'dark' ? "/logo192.png" : "/lumisoft.png";

    const HeaderNav = [
        {
            name: 'Notification',
            pathname: '/private/notification',
            icon: <FiBell />,
            theFunction: () => navigateFunction('/private/notification')
        }
    ]

    const ProfileNav = [
        {
            name: 'Profile',
            pathname: '/private/profile',
            icon: <FiUser />,
            theFunction: () => navigateFunction('/private/profile')
        }
    ]

    const navigateFunction = (location) => {
        navigate(location)
    }

    const headerNavFunction = (headernav, location) => {
        return headernav.map((nav, index) => {
            const sameLocation = nav.pathname === location.pathname ? 'active' : '';
            return (
                <span
                    className={sameLocation} key={index}
                    onClick={nav.theFunction}
                >
                    {nav.icon}
                </span>
            )
        })

    }

    return (
        <header className={`${theTheme}`}>
            <div className='navbar'>
                <div className='headerWrapper'>
                    <div className='logoWrapper'>
                        <div
                            className="logoImageWrapper"
                            onClick={() => navigate('/')}
                        >
                            <img
                                className="logoImage"
                                src={window.location.origin + logoURL}
                                alt=""
                                width="40px"
                            />
                            {
                                isMobile ? null
                                    : <span>lumisoft</span>
                            }

                        </div>
                        <small>innovative solution</small>
                    </div>
                    <div
                        className='sideClientWrapper'
                        onClick={() => sideClientToggle()}
                    >
                        <div className='sideClientButton'>
                            <span><FaBars /></span>
                        </div>
                    </div>
                    <div className='rightWrapper'>
                        <div className='optionsWrapper'>
                            {
                                headerNavFunction(HeaderNav, location)
                            }
                        </div>
                        <div className='profileWrapper'>
                            {
                                headerNavFunction(ProfileNav, location)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}

export default ClientHeader;