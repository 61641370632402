import React from 'react';

import { useLocation } from 'react-router-dom';

import TemplateDashboard from '../../utils/template/dashboard';
import { IoIosPin, IoIosMail, IoMdCall, IoIosContacts } from "react-icons/io";

const WebsiteSetting = (props) => {

    const location = useLocation();
    const pageNav = [
        {
            name: 'Address',
            pathname: '/private/website/address/dashboard',
            icon: <IoIosPin />,
            showName: true
        },
        {
            name: 'Email',
            pathname: '/private/website/email/dashboard',
            icon: <IoIosMail />,
            showName: true
        },
        {
            name: 'Number',
            pathname: '/private/website/number/dashboard',
            icon: <IoMdCall />,
            showName: true
        },
        {
            name: 'Social media',
            pathname: '/private/website/sosmed/dashboard',
            icon: <IoIosContacts />,
            showName: true
        }
    ]

    const pageName = 'Website Settings';

    return (
        <TemplateDashboard
            props={props}
            pageName={pageName}
            pageNav={pageNav}
            showHeader={location.pathname === '/private/website/setting' ? true : false}
        />
    )
}

export default WebsiteSetting;