import React from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';

// import { Link } from 'react-router-dom';
import { useWindowSize } from '../../../utils/windowsize';
import { mobileSize, tabletSize } from '../data/settings';

import {
    // getGalleryFunction,
    getGalleryTags,
    // getGalleryCategory,
    getFilteredGallery
} from '../../../store/actions/frontend/gallery';
import {
    filterOpen
} from '../../../store/actions/frontend/filter';
import LoadingRipple from '../loadingripple';
import FilterListPage from "../widget/filterlist";
import Pagination from '../widget/pagination';
import SubBackDrop from '../widget/backdropsub';
import { FaSlidersH } from 'react-icons/fa';
// import ShopDropdown from '../widget/dropdown';
import Card from "../widget/card";
import CantDecideSection from '../cantdecide';
// import { useDetectOutsideClick } from '../widget/detectOutsideClick';

const GalleryPage = (props) => {

    const { theme } = props;

    const dispatch = useDispatch();

    const size = useWindowSize();
    const isMobile = size.width <= mobileSize.width;
    // const isTablet = size.width >= tabletSize.minWidth && size.width <= tabletSize.minHeight;

    // const sortRef = React.useRef(null);
    // const [sortDropdown, sortDropdownHandler] = useDetectOutsideClick(
    //     sortRef,
    //     false
    // );

    const filteropen = useSelector((state) => state.filterfront.filterOpen);
    const gallery = useSelector((state) => state.galleryfront.filteredGallery);

    const [loading, loadingHandler] = React.useState(false);

    // const [gallery, galleryHandler] = React.useState([]);
    const [alldata, alldataHandler] = React.useState([]);
    const [alltags, alltagsHandler] = React.useState([]);
    // const [allcategories, allcategoriesHandler] = React.useState([]);

    const [filters, filtersHandler] = React.useState({
        tags: []
        // category: []
    });


    const pageSize = 8;
    const [currentPage, currentPageHandler] = React.useState(1);
    const [dataCount, dataCountHandler] = React.useState("");
    const pageChanged = (pageNumber, e) => {
        e.preventDefault();
        currentPageHandler(pageNumber);
    };

    const nodata = alldata && alldata.length > 0 ? false : true;

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(getFilteredGallery(filters))
            }
        }
        fetchData().then(async (response) => {
            if (response && response.payload.success) {
                // let theData = response.payload.newgallery;
                // galleryHandler(theData);
                let theTags = await dispatch(getGalleryTags())
                alltagsHandler(theTags.data.data);
                // let theCategories = await dispatch(getGalleryCategory())
                // allcategoriesHandler(theCategories.data.data);
            }
        })
    }, [dispatch, filters])

    async function contains(obj) {
        for (let key in obj) {
            if (obj[key].length > 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    React.useEffect(() => {
        let mounted = true;
        async function getDataCheck() {
            try {
                if (mounted) {
                    const currentData = gallery && gallery.length > 0 ? gallery : [];
                    for (let data in currentData) {
                        let filtered = currentData[data].images.filter(data => data.height > data.width)
                        currentData[data].images = filtered;
                    }
                    const filterexist = await contains(filters);
                    if (filterexist && gallery.length === 0) {
                        alldataHandler(gallery);
                    } else {
                        var offset = (currentPage - 1) * pageSize;
                        var semuadata = [...currentData];
                        var mydatas = semuadata.slice(offset, offset + pageSize);
                        var sortmydatas = mydatas.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                        dataCountHandler(currentData.length);
                        alldataHandler(sortmydatas);
                    }
                }
            } catch (error) { }
        }
        getDataCheck();
        return () => {
            mounted = false;
        };
    }, [filters, gallery, currentPage, pageSize]);

    const renderCards = (list) =>
        list && list.length > 0
            ? list.map((card, i) => (
                <div className="lumi-md-3 lumi-sm-3 lumi-xs-6 even mb10" key={i}>
                    <Card {...card} link={'gallery'} />
                </div>
            ))
            : null;

    const filterClickHandler = () => {
        dispatch(filterOpen(true));
    };

    const closeFilter = () => {
        document.body.style.overflow = "overlay";
        dispatch(filterOpen(false));
    };

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        dispatch(filterOpen(false));
    }

    const showFilteredResults = async (filters) => {
        // console.log(filters, "<<<<,asfdfsf");
        // dispatch(getFilteredGallery(filters)).then((result) => {
        //   console.log(result, "<<<resultfront");
        // });
        await dispatch(getFilteredGallery(filters))
      };

    const handleFilters = (getfilter, category) => {
        const newFilters = { ...filters };
        newFilters[category] = getfilter;
        showFilteredResults(newFilters);
        filtersHandler(newFilters);
      };

    // const selectItem = (data) => {
        // const newSortBy = [...sortBy];
        // const currentTrue = newSortBy.filter((item) => item.selected === true)[0];
        // if (currentTrue.key !== data.key) {
        //   newSortBy[currentTrue.id].selected = false;
        //   newSortBy[data.id].selected = true;
        //   allproductsHandler((newallproducts) => {
        //     if (data.key === "latest") {
        //       return newallproducts.sort(
        //         (a, b) =>
        //           new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        //       );
        //     }
        //     if (data.key === "low") {
        //       return newallproducts.sort(
        //         (a, b) =>
        //           Number(a.variant[0].price[0].price) -
        //           Number(b.variant[0].price[0].price)
        //       );
        //     }
        //     if (data.key === "high") {
        //       return newallproducts.sort(
        //         (a, b) =>
        //           Number(b.variant[0].price[0].price) -
        //           Number(a.variant[0].price[0].price)
        //       );
        //     }
        //   });
        //   sortByHandler(newSortBy);
        // }
        // sortDropdownHandler(false);
    // };

    const classFilter = `frontfilterSearch ${filteropen ? "open" : ""}`;

    return (
        <section id="gallerypage" className={`homepage ${theme}`}>
            <div className="contentwrap" style={{ transform: "none" }}>
                <div className="section dividersection" style={{ marginTop: isMobile ? '0px' : '70px' }}>
                    <div className="container">
                        <div
                            className="headingblock center mb30"
                        >
                            <h2 className='aboutTitle'>
                                Our New Arrivals
                                Dresses & Gowns
                            </h2>
                        </div>
                        <div className="row mb50">
                            <div className="lumi-md-12 lumi-xs-12 m-xs-0 p-xs-0">
                                <div className="lumi-md-12 lumi-xs-12">
                                    <div className="filterSortWrapper">
                                        <div
                                            className="filterContainer"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            onClick={() => filterClickHandler()}
                                        >
                                            <span className="filterIcon">
                                                <FaSlidersH />
                                            </span>
                                            <div className="filterText">FILTER</div>
                                        </div>
                                    </div>
                                </div>
                                {renderCards(alldata)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="lumi-md-12 lumi-xs-12">
                                <nav className="mb30">
                                    <Pagination
                                        Size={nodata ? 1 : dataCount}
                                        pageLimit={pageSize}
                                        onPageChanged={pageChanged}
                                        currentPage={currentPage}
                                        width={isMobile}
                                    />
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <CantDecideSection
                    isMobile={isMobile}
                />
            </div>
            <div className={classFilter}>
                <div className="rightSideClose" onClick={() => closeFilter()}>
                    <span className="rightSideCloseText">Close</span>
                </div>
                {loading ? (
                    <div className="rightSideFormWrapper">
                        <div className="row">
                            <div className="lumi-md-12">
                                <LoadingRipple />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="lumi-md-12">
                            <FilterListPage
                                title="Tags"
                                list={alltags}
                                handleFilters={(filters) => handleFilters(filters, "tags")}
                            />
                            {/* <FilterListPage
                                title="category"
                                list={allcategories}
                                handleFilters={(filters) => handleFilters(filters, "category")}
                            /> */}
                        </div>
                    </div>
                )}
            </div>
            {
                filteropen &&
                <SubBackDrop click={backdropClickHandler} />
            }
        </section>
    )
}

export default GalleryPage;