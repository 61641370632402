import React from 'react';

import {
    useDispatch,
    useSelector
} from 'react-redux';

import {
    Outlet
} from "react-router-dom";

import { useWindowSize } from '../../../utils/windowsize';
import { mobileSize, tabletSize } from '../data/settings';

import { getWebsiteSetting, getWebsiteAddress } from '../../../store/actions/frontend/website';

import Header from './header';
import SideDrawer from './side/side.mobile';
import BackDrop from './backdrop';
import Footer from './footer';

import { ButtonFloating, ThemeFloating } from '../../../utils/floatingbutton';

import {
    FaWhatsapp
} from 'react-icons/fa';

const LumiMaxiLayout = (props) => {

    const { theme, themeHandler } = props;

    const size = useWindowSize();
    const dispatch = useDispatch();

    const thesetting = useSelector(state => state.websitefront.getWebsiteSetting && state.websitefront.getWebsiteSetting.length > 0 && state.websitefront.getWebsiteSetting[0])

    const isMobile = size.width <= mobileSize.width;
    const isTablet = size.width >= tabletSize.minWidth && size.width <= tabletSize.minHeight;

    const [data, dataHandler] = React.useState([]);
    const [sideDrawerOpen, sideDrawerOpenHandler] = React.useState(false);

    const drawerToggleClickHandler = () => {
        sideDrawerOpenHandler(!sideDrawerOpen);
    };

    const backdropClickHandler = () => {
        document.body.style.overflow = 'overlay';
        sideDrawerOpenHandler(false);
    }

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(getWebsiteSetting())
            }
        }
        fetchData().then(async (response) => {
            if (response && response.data.success) {
                let theData = response.data.data;
                dataHandler(theData);

                await dispatch(getWebsiteAddress());
            }
        })
    }, [dispatch])

    let thedata = data && data[0];
   
    return (
        <div style={{ height: '100%' }} id="lumisoft-maxi" className={`${theme}`}>
            <Header
                thedata={thedata}
                theme={theme}
                drawerClickHandler={drawerToggleClickHandler}
                isMobile={isMobile}
                isTablet={isTablet}
            />
            <SideDrawer
                show={sideDrawerOpen}
                theme={theme}
                click={backdropClickHandler}
                isMobile={isMobile}
                isTablet={isTablet}
            />
            {
                sideDrawerOpen &&
                <BackDrop click={backdropClickHandler} />
            }
            <Outlet
                context={{
                    size,
                    theme,
                    thedata
                }}
            />
            <Footer
                thedata={thedata}
                theme={theme}
            />
            <ThemeFloating
                theme={theme}
                themeHandler={themeHandler}
            />
            {
                thesetting === undefined || thesetting === false ? null :
                    <ButtonFloating
                        theme={theme}
                        Icon={<FaWhatsapp size={25} />}
                        link={`${thesetting && thesetting.whatsapp.whatsapp.extension}${thesetting && thesetting.whatsapp.whatsapp.phone}`}
                    />
            }
        </div>
    );
};

export default LumiMaxiLayout;