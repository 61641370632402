import React from 'react';

import UploadCardScreen from './uploadcard';
import Pagination from './pagination';

const UploadCardWrapper = ({
    currentTableData,
    totalCount,
    pageSize,
    currentPage,
    currentPageHandler,
    lastPage,
    isMobile,
    isTablet,
    isBiggerTablet,
    isBigPC
}) => {

    const renderImage = (image, index) => {
        const first = index % 6 === 0;
        const last = index % 6 === 5;
        const imgURL = image.cloudinary && image.cloudinary.length > 0 ? image.cloudinary[0].secure_url : image.url

        return (
            <UploadCardScreen
                key={index}
                srcimg={imgURL}
                altimg={image.alternative}
                mediaimg={image}
                index={index}
                first={first}
                last={last}
                isMobile={isMobile}
                isTablet={isTablet}
                isBiggerTablet={isBiggerTablet}
                isBigPC={isBigPC}
            />
        )
    }

    return (
        <>
            {
                currentTableData && currentTableData.length > 0 ?
                    <div className="lumi-md-12 lumi-xs-12">
                        <div className="image_picker">
                            {
                                currentTableData.map((image, index) => renderImage(image, index))
                            }
                            <div className="clear" />
                        </div>
                    </div>
                    :
                    <div className="lumi-md-12 lumi-xs-12">
                        <div
                            className="imgCard"
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 250
                            }}
                        >
                            <span>Image Empty</span>
                        </div>
                    </div>
            }

            <div className='paginationWrapper'>
                <nav
                    className="pagination mt30"
                >
                    <Pagination
                        totalCount={totalCount}
                        pageSize={pageSize}
                        onPageChanged={(page) => currentPageHandler(page)}
                        currentPage={currentPage}
                        width={isMobile}
                        lastPage={lastPage}
                    />
                </nav>
            </div>
        </>
    )
}

export default UploadCardWrapper;