import {
    FaChrome,
    FaInstagram,
    FaFacebookSquare,
    FaTiktok
    // FaTwitterSquare, 
} from "react-icons/fa";

export const printIcons = (data, theme) => {

    const bridestoryicon = theme === 'light' ? require('../resources/images/bridestoryicon_dark.jpg') : require('../resources/images/bridestoryicon_white.jpg');
    const weddingkuicon = theme === 'light' ? require('../resources/images/weddingku.jpg') : require('../resources/images/weddingku.jpg');

    const sosmedIcons = [
        {
            provider: 'instagram',
            name: 'Instagram',
            icon: <FaInstagram size={25} />
        },
        {
            provider: 'facebook',
            name: 'Facebook',
            icon: <FaFacebookSquare size={25} />
        },
        {
            provider: 'google',
            name: 'Google',
            icon: <FaChrome size={25} />
        },
        {
            provider: 'tiktok',
            name: 'Tiktok',
            icon: <FaTiktok size={25} />
        },
        {
            provider: 'bridestory',
            name: 'Bridestory',
            icon: <img className="logoImage" src={bridestoryicon} alt="bridestory" width="25" height="25" />
        },
        {
            provider: 'weddingku',
            name: 'Weddingku',
            icon: <img className="logoImage" src={weddingkuicon} alt="weddingku" width="25" height="25" />
        }
    ]

    return data && data.length > 0 && data.map((single, index) => {
        return sosmedIcons.map((item) => {

            if (single.provider === item.provider) {
                return (
                    <li
                        key={index}
                    >
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${single.link}`}
                        >
                            {item.icon}
                        </a>
                    </li>
                )
            } else return null
        })
    })
}