import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
    FiCompass,
    FiGlobe,
    FiMail
} from 'react-icons/fi';

import { 
    mobile, 
    // tablet, 
    // bigTablet, 
    // isPC 
} from '../../utils/globalsize';


const SideMainNavigation = ({
    // client,
    size,
    sideClientBackdrop
}) => {

    const isMobile = mobile(size);

    const mainNav = [
        {
            name: 'Dashboard',
            pathname: '/private',
            icon: <FiCompass />,
            showName: true
        },
        {
            name: 'Ticket',
            pathname: '/private/ticket',
            icon: <FiMail />,
            showName: true
        },
        {
            name: 'Website',
            pathname: '/private/website/setting',
            icon: <FiGlobe />,
            showName: true
        }
    ]

    const location = useLocation();
    const navigate = useNavigate();

    const goToLocation = (nextlocation, isMobile) => {
        navigate(nextlocation);
        if (isMobile) {
            sideClientBackdrop(false);
        }
    }

    const printNavigation = (theNav, location, isMobile) => {
        return theNav.map((nav, index) => {
            const navArray = nav.pathname.split("/").filter(e => e);
            const locationArray = location.pathname.split("/").filter(e => e);
            const firstNav = navArray[1];
            const firstLocation = locationArray[1];
            const sameLocation = firstNav === firstLocation ? 'active' : '';
            return (
                <li
                    className={sameLocation} key={index}
                    onClick={() => goToLocation(nav.pathname, isMobile)}
                >
                    <span>{nav.icon}</span>
                    {
                        nav.showName ?
                            <span className='navTitle'>{nav.name}</span>
                            : null
                    }
                </li>
            )
        })
    }

    return (
        <nav className='navContainer'>
            <ul>
                {
                    printNavigation(mainNav, location, isMobile)
                }
            </ul>
        </nav>
    )
}

export default SideMainNavigation;