const LoadingPage = ({
    theme
}) => {

    return (
        <div id="loading" className={theme}>
            <div className="loaderWrapper">
            <div className="loaderSquare">
                <div className="square" ></div>
                <div className="square"></div>
                <div className="square last"></div>
                <div className="square clear"></div>
                <div className="square"></div>
                <div className="square last"></div>
                <div className="square clear"></div>
                <div className="square "></div>
                <div className="square last"></div>
            </div>
            </div>
        </div>
    )
}

export default LoadingPage;