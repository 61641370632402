import React from 'react';

import TemplateDashboard from '../../utils/template/dashboard';
import { FiGrid, FiPlus } from 'react-icons/fi';

const WebsiteSosmed = (props) => {

    const pageNav = [
        {
            name: 'Social media Lists',
            pathname: '/private/website/sosmed/dashboard',
            icon: <FiGrid />,
            showName: true
        },
        {
            name: 'Add Social media',
            pathname: '/private/website/sosmed/add',
            icon: <FiPlus />,
            showName: true
        }
    ]

    const pageName = 'Social Media Dashboard';

    return (
        <TemplateDashboard
            props={props}
            pageName={pageName}
            pageNav={pageNav}
        />
    )
}

export default WebsiteSosmed;