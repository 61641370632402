import {
    FILTER_OPEN
} from '../../types/frontend/types';

const initialState = {
    filterOpen: false
}

export default function filterReducer(state = initialState, action) {
    switch (action.type) {
        case FILTER_OPEN:
            return {
                filterOpen: action.status
            }
        default:
            return state;
    }
}