import {
    GET_SLIDER,
    ADD_SLIDER,
    UPDATE_SLIDER,
    GET_MEDIA_SLIDER,
    ADD_MEDIA_SLIDER,
    DELETE_MEDIA_SLIDER
} from '../types/slider';

export default function sliderReducer(state = {}, action) {
    switch (action.type) {
        case GET_MEDIA_SLIDER:
            return {
                ...state,
                getMediaSlider: action.payload
            }
        case ADD_MEDIA_SLIDER:
            return {
                ...state,
                addMediaSlider: action.payload
            }
        case DELETE_MEDIA_SLIDER:
            return {
                ...state,
                deleteMediaSlider: action.payload
            }
        case GET_SLIDER:
            return {
                ...state,
                getSlider: action.payload
            }
        case ADD_SLIDER:
            return {
                ...state,
                addSlider: action.payload
            }
        case UPDATE_SLIDER:
            return {
                ...state,
                updateSlider: action.payload
            }
        default:
            return state;
    }
}