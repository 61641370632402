import React from 'react';

import SideMainNavigation from './side.nav';
import SideSubNavigation from './side.subnav';
import SideAdminNavigation from './side.admin';


const SideClientNav = ({
    client, approved, theTheme, size, sideClient, sideClientBackdrop
}) => {

    const className = `sideMobileWrapper ${sideClient ? 'open' : ''} ${theTheme}`;

    return (
        <nav className={className}>
            <SideMainNavigation
                client={client}
                size={size}
                sideClientBackdrop={sideClientBackdrop}
            />
            <SideSubNavigation
                size={size}
                sideClientBackdrop={sideClientBackdrop}
            />
            {
                approved ?
                    <SideAdminNavigation
                        size={size}
                        sideClientBackdrop={sideClientBackdrop}
                    />
                    : null
            }
        </nav>
    )
}

export default SideClientNav;