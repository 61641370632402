import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
    FiSmile
} from 'react-icons/fi'

import { mobile } from '../../utils/globalsize';


const SideAdminNavigation = ({
    size, sideClientBackdrop
}) => {

    const isMobile = mobile(size);

    const subNav = [
        {
            name: 'Admin',
            pathname: '/private/admin/dashboard',
            icon: <FiSmile />
        },
    ]

    const location = useLocation();
    const navigate = useNavigate();
    
    const goToLocation = (nextlocation, isMobile) => {
        navigate(nextlocation);
        if(isMobile){ 
            sideClientBackdrop(false);
        }
    }

    const printNavigation = (subNav, location, isMobile) => {
        return subNav.map((nav, index) => {
            const navArray = nav.pathname.split("/").filter(e => e);
            const locationArray = location.pathname.split("/").filter(e => e);
            const firstNav = navArray[1];
            const firstLocation = locationArray[1];
            const sameLocation = firstNav === firstLocation ? 'active' : '';
            return (
                <li
                    className={sameLocation} key={index}
                    onClick={() => goToLocation(nav.pathname, isMobile)}
                >
                    <span>{nav.icon}</span>
                    <span className='navTitle'>{nav.name}</span>
                </li>
            )
        })
    }

    return (
        <nav className='subNavContainer'>
            <ul>
                {
                    printNavigation(subNav, location, isMobile)
                }
            </ul>
        </nav>
    )
}

export default SideAdminNavigation;