import React from 'react';
// import axios from 'axios';

import {
    useDispatch,
    // useSelector
} from 'react-redux';
import {
    useNavigate,
    // useOutletContext
} from 'react-router-dom';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, resetFields, update, updateSelect } from '../../utils/form/formActions';

import FormField from '../../utils/form/formField';

import AdminLoading from '../../utils/circle.loading';

import { addNewSosmed, getWebsiteSosmed } from '../../../store/actions/website';

import { FiArrowLeft, FiSend, FiUser } from 'react-icons/fi';
import { FaEnvelope, FaSquareRootAlt } from 'react-icons/fa';
import { provider } from '../../utils/globaldata/provider';


const WebsiteSosmedAdd = (props) => {

    const { theme } = props;

    // const globaldata = useOutletContext();
    // const {
    // client, 
    // size
    // } = globaldata;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const theUser = useSelector(state => state.auth && state.auth.data);
    const outsideClickRef = React.useRef();

    const [loading, loadingHandler] = React.useState(false);
    const [allSosmed, allSosmedHandler] = React.useState([]);

    // const [heightForm, heightFormHandler] = React.useState(0);

    const maxSosmed = 3;

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                loadingHandler(true);
                const sosmed = await dispatch(getWebsiteSosmed());
                if (sosmed.status === 200 && sosmed.data.success) {
                    allSosmedHandler(sosmed.data.data)
                }
            }
        }
        fetchData().then(() => {
            loadingHandler(false)
        })
    }, [dispatch])

    const formName = 'Add Social media';

    const [formdata, formdataHandler] = React.useState({
        saveas: {
            element: 'input',
            title: 'Save as',
            value: '',
            config: {
                name: 'SaveAs',
                type: 'text',
                placeholder: 'Save sosmed as'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        link: {
            element: 'input',
            title: 'Link social media',
            value: '',
            config: {
                name: 'LinkName',
                type: 'text',
                placeholder: 'Enter your sosmed link'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        provider: {
            element: 'selectboolean',
            title: 'Provider',
            value: { value: 'instagram', name: 'Instagram' },
            config: {
                name: 'provider',
                type: "text",
                placeholder: "Select Provider"
            },
            options: provider,
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
    });

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const updateSelectForm = (element, getdata, dataHandler) => {
        const newSelect = updateSelect(element, getdata);
        dataHandler(newSelect);
        outsideClickRef.current.closeFromOutside();
    }

    const submitAddSosmed = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let formIsValid = isFormValid(formdata, formName);

        if (formIsValid) {
            let newDataToSubmit = {
                ...dataToSubmit
            }

            newDataToSubmit.provider = dataToSubmit['provider'].value;

            if (allSosmed && allSosmed.length === 0) {
                newDataToSubmit.active = true
            } else {
                newDataToSubmit.active = false
            }

            const result = await dispatch(addNewSosmed(newDataToSubmit))
            if (result === undefined || result === 'undefined') {
                loadingHandler(false);
            }
            if (!result.data.success) {
                dispatch(global.globalError('please try again'))
                loadingHandler(false);
            }
            const newFormdata = resetFields(formdata, formName);
            formdataHandler(newFormdata)
            dispatch(global.globalSuccess('sosmed added'))
            setTimeout(() => {
                navigate('/private/website/sosmed/dashboard')
                loadingHandler(false);
            }, 2000);

        } else {
            dispatch(global.globalError('Please check your data'))
            loadingHandler(false);
        }
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/website/sosmed/dashboard')
    }

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                <div className='cardWrapper'>
                    <div className='cardHeader' style={{ paddingBottom: 0 }}>
                        <h5>{formName}</h5>
                        <div className='cardOptions'>
                            <ul>
                                <li id="loadingSubmit" className='iconRefresh button' onClick={(event) => goBack(event)}>
                                    <div className='center'>
                                        <span><FiArrowLeft /></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='cardBody'>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'saveas'}
                                                formdata={formdata.saveas}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiUser />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'link'}
                                                formdata={formdata.link}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FaEnvelope />}
                                                formdataHandler={formdataHandler}
                                                showName={true}
                                                disabled={loading ? true : false}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                ref={outsideClickRef}
                                                id={'provider'}
                                                formdata={formdata.provider}
                                                selectForm={(element) => updateSelectForm(element, formdata, formdataHandler)}
                                                theClass="inputbutton"
                                                icons={<FaSquareRootAlt />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul>
                                    <li
                                        id="fullSubmit"
                                        className='iconRefresh button'
                                        onClick={
                                            loading ? null
                                                : (event) => submitAddSosmed(event)
                                        }
                                    >
                                        <div className='center formSubmit'>
                                            {
                                                loading ? <AdminLoading theTheme={theme} />
                                                    : <span><FiSend /> Submit</span>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebsiteSosmedAdd;