import React from 'react';

import {
    useNavigate,
    NavLink
} from 'react-router-dom';

import SocialMedia from './socialmedia';

import {
    mainNav,
    minScroll
} from '../data/settings';
import {
    // EmailButton,
    PhoneButton,
    WhatsappButton
} from '../../../utils/floatingbutton';

import {
    FaBars,
    // FaFacebook 
} from 'react-icons/fa';
import { FiPhone } from 'react-icons/fi';
import { FaWhatsapp } from "react-icons/fa";

const HeaderTwo = ({
    thedata,
    theme,
    drawerClickHandler,
    isMobile,
    isTablet
}) => {

    const navigate = useNavigate();

    const [headerShow, headerShowHandler] = React.useState(false);

    const handleScroll = React.useCallback(() => {
        let nowscrolling = window.scrollY;
        let isScrolling = nowscrolling > minScroll;
        if (isScrolling) {
            headerShowHandler(true)
        } else {
            headerShowHandler(false)
        }
    }, []);

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll])

    const printNavigation = (theNav) => {
        return theNav.map((nav, index) => {
            return (
                <li
                    key={index}
                >
                    <NavLink
                        className={(navData) => navData.isActive ? "active" : ""}
                        to={nav.pathname}
                    >
                        {nav.name}
                    </NavLink>
                </li>
            )
        })
    }

    const logoUrl = theme === 'light' ? require('../resources/images/LuminousSposa_Logo_9.png') : require('../resources/images/LuminousSposa_Logo_12.png')
    // const logoUrlMobile = theme === 'light' ? require('../resources/images/LuminousSposa_Logo_7.png') : require('../resources/images/LuminousSposa_Logo_10.png')

    return (
        <header>
            <div
                className={headerShow ? "frontendwebsite topBar small" : "frontendwebsite topBar"}
            >
                <div className="container">
                    <div className="headerinner">
                        <div
                            className='sideWebsiteWrapper'
                            onClick={() => drawerClickHandler()}
                        >
                            <div className='sideWebsiteButton'>
                                <span><FaBars /></span>
                            </div>
                        </div>
                        <div className="left">
                            <ul>
                                {
                                    thedata === undefined ? null :
                                        <li>
                                            <div className='contactIcon phone'>
                                                <FiPhone />
                                            </div>

                                            {
                                                isMobile ? null :
                                                    <span>
                                                        <PhoneButton
                                                            data={`${thedata && thedata.number.whatsapp.extension}${thedata && thedata.number.whatsapp.phone}`}
                                                        />
                                                    </span>
                                            }

                                        </li>
                                }
                                {
                                    thedata === undefined ? null :
                                        <li>
                                            <div className='contactIcon whatsapp'>
                                                <FaWhatsapp />
                                            </div>
                                            {
                                                isMobile ? null :
                                                    <span>
                                                        <WhatsappButton
                                                            data={`${thedata && thedata.whatsapp.whatsapp.extension}${thedata && thedata.whatsapp.whatsapp.phone}`}
                                                        />
                                                    </span>
                                            }
                                        </li>
                                }
                            </ul>
                        </div>
                        <div className="middle">
                            <div
                                className="logoImageWrapper"
                                onClick={() => navigate('/')}
                            >
                                <img
                                    className="logoImage"
                                    src={logoUrl}
                                    alt=""
                                    width="200px"
                                />

                            </div>
                        </div>
                        <SocialMedia
                            theme={theme}
                            thedata={thedata}
                        />
                    </div>
                    <div className='navWrapper'>
                        <div className="topBarRight">
                            <div className="clear"></div>
                            <div className="navigation_items">
                                <ul>
                                    {
                                        printNavigation(mainNav)
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header >
    )
}

export default HeaderTwo;