import React from 'react';
import moment from 'moment';
// import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';

const CarouselPage = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 7000,
        speed: 1500
    }

    const showImage = (data) => {
        return (
            data && data.length > 0 && data.map((item, index) => (
                <div key={index}>
                    <div className="carouselimage">
                        <img src={`${item.images[0].url}`} alt={`${item.images[0].alt}`} />
                        <div className={props.isMobile ? "hometitlemobile" : "hometitle"}>
                            <h1 className={props.isMobile ? "big_whitemobile" : "big_white"}>
                                {item.title}
                            </h1>
                            <div className="slidersmalltitle">{item.subtitle}</div>
                            {
                                item.event.show ?
                                    <div className="eventdetails">
                                        {moment(item.event.startdate, 'x').format('DD/MM/YYYY')} - {moment(item.event.enddate, 'x').format('DD/MM/YYYY')} <br />
                                        open {item.event.hourfrom}, close {item.event.houruntil}
                                    </div>
                                    : null
                            }
                            {
                                item.goto !== '' ?
                                    <a href={item.goto} target="_blank" rel="noopener noreferrer" className="button">
                                        Website
                                    </a>
                                    : null
                            }
                            {
                                item.event.show ?
                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${item.event.location}`} target="_blank" rel="noopener noreferrer" className="buttondirections">
                                        Directions
                                    </a>
                                    : null
                            }
                        </div>
                    </div>

                </div>
            ))
        )
    }

    return (
        <div
            className="slidercontainer"
            style={
                {
                    overflow: "hidden"
                }
            }
        >
            <Slider {...settings}>
                {
                    showImage(props.slider)
                }
            </Slider>
        </div>
    );
};

export default CarouselPage;