import React from 'react';
import { toast } from 'react-toastify';

export const tokenName = 'client-access-token';

export const showToast = (type, msg) => {

    switch (type) {
        case 'SUCCESS':
            toast.success(msg, {
                toastId: "toastAvoidsDuplicates",
                position: toast.POSITION.BOTTOM_RIGHT
            })
            break;
        case 'ERROR':
            toast.error(msg, {
                toastId: "toastAvoidsDuplicates",
                position: toast.POSITION.BOTTOM_RIGHT
            })
            break;
        default:
            return false
    }
}

export const setTokenCookie = (cookieName, cookieValue, hourToExpire) => {
    let date = new Date();
    date.setTime(date.getTime() + (hourToExpire * 60 * 60 * 1000));
    document.cookie = cookieName + " = " + cookieValue + "; expires = " + date.toGMTString();
}

export const getTokenCookie = (key) => {
    var bravo = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return bravo ? bravo.pop() : "";
}

export const removeTokenCookie = (key) => {
    document.cookie = `${key}=''; expires=Thu, 01 Jan 1970 00:00:00 UTC; Max-Age=0; SameSite=None; Secure; path=/;`;
}

export const clientGetToken = () => getTokenCookie(tokenName);
export const removeClientToken = () => removeTokenCookie(tokenName);

export const getAuthHeader = (token) => {
    return { headers: { 'Authorization': `Bearer ${getTokenCookie(token)}` } }
}

export const getAuthHeaderWithToken = (token, source) => {
    return {
        headers: { 'Authorization': `Bearer ${getTokenCookie(token)}` },
        cancelToken: source.token
    }
}

export const useLocalStorageState = (key, initialValue) => {
    const [value, setValue] = React.useState(() => {
        const persistedValue = localStorage.getItem(key);
        return persistedValue !== null ? persistedValue : initialValue;
    });

    React.useEffect(() => {
        localStorage.setItem(key, value);
    }, [key, value]);

    return [value, setValue];
}

export const isObjectEmpty = (objectName) => {
    return (
        objectName &&
        Object.keys(objectName).length === 0 &&
        objectName.constructor === Object
    );
};

export const cropSizeSetting = [
    {
        name: '4 : 1',
        value: 4.00
    },
    {
        name: '4 : 1.5',
        value: 2.67
    },
    {
        name: '4 : 3',
        value: 1.33
    },
    {
        name: '4 : 2',
        value: 2.00
    },
    {
        name: '2 : 4',
        value: 0.50
    },
    {
        name: '3 : 4',
        value: 0.75
    },
    {
        name: '1 : 1',
        value: 1.00
    },
    {
        name: '9 : 16',
        value: 0.56
    },
    {
        name: '16 : 9',
        value: 1.77
    },
    {
        name: '3 : 4',
        value: 1.78
    }
]