import {
    GET_GALLERY,
    ADD_GALLERY,
    UPDATE_GALLERY,
    GET_GALLERY_CATEGORY,
    ADD_GALLERY_CATEGORY,
    UPDATE_GALLERY_CATEGORY,
    GET_GALLERY_TAGS,
    ADD_GALLERY_TAGS,
    UPDATE_GALLERY_TAGS,
    GET_MEDIA_GALLERY,
    ADD_MEDIA_GALLERY,
    DELETE_MEDIA_GALLERY
} from '../types/gallery';

export default function galleryReducer(state = {}, action) {
    switch (action.type) {
        case GET_MEDIA_GALLERY:
            return {
                ...state,
                getMediaGallery: action.payload
            }
        case ADD_MEDIA_GALLERY:
            return {
                ...state,
                addMediaGallery: action.payload
            }
        case DELETE_MEDIA_GALLERY:
            return {
                ...state,
                deleteMediaGallery: action.payload
            }
        case GET_GALLERY:
            return {
                ...state,
                getGallery: action.payload
            }
        case ADD_GALLERY:
            return {
                ...state,
                addGallery: action.payload
            }
        case UPDATE_GALLERY:
            return {
                ...state,
                updateGallery: action.payload
            }
        case GET_GALLERY_CATEGORY:
            return {
                ...state,
                getGalleryCategory: action.payload
            }
        case ADD_GALLERY_CATEGORY:
            return {
                ...state,
                addGalleryCategory: action.payload
            }
        case UPDATE_GALLERY_CATEGORY:
            return {
                ...state,
                updateGalleryCategory: action.payload
            }
        case GET_GALLERY_TAGS:
            return {
                ...state,
                getGalleryTags: action.payload
            }
        case ADD_GALLERY_TAGS:
            return {
                ...state,
                addGalleryTags: action.payload
            }
        case UPDATE_GALLERY_TAGS:
            return {
                ...state,
                updateGalleryTags: action.payload
            }
        default:
            return state;
    }
}