import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { showToast } from '../utils/tools';

import {
    useSelector,
    useDispatch
} from 'react-redux';

import { globalClear } from '../../store/actions/global/data.global';

const GlobalHOC = ({ children, theme, themeHandler }) => {

    const dispatch = useDispatch();
    const notifications = useSelector(state => state.global);

    React.useEffect(() => {
        if (notifications && notifications.error) {
            const msg = notifications.message ? notifications.message : 'Error';
            showToast('ERROR', msg);
            dispatch(globalClear());
        }
        if (notifications && notifications.success === false) {
            const msg = notifications.message ? notifications.message : 'Failed';
            showToast('ERROR', msg);
            dispatch(globalClear());
        }
        if (notifications && notifications.success === true) {
            const msg = notifications.message ? notifications.message : 'Success';
            showToast('SUCCESS', msg)
            dispatch(globalClear());
        }
    }, [notifications, dispatch])

    return (
        <>
            {children}
            <ToastContainer />
        </>
    )
}

export default GlobalHOC;