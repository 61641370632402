import React from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    // Navigate, 
    useNavigate,
    useParams,
    useOutletContext
} from 'react-router-dom';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, resetFields, updateSearch, update, updateSelect } from '../../utils/form/formActions';

import FormField from '../../utils/form/formField';
import MediaFloatingScreen from '../../utils/floatingmedia/floatingscreen';
import ClientBackdrop from '../../hoc/backdrop';

import AdminLoading from '../../utils/circle.loading';

import { addMedia, deleteMedia, getAllMedia } from '../../../store/actions/media';
import { getWebsiteAddress, updateAddress } from '../../../store/actions/website';

import { countries } from '../../utils/globaldata/countries';

import { FiArrowLeft, FiImage, FiPhone, FiSend, FiUser } from 'react-icons/fi';
import { FaEnvelope, FaLock } from 'react-icons/fa';


const WebsiteAddressDetails = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        size
    } = globaldata;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let searchParams = useParams();
    let id = searchParams.id;

    const outsideClickRef = React.useRef();
    const phoneOutsideClickRef = React.useRef();
    const mediaRef = React.useRef();

    const getAddress = useSelector(state => state.website && state.website.getWebsiteAddress);
    const theUser = useSelector(state => state.auth && state.auth.data);

    const [dataselected, dataselectedHandler] = React.useState({});

    const [loading, loadingHandler] = React.useState(false);
    const [loadingupload, loadinguploadHandler] = React.useState(false);
    const [loadingdelete, loadingdeleteHandler] = React.useState(false);

    const [openmedia, openmediaHandler] = React.useState(false);
    const [filternumber, filternumberHandler] = React.useState(0);

    const [picked, pickedHandler] = React.useState([]);
    const [pickedid, pickedidHandler] = React.useState();
    const [allMedia, allMediaHandler] = React.useState([]);

    const formName = 'Address details';

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                loadingHandler(true);
                await dispatch(getWebsiteAddress());
                const media = await dispatch(getAllMedia());
                if (media.status === 200 && media.data.success) {
                    let allMedia = media.data.data;
                    let mediaFiltered = [];
                    if (filternumber !== 0) {
                        mediaFiltered = allMedia.filter((single) => Math.round(single.width / single.height) === filternumber);
                    } else {
                        mediaFiltered = allMedia
                    }
                    allMediaHandler(mediaFiltered)
                };
            }
        }
        fetchData().then(() => {
            loadingHandler(false)
        })
    }, [dispatch, filternumber])

    const [formdata, formdataHandler] = React.useState({
        saveas: {
            element: 'input',
            title: 'Save as',
            value: '',
            config: {
                name: 'SaveAs',
                type: 'text',
                placeholder: 'Save address as'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        companyname: {
            element: 'input',
            title: 'Company Name',
            value: '',
            config: {
                name: 'CompanyName',
                type: 'text',
                placeholder: 'Enter Company Name'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        logolink: {
            element: 'logo',
            title: 'Logo',
            value: {
                url: "",
                alt: ""
            },
            config: {
                type: 'pc'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        mobilelogolink: {
            element: 'logo',
            title: 'Mobile Logo',
            value: {
                url: "",
                alt: ""
            },
            config: {
                type: 'mobile'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        address: {
            element: 'textarea',
            title: 'Address',
            value: '',
            config: {
                name: 'AddressName',
                type: 'text',
                placeholder: 'Enter The address'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        phone: {
            element: 'selectphone',
            title: 'Phone',
            value: '',
            search: '',
            searchValue: {
                name: "Indonesia",
                dialCode: "+62",
                countryCode: "ID"
            },
            default: {
                name: "Indonesia",
                dialCode: "+62",
                countryCode: "ID"
            },
            config: {
                name: 'phoneInput',
                type: 'tel',
                placeholder: 'Enter phone number'
            },
            options: countries,
            validation: {
                required: false,
                phone: true
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        phone2: {
            element: 'selectphone',
            title: 'Phone 2 (Optional)',
            value: '',
            search: '',
            searchValue: {
                name: "Indonesia",
                dialCode: "+62",
                countryCode: "ID"
            },
            default: {
                name: "Indonesia",
                dialCode: "+62",
                countryCode: "ID"
            },
            config: {
                name: 'phone2Input',
                type: 'tel',
                placeholder: 'Enter phone number'
            },
            options: countries,
            validation: {
                required: false,
                phone: true
            },
            valid: true,
            touched: true,
            validationMessage: ''
        },
        email: {
            element: 'input',
            title: 'Email',
            value: '',
            config: {
                name: 'EmailName',
                type: 'text',
                placeholder: 'Enter email'
            },
            validation: {
                required: true,
                email: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        active: {
            element: 'selectboolean',
            title: 'Active',
            value: { value: false, name: 'NO' },
            config: {
                name: 'Active',
                type: "text",
                placeholder: "Active status"
            },
            options: [
                { value: true, name: 'YES' },
                { value: false, name: 'NO' },
            ],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });

    React.useEffect(() => {
        let mounted = true;
        const implementData = async () => {
            if (mounted) {
                if (getAddress !== undefined) {
                    let selected = getAddress && getAddress.length > 0 && getAddress.filter(data => data._id === id)[0];
                    if (selected === undefined) navigate('/private/website/address/dashboard')
                    dataselectedHandler(selected);
                    formdataHandler((prevFormData) => {
                        var newFormdata = { ...prevFormData };
                        const temporaryFormdata = { ...newFormdata };
                        for (let key in newFormdata) {
                            const newData = {
                                ...temporaryFormdata[key]
                            }
                            if (newData.element === 'selectphone') {
                                let theValue = {}
                                if (selected[key] === undefined) {
                                    theValue = {
                                        name: "Indonesia",
                                        dialCode: "+62",
                                        countryCode: "ID"
                                    }
                                    newData.value = 0;
                                    newData.searchValue = theValue;
                                } else {
                                    theValue = {
                                        name: selected[key].nationality,
                                        dialCode: selected[key].extension,
                                        countryCode: selected[key].countrycode
                                    }
                                    newData.value = selected[key].phone;
                                    newData.searchValue = theValue;
                                }

                            } else if (newData.element === 'selectboolean') {
                                if (selected[key] === true || selected[key] === false) {
                                    newData.value = { value: selected[key], name: selected[key] === true ? 'YES' : 'NO' };
                                } else {
                                    let getOptions = "";
                                    if (newData.options && newData.options.length > 0) {
                                        getOptions = newData.options.filter(item => {
                                            return item.value === selected[key]
                                        })
                                    }
                                    newData.value = getOptions[0];
                                }

                            } else {
                                newData.value = selected[key];
                            }
                            newData.valid = true;
                            newData.touched = true;
                            newData.validationMessage = '';
                            temporaryFormdata[key] = newData;
                        }
                        return temporaryFormdata;
                    })
                }
            }
        }
        implementData();
        return () => {
            mounted = false;
        }
    }, [getAddress, navigate, theUser, id])

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const selectForm = (element, dataHandler) => {
        dataHandler((prevFormData) => {
            var newFormdata = { ...prevFormData };
            const temporaryFormdata = { ...newFormdata };
            const newElement = {
                ...temporaryFormdata[element.whichdata]
            }

            newElement.searchValue = element.item

            temporaryFormdata[element.whichdata] = newElement;
            return temporaryFormdata;
        })
        phoneOutsideClickRef.current.phoneCloseFromOurside();
    }

    const updateFormSearch = (element, data, dataHandler, alldataToSearch, thekey) => {
        const newFormdata = updateSearch(element, data, formName);
        var newFormData = newFormdata;
        const temporaryFormdata = {
            ...newFormData
        }
        const newElement = {
            ...temporaryFormdata[element.id]
        }
        const results = alldataToSearch.filter(result =>
            result[thekey].toLowerCase().includes(newElement.search.toLowerCase())
        );
        newElement.options = results;
        temporaryFormdata[element.id] = newElement
        dataHandler(temporaryFormdata);
    }

    const updateSelectForm = (element, getdata, dataHandler) => {
        const newSelect = updateSelect(element, getdata);
        dataHandler(newSelect);
        outsideClickRef.current.closeFromOutside();
    }

    const submitUpdateAddress = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let formIsValid = isFormValid(formdata, formName);

        var newFormdata = formdata;
        const temporaryFormdata = { ...newFormdata };
        const newElement = {
            ...temporaryFormdata['phone']
        }

        const newOptional = {
            ...temporaryFormdata['phone2']
        }

        const network = newElement.searchValue;
        const phonenumber = dataToSubmit.phone.replace(/^0+/, '');

        if (formIsValid) {
            let newDataToSubmit = {
                ...dataToSubmit
            }

            newDataToSubmit.active = dataToSubmit['active'].value;

            newDataToSubmit.phone = {
                extension: network.dialCode,
                phone: phonenumber,
                nationality: network.name,
                countrycode: network.countryCode,
            }

            if (newOptional.value !== 0) {
                const networkOpt = newOptional.searchValue;
                const phonenumberOpt = dataToSubmit.phone2.replace(/^0+/, '');
                newDataToSubmit.phone2 = {
                    extension: networkOpt.dialCode,
                    phone: phonenumberOpt,
                    nationality: networkOpt.name,
                    countrycode: networkOpt.countryCode,
                }
            } else {
                newDataToSubmit.phone2 = {
                    extension: 0,
                    phone: 0,
                    nationality: 0,
                    countrycode: 0
                }
            }

            newDataToSubmit.id = dataselected._id.toString();

            const result = await dispatch(updateAddress(newDataToSubmit))
            if (result === undefined || result === 'undefined') {
                loadingHandler(false);
            }
            if (!result.data.success) {
                dispatch(global.globalError('please try again'))
                loadingHandler(false);
            }
            const newFormdata = resetFields(formdata, formName);
            formdataHandler(newFormdata)
            dispatch(global.globalSuccess('address updated'))
            setTimeout(() => {
                navigate('/private/website/address/dashboard')
                loadingHandler(false);
            }, 2000);

        } else {
            dispatch(global.globalError('Please check your data'))
            loadingHandler(false);
        }
    }

    const mediaClickHandler = async () => {
        document.body.style.overflow = 'overlay';
        mediaRef.current.clearData();
        pickedHandler([]);
        const media = await dispatch(getAllMedia());
        if (media.status === 200 && media.data.success) {
            allMediaHandler(media.data.data)
        } else {
            dispatch(global.globalError('Network error'))
        }
        openmediaHandler(false);
    }

    const openMedia = ({ event, id }) => {
        event.preventDefault();
        event.stopPropagation();
        filternumberHandler(4);
        allMediaHandler((prevData) => {
            const newdata = prevData.filter((single) => Math.round(single.width / single.height) === 4);
            return newdata
        })
        pickedidHandler(id)
        openmediaHandler(true);
    }

    const openMediaSquare = ({ event, id }) => {
        event.preventDefault();
        event.stopPropagation();
        filternumberHandler(1);
        allMediaHandler((prevData) => {
            const newdata = prevData.filter((single) => Math.round(single.width / single.height) === 1);
            return newdata
        })
        pickedidHandler(id)
        openmediaHandler(true);
    }

    const onMediaSubmit = async ({ event, id }) => {
        event.preventDefault();
        event.stopPropagation();

        if (picked && picked.length > 0) {
            let pickedImage = picked[0];

            formdataHandler((prevData) => {
                var newFormdata = { ...prevData };
                const temporaryFormdata = { ...newFormdata };
                const newLogoLink = {
                    ...temporaryFormdata[id]
                }
                newLogoLink.value.url = pickedImage.secureurl;
                newLogoLink.value.alt = pickedImage.name;
                newLogoLink.valid = true;
                newLogoLink.touched = true;
                temporaryFormdata[id] = newLogoLink;
                return temporaryFormdata
            })
            const media = await dispatch(getAllMedia());
            if (media.status === 200 && media.data.success) {
                allMediaHandler(media.data.data)
            } else {
                dispatch(global.globalError('Network error'))
            }
            openmediaHandler(false);
        } else {
            dispatch(global.globalError('Please select logo'))
        }
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/website/address/dashboard')
    }

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                <div className='cardWrapper'>
                    <div className='cardHeader' style={{ paddingBottom: 0 }}>
                        <h5>{formName}</h5>
                        <div className='cardOptions'>
                            <ul>
                                <li id="loadingSubmit" className='iconRefresh button' onClick={(event) => goBack(event)}>
                                    <div className='center'>
                                        <span><FiArrowLeft /></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='cardBody'>
                        <div className='lumi-md-4 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <div className='logowrapper'>
                                    <FormField
                                        id={'logolink'}
                                        formdata={formdata.logolink}
                                        change={(element) => openMedia({ event: element, id: 'logolink' })}
                                        theClass="inputclass"
                                        icons={<FiImage />}
                                        showName={true}
                                        disabled={loading ? true : false}
                                    />
                                </div>
                                <div className='mobilelogowrapper'>
                                    <FormField
                                        id={'mobilelogolink'}
                                        formdata={formdata.mobilelogolink}
                                        change={(element) => openMediaSquare({ event: element, id: 'mobilelogolink' })}
                                        theClass="inputclass"
                                        icons={<FiImage />}
                                        showName={true}
                                        disabled={loading ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='lumi-md-4 lumi-xs-12 pr20 p-xs-0'>
                            <div className='cardOptions form'>
                                <ul className='mb30 mb-xs-0'>
                                    <li className='inputSearch'>
                                        <FormField
                                            id={'saveas'}
                                            formdata={formdata.saveas}
                                            change={(element) => updateForm(element)}
                                            theClass="inputclass"
                                            icons={<FiUser />}
                                            showName={true}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                    <li className='inputSearch'>
                                        <FormField
                                            ref={outsideClickRef}
                                            id={'active'}
                                            formdata={formdata.active}
                                            selectForm={(element) => updateSelectForm(element, formdata, formdataHandler)}
                                            theClass="inputclass"
                                            icons={<FaEnvelope />}
                                            formdataHandler={formdataHandler}
                                            showName={true}
                                            thekey={'name'}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                </ul>
                                <ul className='mb30 mb-xs-0'>
                                    <li className='inputSearch'>
                                        <FormField
                                            id={'companyname'}
                                            formdata={formdata.companyname}
                                            change={(element) => updateForm(element)}
                                            theClass="inputclass"
                                            icons={<FaLock />}
                                            formdataHandler={formdataHandler}
                                            showName={true}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                </ul>
                                <ul className='mb30 mb-xs-0'>
                                    <li className='inputSearch'>
                                        <FormField
                                            id={'email'}
                                            formdata={formdata.email}
                                            change={(element) => updateForm(element)}
                                            theClass="inputclass"
                                            icons={<FaEnvelope />}
                                            formdataHandler={formdataHandler}
                                            showName={true}
                                            disabled={loading ? true : false}
                                        />
                                    </li>


                                </ul>
                            </div>
                        </div>
                        <div className='lumi-md-4 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul className='mb30 mb-xs-0'>
                                    <li className='inputSearch'>
                                        <FormField
                                            ref={phoneOutsideClickRef}
                                            id={'phone'}
                                            formdata={formdata.phone}
                                            searchChange={(element) => updateFormSearch(element, formdata, formdataHandler, countries, 'name')}
                                            change={(element) => updateForm(element, formdata, formdataHandler)}
                                            selectForm={(element) => selectForm(element, formdataHandler)}
                                            theClass="inputbuttonForSelect"
                                            icons={<FiPhone />}
                                            formdataHandler={formdataHandler}
                                            showName={true}
                                            disabled={loading ? true : false}
                                            thekey={'name'}
                                        />
                                    </li>
                                    <li className='inputSearch'>
                                        <FormField
                                            ref={phoneOutsideClickRef}
                                            id={'phone2'}
                                            formdata={formdata.phone2}
                                            searchChange={(element) => updateFormSearch(element, formdata, formdataHandler, countries, 'name')}
                                            change={(element) => updateForm(element, formdata, formdataHandler)}
                                            selectForm={(element) => selectForm(element, formdataHandler)}
                                            theClass="inputbuttonForSelect"
                                            icons={<FiPhone />}
                                            formdataHandler={formdataHandler}
                                            showName={true}
                                            disabled={loading ? true : false}
                                            thekey={'name'}
                                        />
                                    </li>
                                </ul>
                                <ul className='mb30 mb-xs-0'>
                                    <li className='inputSearch'>
                                        <FormField
                                            id={'address'}
                                            formdata={formdata.address}
                                            change={(element) => updateForm(element)}
                                            theClass="inputclass"
                                            icons={<FaLock />}
                                            formdataHandler={formdataHandler}
                                            showName={true}
                                            disabled={loading ? true : false}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <ul>
                                    <li
                                        id="fullSubmit"
                                        className='iconRefresh button'
                                        onClick={
                                            loading ? null
                                                : (event) => submitUpdateAddress(event)
                                        }
                                    >
                                        <div className='center formSubmit'>
                                            {
                                                loading ? <AdminLoading theTheme={theme} />
                                                    : <span><FiSend /> Submit</span>
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <MediaFloatingScreen
                    ref={mediaRef}
                    media={allMedia}
                    filternumber={filternumber}
                    mediaHandler={allMediaHandler}
                    size={size}
                    mainMediaOpen={openmedia}
                    theme={theme}
                    uploadmultiple={true}
                    enablemultiple={false}
                    onMediaSubmit={onMediaSubmit}
                    onCancelSubmit={mediaClickHandler}
                    picked={picked}
                    pickedHandler={pickedHandler}
                    pickedid={pickedid}
                    loadingupload={loadingupload}
                    loadinguploadHandler={loadinguploadHandler}
                    loadingdelete={loadingdelete}
                    loadingdeleteHandler={loadingdeleteHandler}
                    addFunction={addMedia}
                    deleteFunction={deleteMedia}
                    getFunction={getAllMedia}
                    allowedCropSize={[]}
                />
            }
            {
                !loading && openmedia &&
                <ClientBackdrop click={mediaClickHandler} />
            }
        </div>
    )

}


export default WebsiteAddressDetails;