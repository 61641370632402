import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
    FiImage
} from 'react-icons/fi'

import { BsImages } from "react-icons/bs";
import { ImBubbles } from "react-icons/im";

import { mobile } from '../../utils/globalsize';
import { FaImages } from 'react-icons/fa';


const SideSubNavigation = ({
    size, sideClientBackdrop
}) => {

    const isMobile = mobile(size);

    const subNav = [
        {
            name: 'Media',
            pathname: '/private/media',
            icon: <FiImage />,
            showName: true
        },
        {
            name: 'Slider',
            pathname: '/private/slider/dashboard',
            icon: <FaImages />,
            showName: true
        },
        {
            name: 'Gallery',
            pathname: '/private/gallery/dashboard',
            icon: <BsImages />,
            showName: true
        },
        {
            name: 'Testimoni',
            pathname: '/private/testimoni/dashboard',
            icon: <ImBubbles />,
            showName: true
        }
    ]

    const location = useLocation();
    const navigate = useNavigate();
    
    const goToLocation = (nextlocation, isMobile) => {
        navigate(nextlocation);
        if(isMobile){ 
            sideClientBackdrop(false);
        }
    }

    const printNavigation = (subNav, location, isMobile) => {
        return subNav.map((nav, index) => {
            const navArray = nav.pathname.split("/").filter(e => e);
            const locationArray = location.pathname.split("/").filter(e => e);
            const firstNav = navArray[1];
            const firstLocation = locationArray[1];
            const sameLocation = firstNav === firstLocation ? 'active' : '';
            return (
                <li
                    className={sameLocation} key={index}
                    onClick={() => goToLocation(nav.pathname, isMobile)}
                >
                    <span>{nav.icon}</span>
                    <span className='navTitle'>{nav.name}</span>
                </li>
            )
        })
    }

    return (
        <nav className='subNavContainer'>
            <ul>
                {
                    printNavigation(subNav, location, isMobile)
                }
            </ul>
        </nav>
    )
}

export default SideSubNavigation;