import React from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";

import { FiLogOut } from 'react-icons/fi';
import { HeaderPage } from '../../utils/globalfunction';

// import { adminSignOut } from '../../../store/actions/auth';

const ProfileAdmin = () => {

    const globaldata = useOutletContext();
    const { client, size } = globaldata;
    // console.log(client, '<<client')

    const navigate = useNavigate();

    const logOutFunction = () => {
        navigate('/logout')
    }

    return (
        <div className="mainBodyWrapper">
            {
                HeaderPage('Profile')
            }
            <div className='insidenavWrapper'>
                Navigation
            </div>
            <div className='insidecontentWrapper'>
                Content
                <div onClick={() => logOutFunction()}>
                    <FiLogOut />
                </div>
            </div>
        </div>
    )
}

export default ProfileAdmin;