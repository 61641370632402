import * as data from './data.gallery';
import * as global from '../global/data.global';
import axios from '../api';
import axiosmultipart from '../apimultipart';

import { checkError } from '../global/function';

import {
    getAuthHeader,
    tokenName
} from '../../../views/utils/tools';

import {
    ADD_MEDIA_GALLERY, DELETE_MEDIA_GALLERY
} from '../../types/gallery';

export const getAllMediaGallery = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/gallery/getmediagallery');
            dispatch(data.getMediaGallery(request.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export async function addMediaGallery(dataToSubmit) {
    try {
        const formData = new FormData();
        dataToSubmit.forEach((img, i) => {
            formData.append(`image${i}`, img);
        });

        formData.append('upload_preset', process.env.REACT_APP_UPLOADPRESET);
        formData.append('cloud_name', process.env.REACT_APP_CLOUDNAME);

        const request = axiosmultipart.post(
            `/api/gallery/addmediagallery`,
            formData
        ).then(response => response.data);

        return {
            type: ADD_MEDIA_GALLERY,
            payload: request
        }
    } catch (error) {
        return error
    }
}

export async function deleteMediaGallery(dataToSubmit) {
    try {
        const request = axios.post(`/api/gallery/deletemediagallery`, dataToSubmit).then(response => response.data);

        return {
            type: DELETE_MEDIA_GALLERY,
            payload: request
        }
    } catch (error) {
        return error
    }
}

export const getGalleryFunction = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/gallery/getgallery?limit=0');
            dispatch(data.getGallery(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addGalleryFunction = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/gallery/addgallery`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addGallery(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateGalleryFunction = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/gallery/updategallery`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateGallery(request));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getGalleryCategory = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/gallery/getgallerycategory');
            dispatch(data.getGalleryCategory(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addGalleryCategory = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/gallery/addgallerycategory`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addGalleryCategory(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateGalleryCategory = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/gallery/updategallerycategory`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateGalleryCategory(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getGalleryTags = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/gallery/getgallerytags');
            dispatch(data.getGalleryTags(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const addGalleryTags = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/gallery/addgallerytags`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.addGalleryTags(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const updateGalleryTags = (dataToSubmit) => {
    return async (dispatch) => {
        try {
            const request = await axios.post(`/api/gallery/updategallerytags`, dataToSubmit, getAuthHeader(tokenName));
            dispatch(data.updateGalleryTags(request.data.data));
            return request;

        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}