import React from 'react';
import { useOutletContext } from "react-router-dom";

import { useDispatch } from 'react-redux';

import { mobile, tablet, bigTablet, isPC } from '../../utils/globalsize';
import { HeaderPage } from '../../utils/globalfunction';

const TicketPage = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        size
    } = globaldata;

    const dispatch = useDispatch();

    const isMobile = mobile(size);
    const isTablet = tablet(size);
    const isBiggerTablet = bigTablet(size);
    const isBigPC = isPC(size);

    return (
        <div className='mainBodyWrapper'>
            {
                HeaderPage('Ticket Dashboard')
            }
            <div className='cardWrapper'>
                <div className='dashboardBody'>
                    <div className="row width100p">
                        <div className='lumi-md-12 lumi-xs-12'>
                            <div className={`mainImageScreen ${theme}`}>
                                <div
                                    className="mediaLibraryWrapper"
                                    style={{
                                        height: isMobile ? size.height * 95 / 100 : size.height * 65 / 100
                                    }}
                                >
                                    <div className="mediaLibraryTitle">
                                    Ticket page
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketPage;