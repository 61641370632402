import React from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    // Navigate, 
    useNavigate,
    useParams
} from 'react-router-dom';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, resetFields, update, updateSelect } from '../../utils/form/formActions';
import FormField from '../../utils/form/formField';

import { FiArrowLeft, FiSend } from 'react-icons/fi';
import AdminLoading from '../../utils/circle.loading';
import { updateAdmin, getallClient } from '../../../store/actions/client';
import { FaLock } from 'react-icons/fa';


const AdminDetails = (props) => {

    const { theme } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let searchParams = useParams();
    let id = searchParams.id;

    const outsideClickRef = React.useRef();
    const getAllAdmin = useSelector(state => state.client && state.client.clientGetAll);
    const theUser = useSelector(state => state.auth && state.auth.data);

    const [loading, loadingHandler] = React.useState(false);
    const [dataselected, dataselectedHandler] = React.useState({})

    const formName = 'Admin details';
    const [formstatus, formstatusHandler] = React.useState({
        adminstatus: {
            element: 'selectboolean',
            title: 'Active',
            value: { value: false, name: 'NO' },
            config: {
                name: 'AdminStatus',
                type: "text",
                placeholder: "Admin status"
            },
            options: [
                { value: true, name: 'YES' },
                { value: false, name: 'NO' },
            ],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    })
    const [formdata, formdataHandler] = React.useState({
        password: {
            element: 'input',
            title: 'Password',
            value: '',
            config: {
                name: 'Password',
                type: 'password',
                placeholder: 'Enter the password'
            },
            validation: {
                required: true,
                strong: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        confirmpassword: {
            element: 'input',
            title: 'Confirm Password',
            value: '',
            config: {
                name: 'ConfirmPassword',
                type: 'password',
                placeholder: 'Confirm the password'
            },
            validation: {
                required: true,
                confirm: 'password'
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    React.useEffect(() => {
        let mounted = true;
        const implementData = async () => {
            if (mounted) {
                if (theUser !== undefined && theUser.rolesName !== 'superadmin') {
                    navigate('/')
                }
                if (getAllAdmin !== undefined) {
                    let selected = getAllAdmin && getAllAdmin.length > 0 && getAllAdmin.filter(admin => admin._id === id)[0];
                    if (selected === undefined) navigate('/private/admin/dashboard')
                    dataselectedHandler(selected);
                    formstatusHandler((prevFormData) => {
                        var newFormdata = { ...prevFormData };
                        const temporaryFormdata = { ...newFormdata };
                        const newVerified = {
                            ...temporaryFormdata['adminstatus']
                        }
                        newVerified.value = selected['isActive'] === true ? { value: true, name: 'YES' } : { value: false, name: 'NO' };
                        temporaryFormdata['adminstatus'] = newVerified;
                        return temporaryFormdata;
                    })
                }
            }
        }
        implementData();
        return () => {
            mounted = false;
        }
    }, [getAllAdmin, navigate, theUser, id])

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const updateSelectForm = (element, getdata, dataHandler) => {
        const newSelect = updateSelect(element, getdata);
        dataHandler(newSelect);
        outsideClickRef.current.closeFromOutside();
    }

    const submitUpdateAdmin = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let statusToSubmit = generateData(formstatus, formName);
        let formIsValid = isFormValid(formdata, formName);

        let thePassword = ""
        let result = []
        if (dataToSubmit.password !== "" && formIsValid) {
            thePassword = dataToSubmit.password
        } else {
            thePassword = ""
        }
        let dataToSend = {
            id,
            password: thePassword,
            ...statusToSubmit
        }

        result = await dispatch(updateAdmin(dataToSend))
        if (result === undefined || result === 'undefined') {
            loadingHandler(false);
        }
        if (!result.data.success) {
            dispatch(global.globalError('please try again'))
            loadingHandler(false);
        }
        const newFormdata = resetFields(formdata, formName);
        const newFormstatus = resetFields(formstatus, formName);
        await dispatch(getallClient());
        formdataHandler(newFormdata)
        formstatusHandler(newFormstatus)
        dispatch(global.globalSuccess('status updated'))
        setTimeout(() => {
            navigate('/private/admin/dashboard')
            loadingHandler(false);
        }, 1000);
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/admin/dashboard')
    }

    return (
        <div className='cardWrapper'>
            <div className='cardHeader' style={{ paddingBottom: 30 }}>
                <h5>{dataselected.email}</h5>
                <div className='cardOptions'>
                    <ul>
                        <li id="loadingSubmit" className='iconRefresh button' onClick={(event) => goBack(event)}>
                            <div className='center'>
                                <span><FiArrowLeft /></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='cardBody'>
                <div className='lumi-md-12 lumi-xs-12 mb30 p0'>
                    <div className='cardOptions'>
                        <ul>
                            <li className='inputSearch'>
                                <FormField
                                    ref={outsideClickRef}
                                    id={'adminstatus'}
                                    formdata={formstatus.adminstatus}
                                    selectForm={(element) => updateSelectForm(element, formstatus, formstatusHandler)}
                                    theClass="inputclass"
                                    icons={<FaLock />}
                                    showName={true}
                                    thekey={'name'}
                                    disabled={loading ? true : false}
                                />
                            </li>
                            <li className='inputSearch'>
                                <FormField
                                    id={'password'}
                                    formdata={formdata.password}
                                    change={(element) => updateForm(element)}
                                    theClass="inputclass"
                                    icons={<FaLock />}
                                    formdataHandler={formdataHandler}
                                    showName={true}
                                    disabled={loading ? true : false}
                                />
                            </li>
                            <li className='inputSearch'>
                                <FormField
                                    id={'confirmpassword'}
                                    formdata={formdata.confirmpassword}
                                    change={(element) => updateForm(element)}
                                    theClass="inputclass"
                                    icons={<FaLock />}
                                    formdataHandler={formdataHandler}
                                    showName={true}
                                    disabled={loading ? true : false}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='lumi-md-12 lumi-xs-12 p0'>
                    <div className='cardOptions form'>
                        <ul>
                            <li
                                // id="loadingSubmit" 
                                id="fullSubmit"
                                className='iconRefresh button'
                                onClick={(event) => submitUpdateAdmin(event)}
                            >
                                <div className='center'>
                                    {
                                        loading ? <AdminLoading theTheme={theme} />
                                            : <span><FiSend /> Submit</span>

                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDetails;