import * as data from './data.testimoni';
import * as global from '../global/data.global';
import axios from 'axios';

import { checkError } from '../global/function';

import {
    GET_FILTERED_TESTIMONI
} from '../../../types/frontend/types';

export const getFilteredTestimoni = (filters = [], prevState = []) => {
    const data = {
        filters
    };

    const request = axios
        .post('/api/testimoni/filteredtestimoni', data)
        .then((response) => {
            if (response && response.data.success) {
                let newState = [...prevState, ...response.data.newtestimoni];
                return {
                    success: true,
                    testimonilength: response.data.testimonilength,
                    newtestimoni: newState
                };
            } else {
                return {
                    success: false,
                    testimonilength: 0,
                    newtestimoni: []
                };
            }
        });

    return {
        type: GET_FILTERED_TESTIMONI,
        payload: request,
    };
}

export const getTestimoniFunction = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/testimoni/gettestimoni');
            dispatch(data.getTestimoni(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getTestimoniCategoryDistinct = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/testimoni/gettestimonicategorydistinct');
            dispatch(data.getTestimoniCategoryDistinct(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getTestimoniTags = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/testimoni/gettestimonitags');
            dispatch(data.getTestimoniTags(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}
