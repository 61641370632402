import {
    GET_WEBSITE_SETTINGS,
    GET_WEBSITE_ADDRESS
} from '../../types/frontend/types';

export default function websiteReducer(state = {}, action) {
    switch (action.type) {
        case GET_WEBSITE_SETTINGS:
            return {
                ...state,
                getWebsiteSetting: action.payload
            }
        case GET_WEBSITE_ADDRESS:
            return {
                ...state,
                getWebsiteAddress: action.payload
            }
        default:
            return state;
    }
}