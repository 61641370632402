import React from 'react';
import {
    useDispatch
    // useSelector
} from 'react-redux';
import {
    Outlet,
    useNavigate
} from "react-router-dom";

import { useWindowSize } from '../utils/windowsize';

import ClientSideAuth from './side/side.auth';

import { clientIsAuth } from '../../store/actions/auth';

import CircleLoading from '../utils/circle.loading';

const PublicRoute = ({ theme }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const size = useWindowSize();

    const [fadeType, fadeTypeHandler] = React.useState({
        fade: ''
    });

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (fadeType.fade === '') {
                fadeTypeHandler({
                    fade: 'fadeIn'
                })
            }
        }, 300);
        return () => clearTimeout(timeout)
    }, [fadeType])


    const [loading, loadingHandler] = React.useState(false);

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(clientIsAuth())
            }
        }
        fetchData().then(async (response) => {
            if (response && response.data.success) {
                let theData = response.data.data;
                if (!theData.isPin) {
                    navigate('/auth/pin')
                }
                if (theData.isPin && theData.isFirstTime) {
                    navigate('/auth/welcome')
                }
                if (theData.isPin && !theData.isFirstTime) {
                    navigate('/private')
                }
                setTimeout(() => {
                    loadingHandler(false)
                }, 1000)
            } else {
                navigate('/auth/login')
            }
            setTimeout(() => {
                loadingHandler(false)
            }, 1000)
        })
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate]);

    if (loading) {
        return (
            <div style={{
                paddingTop: 78,
                paddingBottom: 80
            }}>
                <CircleLoading />
            </div>
        )
    }

    return (
        <div id='client'>
            <ClientSideAuth
                theTheme={theme}
                size={size}
                fadeType={fadeType}
            >
                <Outlet
                    context={{
                        size,
                        theme
                    }}
                />
            </ClientSideAuth>
        </div>

    )
}

export default PublicRoute;