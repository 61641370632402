import * as data from './data.slider';
import * as global from '../global/data.global';
import axios from 'axios';

import { checkError } from '../global/function';

export const getAllMediaSlider = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/slider/getmediaslider');
            dispatch(data.getMediaSlider(request.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getSliderFunction = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/slider/getslider');
            dispatch(data.getSlider(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}