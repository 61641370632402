import React from 'react';
// import moment from 'moment';
import {
    // useDispatch,
    useSelector
} from 'react-redux';
import { HeaderPage } from '../../utils/globalfunction';
// import { useNavigate, useOutletContext } from "react-router-dom";

const DashboardPage = () => {

    const clientData = useSelector(state => state.auth && state.auth.data);

    // const globaldata = useOutletContext();
    // const { client, size } = globaldata;

    const profileimage = clientData.profileimage ? clientData.profileimage.cloudinary.secure_url : window.location.origin + "/assets/images/admin/profile_default.png";
    const clientName = clientData.firstname ? `${clientData.firstname} ${clientData.lastname.substring(0, 1).toUpperCase()}` : 'client';

    return (
        <div className='mainBodyWrapper'>
            {
                HeaderPage('Dashboard')
            }
            <div className='cardWrapper'>
                <div className='dashboardHeader'>
                    <div className='row'>
                        <div className='lumi-md-4 lumi-sm-12 lumi-lg-12 lumi-xlg-12 lumi-xs-12'>
                            <div className='dashboardProfile'>
                                <div className='imgWrapper'>
                                    <img src={profileimage} alt="profile" />
                                </div>
                                <div className='greetWrapper'>
                                    <p className='greetings'>Welcome back,<br />{clientName}</p>
                                    <small>It's really nice to see you again</small>
                                </div>
                            </div>
                        </div>
                        <div className='lumi-md-4 lumi-sm-12 lumi-lg-12 lumi-xlg-12 lumi-xs-12'>cardWrapper</div>
                        <div className='lumi-md-4 lumi-sm-12 lumi-lg-12 lumi-xlg-12 lumi-xs-12'>cardWrapper</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardPage;