import React from 'react';

import {
    BsFacebook,
    BsInstagram
} from 'react-icons/bs';

const ClientSideAuth = ({ children, theTheme, fadeType }) => {

    return (
        <div id='clientlogin' className={theTheme}>
            <section>
                <div className='containerfluid'>
                    <div className='row'>
                        <div
                            className='lumi-md-6 lumi-xs-12 loginImg mobileHide'
                            // style={{
                            //     background: `url(/assets/images/client/square.jpg)`,
                            //     backgroundRepeat: 'repeat',
                            //     backgroundPosition: 'top left'
                            // }}
                        >
                            <div className={`information ${fadeType.fade}`}>
                                <div className='title'>
                                    <h1>Personal dashboard</h1>
                                </div>
                                <p>
                                    Welcome,<br />
                                    This Dashboard can only be accessed by
                                    <br />
                                    lumisoft technology client/customer
                                </p>
                                <div className='socialIcon'>
                                    <div className='socialButton facebook'>
                                        <BsFacebook />
                                    </div>
                                    <div className='socialButton instagram'>
                                        <BsInstagram />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='lumi-md-6 lumi-xs-12 loginBg formSection'>
                            {children}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ClientSideAuth;