import * as data from './data.gallery';
import * as global from '../global/data.global';
import axios from 'axios';

import { checkError } from '../global/function';

import {
    GET_FILTERED_GALLERY
} from '../../../types/frontend/types';

export const getFilteredGallery = (filters = [], prevState = []) => {
    const data = {
        filters
    };

    const request = axios
        .post('/api/gallery/filteredgallery', data)
        .then((response) => {
            if (response && response.data.success) {
                let newState = [...prevState, ...response.data.newgallery];
                return {
                    success: true,
                    gallerylength: response.data.gallerylength,
                    newgallery: newState
                };
            } else {
                return {
                    success: false,
                    gallerylength: 0,
                    newgallery: []
                };
            }
        });

    return {
        type: GET_FILTERED_GALLERY,
        payload: request,
    };
}

export const getAllMediaGallery = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/gallery/getmediagallery');
            dispatch(data.getMediaGallery(request.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getGalleryFunction = (limit) => {
    return async (dispatch) => {
        try {
            const request = await axios.get(`/api/gallery/getgallery?limit=${limit}`);
            dispatch(data.getGallery(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getGalleryCategoryDistinct = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/gallery/getgallerycategorydistinct');
            dispatch(data.getGalleryCategoryDistinct(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}


export const getGalleryCategory = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/gallery/getgallerycategory');
            dispatch(data.getGalleryCategory(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export const getGalleryTags = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/gallery/getgallerytags');
            dispatch(data.getGalleryTags(request.data.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}