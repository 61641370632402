import React from 'react';

import ImageCardScreen from './imagecard';
import Pagination from './pagination';

const ImageCardWrapper = ({
    multiple,
    currentTableData,
    picked,
    pickedHandler,
    totalCount,
    pageSize,
    currentPage,
    currentPageHandler,
    lastPage,
    isMobile,
    isTablet,
    isBiggerTablet,
    isBigPC
}) => {

    const handleImageClick = (image) => {
        const pickedImage = [image];
        if (multiple) {
            if (picked.length > 0) {
                // let results = picked.filter( data => data._id === image._id).length > 0;
                let results = picked.some(el => {
                    if(el._id){
                        return el._id === image._id
                    } else {
                        return el.name === image.name
                    }
                });
                
                if (results) {
                    let newresults = picked.filter(data => {
                        if(data._id){
                            return data._id !== image._id
                        } else {
                            return data.name !== image.name
                        }
                    })
                    pickedHandler(newresults);
                } else {
                    let newArray = picked.concat(pickedImage);
                    pickedHandler(newArray);
                }
            } else {
                pickedHandler(pickedImage);
            }

        } else {
            pickedHandler(pickedImage)
        }

    }

    const renderImage = (image, index) => {
        const exist = Array.isArray(picked) === true ? picked.some(el => {
            if(el._id) {
                return el._id === image._id
            } else {
                return el.name === image.name
            }
        }) : picked._id === image._id;
        const first = index % 6 === 0;
        const last = index % 6 === 5;
        const imgURL = image.secureurl ? image.secureurl : image.url

        return (
            <ImageCardScreen
                key={index}
                srcimg={imgURL}
                altimg={image.alternative}
                mediaimg={image}
                index={index}
                first={first}
                last={last}
                isSelected={exist}
                selectedImage={() => handleImageClick(image)}
                isMobile={isMobile}
                isTablet={isTablet}
                isBiggerTablet={isBiggerTablet}
                isBigPC={isBigPC}
            />
        )
    }
    
    return (
        <>
            {
                currentTableData && currentTableData.length > 0 ?
                    <div className="lumi-md-12 lumi-xs-12">
                        <div className="image_picker">
                            {
                                currentTableData.map((image, index) => renderImage(image, index))
                            }
                            <div className="clear" />
                        </div>
                    </div>
                    :
                    <div className="lumi-md-12 lumi-xs-12">
                        <div
                            className="imgCard"
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 250
                            }}
                        >
                            <span>Image Empty</span>
                        </div>
                    </div>
            }

            <div className='paginationWrapper'>
                <nav
                    className="pagination mt30"
                >
                    <Pagination
                        totalCount={totalCount}
                        pageSize={pageSize}
                        onPageChanged={(page) => currentPageHandler(page)}
                        currentPage={currentPage}
                        width={isMobile}
                        lastPage={lastPage}
                    />
                </nav>
            </div>
        </>
    )
}

export default ImageCardWrapper;