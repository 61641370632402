import React from 'react';
import {
    useDispatch
    // useSelector
} from 'react-redux';
import {
    Outlet,
    useNavigate
} from "react-router-dom";

import { useWindowSize } from '../utils/windowsize';

import Loading from '../utils/loading';

import { clientIsAuth } from '../../store/actions/auth';
import { getallClient } from '../../store/actions/client';

import ClientSideLayout from './side/side.layout';

import ClientHeader from './header';
import SideClientNav from './side/side.mobile';

import ClientBackdrop from './backdrop';

import { mobile } from '../utils/globalsize';

import { ButtonFloating, ThemeFloating } from '../utils/floatingbutton';

import {
    FaWhatsapp
} from 'react-icons/fa';

const PrivateRoute = ({ theme, themeHandler }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const size = useWindowSize();
    const isMobile = mobile(size);

    const [loading, loadingHandler] = React.useState(false);
    const [data, dataHandler] = React.useState({});
    const [approved, approvedHandler] = React.useState(false);
    const [sideClient, sideClientHandler] = React.useState(false);

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(clientIsAuth())
            }
        }

        fetchData().then(async (response) => {
            if (response && response.data.success) {
                let theData = response.data.data;
                dataHandler({ data: theData, auth: true })
                if (!theData.isPin) {
                    navigate('/auth/pin')
                }
                if (theData.isPin && theData.isFirstTime) {
                    navigate('/auth/welcome')
                }
                if (theData.isPin && !theData.isFirstTime) {
                    if(theData.rolesName === 'superadmin'){
                        approvedHandler(true);
                    }
                    dispatch(getallClient())
                }
                setTimeout(() => {
                    loadingHandler(false)
                }, 1000)
            } else {
                navigate('/auth/login')
            }
        })
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate]);

    const sideClientToggle = () => {
        sideClientHandler(!sideClient);
    };

    const sideClientBackdrop = () => {
        document.body.style.overflow = 'overlay';
        sideClientHandler(false)
    }

    if (!data.auth) {
        return (
            <Loading
                theme={theme}
            />
        )
    }
    if (loading) {
        return (
            <Loading
                theme={theme}
            />
        )
    }

    return (
        <div id='client'>
            <ClientHeader
                isMobile={isMobile}
                theTheme={theme}
                sideClientToggle={sideClientToggle}
            />
            <SideClientNav
                client={data}
                approved={approved}
                size={size}
                theTheme={theme}
                sideClient={sideClient}
                sideClientBackdrop={sideClientBackdrop}
            />
            {
                sideClient &&
                <ClientBackdrop click={sideClientBackdrop} />
            }
            <ClientSideLayout
                client={data}
                approved={approved}
                theTheme={theme}
                size={size}
            >
                <Outlet
                    context={{
                        client: data,
                        size,
                        theme
                    }}
                />
            </ClientSideLayout>
            <ThemeFloating 
                theme={theme}
                themeHandler={themeHandler}
            />
            <ButtonFloating
                theme={theme}
                Icon={<FaWhatsapp size={25} />}
                link={"+62811920111"}
            />
        </div>

    )
}

export default PrivateRoute;