import React from 'react';
import { HeaderPage } from '../../utils/globalfunction';

const NotificationPage = () => {

    return (
        <div className='mainBodyWrapper'>
            {
                HeaderPage('Notification')
            }
        </div>
    )
}

export default NotificationPage;