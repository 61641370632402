import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clientIsAuth } from '../../store/actions/auth';

import Loading from '../utils/loading';


const ClientNavigateToSignIn = (props) => {

    const { theme } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // api/client/404

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(clientIsAuth())
            }
        }
        fetchData().then(async (response) => {
            if (response && response.data.success) {
                navigate('/')
            } else {
                navigate('/login')
            }

        })
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate]);

    return (
        <div className={`emailVerifyWrapper ${theme}`}>
             <Loading theme={theme} />
        </div>
    )
}

export default ClientNavigateToSignIn;