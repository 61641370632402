import React from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    // Navigate, 
    useNavigate,
    useParams,
    useOutletContext
} from 'react-router-dom';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, resetFields, updateSearch, update, updateSelect } from '../../utils/form/formActions';

import FormField from '../../utils/form/formField';

import AdminLoading from '../../utils/circle.loading';

import { getWebsiteNumber, updateNumber } from '../../../store/actions/website';

import { countries } from '../../utils/globaldata/countries';

import { FiArrowLeft, FiPhone, FiSend, FiUser } from 'react-icons/fi';
import { FaEnvelope } from 'react-icons/fa';


const WebsiteNumberDetails = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        // size
    } = globaldata;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let searchParams = useParams();
    let id = searchParams.id;

    const outsideClickRef = React.useRef();
    const phoneOutsideClickRef = React.useRef();

    const getNumber = useSelector(state => state.website && state.website.getWebsiteNumber);
    const theUser = useSelector(state => state.auth && state.auth.data);

    const [dataselected, dataselectedHandler] = React.useState({});

    const [loading, loadingHandler] = React.useState(false);

    const [allNumber, allNumberHandler] = React.useState([]);

    const formName = 'Number details';

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                loadingHandler(true);
                await dispatch(getWebsiteNumber());
            }
        }
        fetchData().then(() => {
            loadingHandler(false)
        })
    }, [dispatch])

    const [formdata, formdataHandler] = React.useState({
        saveas: {
            element: 'input',
            title: 'Save as',
            value: '',
            config: {
                name: 'SaveAs',
                type: 'text',
                placeholder: 'Save number as'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        whatsapp: {
            element: 'selectphone',
            title: 'Number',
            value: '',
            search: '',
            searchValue: {
                name: "Indonesia",
                dialCode: "+62",
                countryCode: "ID"
            },
            default: {
                name: "Indonesia",
                dialCode: "+62",
                countryCode: "ID"
            },
            config: {
                name: 'whatsappInput',
                type: 'tel',
                placeholder: 'Enter number'
            },
            options: countries,
            validation: {
                required: true,
                phone: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        active: {
            element: 'selectboolean',
            title: 'Active',
            value: { value: false, name: 'NO' },
            config: {
                name: 'Active',
                type: "text",
                placeholder: "Active status"
            },
            options: [
                { value: true, name: 'YES' },
                { value: false, name: 'NO' },
            ],
            validation: {
                required: false
            },
            valid: true,
            touched: true,
            validationMessage: ''
        }
    });

    React.useEffect(() => {
        let mounted = true;
        const implementData = async () => {
            if (mounted) {
                // if (theUser !== undefined && theUser.rolesName !== 'superadmin') {
                //     navigate('/')
                // }
                if (getNumber !== undefined) {
                    let selected = getNumber && getNumber.length > 0 && getNumber.filter(data => data._id === id)[0];
                    if (selected === undefined) navigate('/private/website/number/dashboard')
                    dataselectedHandler(selected);
                    formdataHandler((prevFormData) => {
                        var newFormdata = { ...prevFormData };
                        const temporaryFormdata = { ...newFormdata };
                        for (let key in newFormdata) {
                            const newData = {
                                ...temporaryFormdata[key]
                            }
                            if (newData.element === 'selectphone') {
                                let theValue = {}
                                if (selected[key] === undefined) {
                                    theValue = {
                                        name: "Indonesia",
                                        dialCode: "+62",
                                        countryCode: "ID"
                                    }
                                    newData.value = 0;
                                    newData.searchValue = theValue;
                                } else {
                                    theValue = {
                                        name: selected[key].nationality,
                                        dialCode: selected[key].extension,
                                        countryCode: selected[key].countrycode
                                    }
                                    newData.value = selected[key].phone;
                                    newData.searchValue = theValue;
                                }

                            } else if (newData.element === 'selectboolean') {
                                if (selected[key] === true || selected[key] === false) {
                                    newData.value = { value: selected[key], name: selected[key] === true ? 'YES' : 'NO' };
                                } else {
                                    let getOptions = "";
                                    if (newData.options && newData.options.length > 0) {
                                        getOptions = newData.options.filter(item => {
                                            return item.value === selected[key]
                                        })
                                    }
                                    newData.value = getOptions[0];
                                }
                            } else {
                                newData.value = selected[key];
                            }
                            newData.valid = true;
                            newData.touched = true;
                            newData.validationMessage = '';
                            temporaryFormdata[key] = newData;
                        }
                        return temporaryFormdata;
                    })
                }
            }
        }
        implementData();
        return () => {
            mounted = false;
        }
    }, [getNumber, allNumber, navigate, theUser, id])

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const selectForm = (element, dataHandler) => {
        dataHandler((prevFormData) => {
            var newFormdata = { ...prevFormData };
            const temporaryFormdata = { ...newFormdata };
            const newElement = {
                ...temporaryFormdata[element.whichdata]
            }

            newElement.searchValue = element.item

            temporaryFormdata[element.whichdata] = newElement;
            return temporaryFormdata;
        })
        phoneOutsideClickRef.current.phoneCloseFromOurside();
    }

    const updateSelectForm = (element, getdata, dataHandler) => {
        const newSelect = updateSelect(element, getdata);
        dataHandler(newSelect);
        outsideClickRef.current.closeFromOutside();
    }

    const updateFormSearch = (element, data, dataHandler, alldataToSearch, thekey) => {
        const newFormdata = updateSearch(element, data, formName);
        var newFormData = newFormdata;
        const temporaryFormdata = {
            ...newFormData
        }
        const newElement = {
            ...temporaryFormdata[element.id]
        }
        const results = alldataToSearch.filter(result =>
            result[thekey].toLowerCase().includes(newElement.search.toLowerCase())
        );
        newElement.options = results;
        temporaryFormdata[element.id] = newElement
        dataHandler(temporaryFormdata);
    }

    const submitUpdateNumber = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let formIsValid = isFormValid(formdata, formName);

        var newFormdata = formdata;
        const temporaryFormdata = { ...newFormdata };
        const newElement = {
            ...temporaryFormdata['whatsapp']
        }

        const network = newElement.searchValue;
        const phonenumber = dataToSubmit.whatsapp.replace(/^0+/, '');

        if (formIsValid) {
            let newDataToSubmit = {
                ...dataToSubmit
            }

            newDataToSubmit.active = dataToSubmit['active'].value;

            newDataToSubmit.whatsapp = {
                extension: network.dialCode,
                phone: phonenumber,
                nationality: network.name,
                countrycode: network.countryCode,
            }

            newDataToSubmit.id = dataselected._id.toString();

            const result = await dispatch(updateNumber(newDataToSubmit))
            if (result === undefined || result === 'undefined') {
                loadingHandler(false);
            }
            if (!result.data.success) {
                dispatch(global.globalError('please try again'))
                loadingHandler(false);
            }
            const newFormdata = resetFields(formdata, formName);
            formdataHandler(newFormdata)
            dispatch(global.globalSuccess('number updated'))
            setTimeout(() => {
                navigate('/private/website/number/dashboard')
                loadingHandler(false);
            }, 2000);

        } else {
            dispatch(global.globalError('Please check your data'))
            loadingHandler(false);
        }
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/website/number/dashboard')
    }

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                <div className='cardWrapper'>
                    <div className='cardHeader' style={{ paddingBottom: 0 }}>
                        <h5>{formName}</h5>
                        <div className='cardOptions'>
                            <ul>
                                <li id="loadingSubmit" className='iconRefresh button' onClick={(event) => goBack(event)}>
                                    <div className='center'>
                                        <span><FiArrowLeft /></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='cardBody'>
                        <div className='lumi-md-12 lumi-xs-12 p0'>
                            <div className='cardOptions form'>
                                <div className='lumi-md-12 lumi-xs-12 p0'>
                                    <ul className='mb30 mb-xs-0'>
                                        <li className='inputSearch'>
                                            <FormField
                                                id={'saveas'}
                                                formdata={formdata.saveas}
                                                change={(element) => updateForm(element)}
                                                theClass="inputclass"
                                                icons={<FiUser />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                ref={phoneOutsideClickRef}
                                                id={'whatsapp'}
                                                formdata={formdata.whatsapp}
                                                searchChange={(element) => updateFormSearch(element, formdata, formdataHandler, countries, 'name')}
                                                change={(element) => updateForm(element, formdata, formdataHandler)}
                                                selectForm={(element) => selectForm(element, formdataHandler)}
                                                theClass="inputbuttonForSelect"
                                                icons={<FiPhone />}
                                                formdataHandler={formdataHandler}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                        <li className='inputSearch'>
                                            <FormField
                                                ref={outsideClickRef}
                                                id={'active'}
                                                formdata={formdata.active}
                                                selectForm={(element) => updateSelectForm(element, formdata, formdataHandler)}
                                                theClass="inputclass"
                                                icons={<FaEnvelope />}
                                                showName={true}
                                                disabled={loading ? true : false}
                                                thekey={'name'}
                                            />
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className='lumi-md-12 lumi-xs-12 p0'>
                                <div className='cardOptions form'>
                                    <ul>
                                        <li
                                            id="fullSubmit"
                                            className='iconRefresh button'
                                            onClick={
                                                loading ? null
                                                    : (event) => submitUpdateNumber(event)
                                            }
                                        >
                                            <div className='center formSubmit'>
                                                {
                                                    loading ? <AdminLoading theTheme={theme} />
                                                        : <span><FiSend /> Submit</span>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default WebsiteNumberDetails;