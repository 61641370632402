import React, { useState } from "react";

const FilterListPage = ({ list, title, handleFilters }) => {
  const [checked, checkedHandler] = useState([]);

  const filterLists = (newlist) =>
    newlist &&
    newlist.length > 0 &&
    newlist.map((item, index) => (
      <li key={item._id}>
        <div className="filterItem" onClick={() => handleToggle(item._id)}>
          {item.name}
          {/* <span>(5)</span> */}
          <input
            type="checkbox"
            className="custom-control-input"
            id="rememberMe"
            readOnly
            // onChange={() => this.handleToggle(item._id)}
            checked={checked.indexOf(item._id) !== -1}
          ></input>
          {/* <Checkbox 
                                    onChange={this.handleToggle(item._id)}
                                    
                                /> */}
        </div>
      </li>
    ));

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log(newChecked)
    checkedHandler(newChecked);
    handleFilters(newChecked);
  };

  return (
    <div className="filterWrapper">
      <div className="filterTitle">
        <h3>{title}</h3>
      </div>
      <ul className="filterLists">{filterLists(list)}</ul>
    </div>
  );
};

export default FilterListPage;
