import React from 'react';

import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useNavigate,
    useOutletContext
} from 'react-router-dom';

import GlobalTableTemplate from '../../utils/table/globaltable';
import { getWebsiteEmail } from '../../../store/actions/website';

const WebsiteEmailDashboard = (props) => {

    const { theme } = props;

    const globaldata = useOutletContext();
    const {
        // client, 
        size
    } = globaldata;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theUser = useSelector(state => state.auth && state.auth.data);

    const [loading, loadingHandler] = React.useState(false);
    const [alldata, alldataHandler] = React.useState([]);

    let formname = 'Email'
    let email = [
        {
            name: 'Save as',
            code: 'saveas'
        },
        {
            name: 'Email',
            code: 'email'
        },
        {
            name: 'Active',
            code: 'active'
        }
    ]

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            if (mounted) {
                // if (theUser !== undefined && theUser.rolesName !== 'superadmin') {
                //     navigate('/')
                // }
                const email = await dispatch(getWebsiteEmail());
                if (email.status === 200 && email.data.success) {
                    alldataHandler(email.data.data)
                }
            }
        }
        fetchData()
        return () => {
            mounted = false;
        };
    }, [dispatch, navigate, theUser])

    const allURLs = async () => {
        loadingHandler(true)
        const email = await dispatch(getWebsiteEmail());
        if (email.status === 200 && email.data.success) {
            alldataHandler(email.data.data)
        }
        loadingHandler(false)
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/website/setting')
    }

    return (
        <div className="row width100p">
            <div className='lumi-md-12 lumi-xs-12'>
                {
                    <GlobalTableTemplate
                        tableId={formname}
                        tableName={`${formname} Table`}
                        alldata={alldata}
                        datatoshow={email}
                        pageSize={5}
                        loading={loading}
                        allURLs={allURLs}
                        theTheme={theme}
                        navigateto={'website/email'}
                        backStatus={true}
                        backFunction={(event) => goBack(event)}
                    />
                }
            </div>
        </div>
    )
}

export default WebsiteEmailDashboard;