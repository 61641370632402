import React, {
    forwardRef
} from 'react';

import { FiCheck, FiXSquare } from 'react-icons/fi';
import ImageMain from '../media/imagemain';

const MediaFloatingScreen = forwardRef(({
    mainMediaOpen,
    media,
    filternumber,
    mediaHandler,
    size,
    theme,
    uploadmultiple = false,
    enablemultiple = false,
    onMediaSubmit,
    onCancelSubmit,
    picked,
    pickedHandler,
    pickedid,
    loadingupload,
    loadinguploadHandler,
    loadingdelete,
    loadingdeleteHandler,
    addFunction,
    deleteFunction,
    getFunction,
    allowedCropSize = []
}, mediaRef) => {

    const className = `floatingScreen ${theme} ${mainMediaOpen ? 'open' : ''}`;

    return (
        <div
            className={className}
        >
            {
                mainMediaOpen ?
                    <div className='mainBodyWrapper mediacss'>
                        <ImageMain
                            ref={mediaRef}
                            media={media}
                            filternumber={filternumber}
                            mediaHandler={mediaHandler}
                            size={size}
                            theme={theme}
                            uploadmultiple={uploadmultiple}
                            enablemultiple={enablemultiple}
                            picked={picked}
                            pickedHandler={pickedHandler}
                            loadingupload={loadingupload}
                            loadinguploadHandler={loadinguploadHandler}
                            loadingdelete={loadingdelete}
                            loadingdeleteHandler={loadingdeleteHandler}
                            addFunction={addFunction}
                            deleteFunction={deleteFunction}
                            getFunction={getFunction}
                            allowedCropSize={allowedCropSize}
                        />
                        <div className="mediaLibraryClose">
                            {
                                loadingupload || loadingdelete ?
                                    <div className="closeButton disabled">
                                        <FiXSquare />
                                    </div>
                                    :
                                    <div className="closeButton"
                                        onClick={(event) => onCancelSubmit(event)}
                                    >
                                        <FiXSquare />
                                    </div>
                            }
                            {
                                loadingupload || loadingdelete ?
                                    <div className="acceptButton disabled">
                                        <FiCheck />
                                    </div>
                                    :
                                    <div className={
                                        enablemultiple ?
                                            picked && picked.length > 0 ?
                                                "acceptButton multiactive"
                                                : "acceptButton"
                                            :
                                            picked && picked.length > 0 && picked.length < 2 ?
                                                "acceptButton singleactive"
                                                : "acceptButton"
                                    }
                                        onClick={(event) => onMediaSubmit({ event, id: pickedid })}
                                    >
                                        <FiCheck />
                                    </div>
                            }

                        </div>
                    </div>
                    : null
            }
        </div>
    )
})


export default MediaFloatingScreen;