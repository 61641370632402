import React from 'react';

import SideMainNavigation from './side.nav';
import SideSubNavigation from './side.subnav';
import SideAdminNavigation from './side.admin';


const ClientSideLayout = ({ children, client, approved, theTheme, size }) => {

    return (
        <div
            className={`clientpagelayout ${theTheme}`}
            style={{
                minHeight: size.height - 79.5
            }}
        >
            <div className='clientSideWrapper'>
                <SideMainNavigation
                    client={client}
                    size={size}
                />
                <SideSubNavigation
                    size={size}
                />
                {
                    approved ?
                        <SideAdminNavigation
                            size={size}
                        />
                        : null
                }
            </div>
            <div
                className='clientPageWrapper'
            >
                <div className='insidecontentWrapper'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ClientSideLayout;