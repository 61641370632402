export const HeaderPage = (title) => {
    return (
        <div className='cardWrapper header'>
            <div className='dashboardHeader'>
                <div className='row'>
                    <div className='lumi-md-12 lumi-xs-12'>
                        <div className="dashboardProfile">
                            <div className='greetWrapper'>
                                <p className='greetings mb0'>{title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

