import React from 'react';

import { NavLink } from 'react-router-dom';

import { mainNav } from '../../data/settings';

const SideWebsiteMainNavigation = () => {

    const printNavigation = (theNav) => {
        return theNav.map((nav, index) => {
            return (
                <li
                    key={index}
                >
                    <NavLink
                        className={(navData) => navData.isActive ? "active" : ""}
                        to={nav.pathname}
                    >
                        {nav.name}
                    </NavLink>
                </li>
            )
        })
    }

    return (
        <nav className='navContainer'>
            <ul>
                {
                    printNavigation(mainNav)
                }
            </ul>
        </nav>
    )
}

export default SideWebsiteMainNavigation;