export const mobile = (size) => {
    return size.width <= 767.98;
}

export const tablet = (size) => {
    return size.width >= 767.99 && size.width <= 1023.98
}

export const bigTablet = (size) => {
    return size.width >= 1024 && size.width <= 1279.98;
}

export const isPC = (size) => {
    return size.width >= 1920 && size.height >= 950;
}