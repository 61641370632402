export const GET_TESTIMONI = 'get_testimoni';
export const ADD_TESTIMONI = 'add_testimoni';
export const UPDATE_TESTIMONI = 'update_testimoni';

export const GET_TESTIMONI_CATEGORY = 'get_testimoni_category';
export const ADD_TESTIMONI_CATEGORY = 'add_testimoni_category';
export const UPDATE_TESTIMONI_CATEGORY = 'update_testimoni_category';

export const GET_TESTIMONI_TAGS = 'get_testimoni_tags';
export const ADD_TESTIMONI_TAGS = 'add_testimoni_tags';
export const UPDATE_TESTIMONI_TAGS = 'update_testimoni_tags';

export const GET_MEDIA_TESTIMONI = 'get_media_testimoni';
export const ADD_MEDIA_TESTIMONI = 'add_media_testimoni';
export const DELETE_MEDIA_TESTIMONI = 'delete_media_testimoni';