import React from 'react';

import {
    useDispatch
    // useSelector
} from 'react-redux';

import { useNavigate } from "react-router-dom";

import {
    setTokenCookie,
    getTokenCookie,
    tokenName
} from '../utils/tools';

import * as global from '../../store/actions/global/data.global';

import FormField from '../utils/form/formField';
import FormButton from '../utils/form/formButton';

import { FaEnvelope, FaLock } from 'react-icons/fa'
import { generateData, isFormValid, update } from '../utils/form/formActions';
import { clientSignIn } from '../../store/actions/auth';
// import rootReducer  from '../../store';
import CircleLoading from '../utils/circle.loading';


const SignInForm = () => {
    // const clientNotification = useSelector(state => state.clientNotification);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, loadingHandler] = React.useState(false);
    const formName = 'Sign in';
    const [formdata, formdataHandler] = React.useState({
        email: {
            element: 'input',
            value: '',
            config: {
                name: 'Email',
                type: 'email',
                placeholder: 'Enter your email'
            },
            validation: {
                required: true,
                email: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        password: {
            element: 'input',
            value: '',
            config: {
                name: 'Password',
                type: 'password',
                placeholder: 'Enter your password'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const submitForm = async (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();
            loadingHandler(true);

            let dataToSubmit = generateData(formdata, formName);
            let formIsValid = isFormValid(formdata, formName);

            // COBA DI CHECK LAGI WAKTU LOGIN, REQUEST DATANYA DAH BENER BLOM TUH
            // BUAT BUTTON LOGOUTNYA
            if (formIsValid) {
                let userauth = await dispatch(clientSignIn(dataToSubmit))
                if(userauth === undefined){
                    setTimeout(() => {
                        loadingHandler(false);
                    }, 5000)
                } else {
                    let usertocheck = userauth.data;
                    let token = await getTokenCookie(tokenName);
                    if(token === '') {
                        setTokenCookie(tokenName, usertocheck.data.token, 1);
                    }
                    
                    if (!usertocheck.success) {
                        setTimeout(() => {
                            dispatch(global.globalError('Please check your data'))
                            loadingHandler(false);
                        }, 5000);
                    } else if (usertocheck.data.isPin && !usertocheck.data.isFirstTime) {
                        dispatch(global.globalSuccess('Success, redirect to dashboard'))
                        setTimeout(() => {
                            navigate('/private')
                        }, 5000);
                    } else if (!usertocheck.data.isPin) {
                        dispatch(global.globalSuccess('Success, redirect to pin page'))
                        setTimeout(() => {
                            navigate('/auth/pin')
                        }, 5000);
                    } else if (usertocheck.data.isFirstTime) {
                        dispatch(global.globalSuccess('Success, redirect to welcome page'))
                        setTimeout(() => {
                            navigate('/auth/welcome')
                        }, 5000);
                    } else {
                        setTimeout(() => {
                            dispatch(global.globalError('Please check your data'))
                            loadingHandler(false);
                        }, 5000);
                    }
                }
            } else {
                setTimeout(() => {
                    dispatch(global.globalError('Please check your data'))
                    loadingHandler(false);
                }, 5000);
            }
        } catch (error) {
            setTimeout(() => {
                dispatch(global.globalError('something is wrong, please retry'))
                loadingHandler(false);
            }, 5000);
        }
    }

    if (loading) {
        return (
            <div style={{
                paddingTop: 78,
                paddingBottom: 80
            }}>
                <CircleLoading />
            </div>
        )
    }

    return (
        <>
            <form onSubmit={(event) => submitForm(event)}>
                <FormField
                    id={'email'}
                    formdata={formdata.email}
                    change={(element) => updateForm(element)}
                    theClass="inputclass"
                    icons={<FaEnvelope />}
                    showName={false}
                />
                <FormField
                    id={'password'}
                    formdata={formdata.password}
                    change={(element) => updateForm(element)}
                    theClass="inputclass"
                    icons={<FaLock />}
                    formdataHandler={formdataHandler}
                    showName={false}
                />
                <FormButton
                    theName={'submitNF'}
                    theClass={'submitButton'}
                    theType={'submit'}
                    theTitle={'Submit'}
                />
            </form>
        </>
    )
}

export default SignInForm;