import * as data from './data.media';
import * as global from '../global/data.global';
import axios from '../api';
import axiosmultipart from '../apimultipart';

import {
    ADD_MEDIA, DELETE_MEDIA
} from '../../types/media';

import { checkError } from '../global/function';

export const getAllMedia = () => {
    return async (dispatch) => {
        try {
            const request = await axios.get('/api/media/getmedia');
            dispatch(data.getMedia(request.data));
            return request;
        } catch (error) {
            let getErrorMessage = await checkError(error);
            dispatch(global.globalError(getErrorMessage))
        }
    }
}

export async function addMedia(dataToSubmit) {
    try {
        const formData = new FormData();
        dataToSubmit.forEach((img, i) => {
            formData.append(`image${i}`, img);
        });

        formData.append('upload_preset', process.env.REACT_APP_UPLOADPRESET);
        formData.append('cloud_name', process.env.REACT_APP_CLOUDNAME);

        const request = axiosmultipart.post(
            `/api/media/addmedia`,
            formData
        ).then(response => response.data);

        return {
            type: ADD_MEDIA,
            payload: request
        }
    } catch (error) {
        return error
    }
}

export async function deleteMedia(dataToSubmit) {
    try {
        const request = axios.post(`/api/media/deletemedia`, dataToSubmit).then(response => response.data);

        return {
            type: DELETE_MEDIA,
            payload: request
        }
    } catch (error) {
        return error
    }
}