import React from 'react';
import {
    useDispatch,
    // useSelector
} from 'react-redux';

import Slider from './slider';

import { Link } from 'react-router-dom';
import { useWindowSize } from '../../../utils/windowsize';
import { mobileSize, tabletSize } from '../data/settings';

import { getSliderFunction } from '../../../store/actions/frontend/slider';
import { 
    getGalleryFunction
} from '../../../store/actions/frontend/gallery';
import { 
    getTestimoniFunction
} from '../../../store/actions/frontend/testimoni';
import CantDecideSection from '../cantdecide';



const HomePage = (props) => {

    const { theme } = props;

    const dispatch = useDispatch();

    const size = useWindowSize();
    const isMobile = size.width <= mobileSize.width;
    const isTablet = size.width >= tabletSize.minWidth && size.width <= tabletSize.minHeight;

    const [data, dataHandler] = React.useState({});
    // const [testimoni, testimoniHandler] = React.useState({});
    const [filteredTestimoni, filteredTestimoniHandler] = React.useState({});
    // const [gallerycat, gallerycatHandler] = React.useState([]);
    // const [testimonicat, testimonicatHandler] = React.useState([]);
    const [slider, sliderHandler] = React.useState([]);

    const maxDescription = 100;

    React.useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (mounted) {
                return dispatch(getGalleryFunction(3))
            }
        }
        fetchData().then(async (response) => {
            if (response && response.data.success) {
                let theData = response.data.data;
                // let latestData = getLatestData(theData);
                dataHandler(theData);
                let theTestimoni = await dispatch(getTestimoniFunction())
                let testimoni = theTestimoni.status === 200 && theTestimoni.data.success && theTestimoni.data.data;
                // let filterTestimoni = testimoni.filter(single => single.show === true);
                for (let data in testimoni) {
                    let filtered = testimoni[data].images.filter(data => data.height > data.width)
                    testimoni[data].images = filtered;
                }
                // console.log(testimoni, '<<<testimoni')
                // testimoniHandler(testimoni);
                filteredTestimoniHandler(testimoni)
                // let theGallerycat = await dispatch(getGalleryCategoryDistinct())
                // let gallerycat = theGallerycat.status === 200 && theGallerycat.data.success && theGallerycat.data.data;
                // gallerycatHandler(gallerycat)
                // let theTestimonicat = await dispatch(getTestimoniCategoryDistinct())
                // let testimonicat = theTestimonicat.status === 200 && theTestimonicat.data.success && theTestimonicat.data.data;
                // testimonicatHandler(testimonicat)
                let theSlider = await dispatch(getSliderFunction())
                let slider = theSlider.status === 200 && theSlider.data.success && theSlider.data.data;
                sliderHandler(slider)
            }
        })
    }, [dispatch])



    // thewebsitedomain/

    const showLatestGallery = (data) => {

        return data && data.length > 0 && data.map((single, index) => (

            <Link
                key={index}
                to={`/gallery/${single._id}`}
                className='productitemwrapper lumi-md-4 lumi-xlg-4 lumi-sm-6 lumi-xs-12'
            >
                <div className='productitem'>
                    <div className="productimage">
                        <picture>
                            <img src={`${single.images && single.images[0].secureurl}`} alt={`${single.images && single.images[0].newname}`} />
                        </picture>
                    </div>
                    <div className="productdesc">
                        <h3>{single.name}</h3>
                        <span>
                            {single.description && single.description.length > maxDescription ? single.description.slice(0, maxDescription) + '...' : single.description.slice(0, maxDescription)}
                        </span>
                    </div>
                </div>
            </Link>

        ))
    }

    // const showLatestTestimoni = (data) => {

    //     return data && data.length > 0 && data.map((single, index) => {
    //         if(single.images && single.images.length > 0) {
    //             return (

    //                 <Link
    //                     key={index}
    //                     to={`/${single._id}`}
    //                     className='productitemwrapper lumi-md-4 lumi-xlg-4 lumi-sm-6 lumi-xs-12'
    //                 >
    //                     <div className='productitem'>
    //                         <div
    //                             className="productimage "
    //                         >
    //                             <picture>
    //                                 <img src={`${single.images && single.images[0].secureurl}`} alt={`${single.images && single.images[0].newname}`} />
    //                             </picture>
    //                         </div>
    //                         <div className="productdesc">
    //                             <h3>{single.name}</h3>
    //                         </div>
    //                     </div>
    //                 </Link>
        
    //             )
    //         }
    //     })
    // }

    return (
        <section id="homepage" className={`homepage ${theme}`}>
            <div className="contentwrap" style={{ transform: "none" }}>
                <Slider isMobile={isMobile} isTablet={isTablet} slider={slider} />
                <div className="section dividersection" style={{ marginTop: isMobile ? '0px' : '70px' }}>
                    <div className="container">
                        <div
                            className="headingblock center mb30"
                        >
                            <h2 className='aboutTitle'>
                                Our New Arrivals
                                Dresses & Gowns
                            </h2>
                            <div className='productlistwrapper'>
                                {/* <nav>
                                    <ul>
                                        <li className='active'>
                                            <button>
                                                New Arrivals
                                            </button>
                                        </li>
                                        <li>
                                            <button>
                                                Category 1
                                            </button>
                                        </li>
                                        <li>
                                            <button>
                                                Category 2
                                            </button>
                                        </li>
                                    </ul>
                                </nav> */}
                                <div className='productlists'>
                                    {
                                        showLatestGallery(data)
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="section" style={{ marginBottom: isMobile ? '80px' : '10px' }}>
                    <div className="container">
                        <div className='row'>
                            <div className='gridContainer'>
                                <div className='gridHeader'>
                                    <h2 className='headingInfinity'>Our</h2>
                                    <h2 className='headingInfinity alignRight'>Testimony</h2>
                                </div>
                                <div className='leftImage'>

                                    {
                                        filteredTestimoni && filteredTestimoni.length > 0 ?
                                            <div className='sketchImage'>
                                                {
                                                    filteredTestimoni[0].images && filteredTestimoni[0].images.length > 0 ?
                                                        <img src={`${filteredTestimoni[0].images[0].secureurl}`} alt={`${filteredTestimoni[0].images[0].alternative}`} />
                                                        : null
                                                }
                                            </div>
                                            : null
                                    }


                                    {
                                        filteredTestimoni && filteredTestimoni.length > 0 ?
                                            <div className='corsetImage'>
                                                {
                                                    filteredTestimoni[0].images && filteredTestimoni[0].images.length > 1 ?
                                                        <img src={`${filteredTestimoni[0].images[1].secureurl}`} alt={`${filteredTestimoni[0].images[1].alternative}`} />
                                                        : null
                                                }
                                            </div>
                                            : null
                                    }

                                </div>
                                <div className='rightImage'>
                                    {
                                        filteredTestimoni && filteredTestimoni.length > 1 ?
                                            <div className='sketchImage'>
                                                {
                                                    filteredTestimoni[1].images && filteredTestimoni[1].images.length > 0 ?
                                                        <img src={`${filteredTestimoni[1].images[0].secureurl}`} alt={`${filteredTestimoni[1].images[0].alternative}`} />
                                                        : null
                                                }
                                            </div>
                                            : null
                                    }
                                    {
                                        filteredTestimoni && filteredTestimoni.length > 1 ?
                                            <div className='corsetImage'>
                                                {
                                                    filteredTestimoni[1].images && filteredTestimoni[1].images.length > 1 ?
                                                        <img src={`${filteredTestimoni[1].images[1].secureurl}`} alt={`${filteredTestimoni[1].images[1].alternative}`} />
                                                        : null
                                                }
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className='gridCopyRight'>
                                    <p>
                                        Luminous Sposa aims to build trust and form relationships with the brides, designers and partners. With this motto and service culture ingrained, the company strives to be the leading designer bridal store in Indonesia.
                                    </p>
                                    <div className='aboutButton'>
                                        <Link
                                            to={'/testimony'}
                                            className='button'
                                        >
                                            Click Here
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="section" style={{ marginBottom: isMobile ? '80px' : '10px' }}>
                    <div className="container">
                        <div
                            className="headingblock center mb30"
                        >
                            <h2 className='aboutTitle'>
                                Our Happy Couples
                            </h2>
                            <div className='productlistwrapper'>
                                <div className='productlists'>
                                    {
                                        showLatestTestimoni(filteredTestimoni)
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}
                <CantDecideSection
                    isMobile={isMobile}
                />
            </div>
        </section>
    )
}

export default HomePage;