import React from 'react';

import TemplateDashboard from '../../utils/template/dashboard';
import { FiGrid, FiPlus } from 'react-icons/fi';

const WebsiteEmail = (props) => {

    const pageNav = [
        {
            name: 'Email Lists',
            pathname: '/private/website/email/dashboard',
            icon: <FiGrid />,
            showName: true
        },
        {
            name: 'Add Email',
            pathname: '/private/website/email/add',
            icon: <FiPlus />,
            showName: true
        }
    ]

    const pageName = 'Email Dashboard';

    return (
        <TemplateDashboard
            props={props}
            pageName={pageName}
            pageNav={pageNav}
        />
    )
}

export default WebsiteEmail;