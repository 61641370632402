import React from 'react';
import {
    useDispatch
} from 'react-redux';
import {
    useNavigate
} from 'react-router-dom';

import * as global from '../../../store/actions/global/data.global';

import { generateData, isFormValid, resetFields, update } from '../../utils/form/formActions';
import FormField from '../../utils/form/formField';

import AdminLoading from '../../utils/circle.loading';
import { addNewAdmin } from '../../../store/actions/client';

import { FiArrowLeft, FiSend, FiUser } from 'react-icons/fi';
import { FaLock } from 'react-icons/fa';

const AdminAdd = ({
    theme
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, loadingHandler] = React.useState(false);

    const formName = 'Add Admin';

    const [formdata, formdataHandler] = React.useState({
        email: {
            element: 'input',
            title: 'Email',
            value: '',
            config: {
                name: 'Email',
                type: 'email',
                placeholder: 'Enter admin email'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        },
        password: {
            element: 'input',
            title: 'Password',
            value: '',
            config: {
                name: 'Password',
                type: 'password',
                placeholder: 'Enter the password'
            },
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            validationMessage: ''
        }
    });

    const updateForm = (element) => {
        const newFormdata = update(element, formdata, formName);
        formdataHandler(newFormdata)
    }

    const submitAddAdmin = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        loadingHandler(true);

        let dataToSubmit = generateData(formdata, formName);
        let formIsValid = isFormValid(formdata, formName);

        if (formIsValid) {
            const result = await dispatch(addNewAdmin(dataToSubmit))
            if (result === undefined || result === 'undefined') {
                loadingHandler(false);
            }
            if (!result.data.success) {
                dispatch(global.globalError('please try again'))
                loadingHandler(false);
            }
            const newFormdata = resetFields(formdata, formName);
            formdataHandler(newFormdata)
            dispatch(global.globalSuccess('admin added'))
            loadingHandler(false);
        } else {
            dispatch(global.globalError('Please check your data'))
            loadingHandler(false);
        }
    }

    const goBack = (event) => {
        event.preventDefault();
        event.stopPropagation();

        navigate('/private/admin/dashboard')
    }

    return (
        <div className='cardWrapper'>
            <div className='cardHeader' style={{ paddingBottom: 30 }}>
                <h5>{formName}</h5>
                <div className='cardOptions'>
                    <ul>
                        <li id="loadingSubmit" className='iconRefresh button' onClick={(event) => goBack(event)}>
                            <div className='center'>
                                <span><FiArrowLeft /></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='cardBody'>
                <div className='lumi-md-12 lumi-xs-12 mb30 p0'>
                    <div className='cardOptions'>
                        <ul>
                            <li className='inputSearch'>
                                <FormField
                                    id={'email'}
                                    formdata={formdata.email}
                                    change={(element) => updateForm(element)}
                                    theClass="inputclass"
                                    icons={<FiUser />}
                                    showName={true}
                                    disabled={loading ? true : false}
                                />
                            </li>
                            <li className='inputSearch'>
                                <FormField
                                    id={'password'}
                                    formdata={formdata.password}
                                    change={(element) => updateForm(element)}
                                    theClass="inputclass"
                                    icons={<FaLock />}
                                    formdataHandler={formdataHandler}
                                    showName={true}
                                    disabled={loading ? true : false}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='lumi-md-12 lumi-xs-12 p0'>
                    <div className='cardOptions form'>
                        <ul>
                            <li
                                // id="loadingSubmit"
                                id="fullSubmit"
                                className='iconRefresh button'
                                onClick={
                                    loading ? null
                                        : (event) => submitAddAdmin(event)
                                }
                            >
                                <div className='center'>
                                    {
                                        loading ? <AdminLoading theTheme={theme} />
                                            : <span><FiSend /> Submit</span>
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminAdd;